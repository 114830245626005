import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";

export default class JoinTeamButtons {
  constructor(dh = new DomHelpers()) {
    this.dh = dh;

    _.bindAll(this, "handleButtonClick", "reload", "sendJoinRequest");
  }

  bindListeners() {
    document.addEventListener("click", this.handleButtonClick);
  }

  handleButtonClick(event) {
    if (!this.isJoinButtonClick(event)) {
      return;
    }

    event.preventDefault();

    const data = event.target.dataset;

    const { joinTeam: teamId, joinTeamName: teamName } = data;

    const yayData = { teamId, teamName };

    const detail = {
      title: "Are you sure?",
      showInput: false,
      inputPlaceholder: "",
      onYay: this.sendJoinRequest,
      onNay: _.noop,
      doNotCloseOnYay: true,
      yayData
    };

    if (data.joinTeamPassword == 1) {
      detail.showInput = true;
      detail.inputPlaceholder = "Team Password";
      detail.body = `Team "${data.joinTeamName}" requires a password. Please enter the team password and confirm.`;
    } else {
      detail.body = `Please confirm that you would like to leave your current team and join "${data.joinTeamName}"`;
    }

    document.dispatchEvent(new CustomEvent("showModal", { detail }));
 }

  isJoinButtonClick(event) {
    return _.has(event.target.dataset, "joinTeam");
  }

  async sendJoinRequest(modalData) {
    const { teamId: team_id, modalInputValue: team_password } = modalData;

    const params = { team_password, participant: { team_id } };

    const options = {
      method: "PUT",
      body: JSON.stringify(params),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken()
      }
    };

    try {
      const response = await fetch("/me/participant", options);

      if (response.headers.get("content-type").includes("json")) {
        const data = await response.json();

        if (response.ok && _.has(data, "id")) {
          this.handleSuccess(data, modalData);
        } else {
          this.handleFailure(data, modalData);
        }
      } else {
        const err = new Error(`Invalid response: ${response.statusText || response.status}`);

        err.response = response;
        err.responseText = await response.text();

        throw err;
      }
    } catch (error) {
      this.handleError(error, modalData);
    }
  }

  handleSuccess(response, data) {
    const detail = {
      title: "Success",
      body: `You are now a member of "${data.teamName}"`,
      yayLabel: "Sounds good",
      onYay: this.reload,
      hideNay: true,
      hideClose: true
    };

    document.dispatchEvent(new CustomEvent("showModal", { detail }));
  }

  handleFailure(errors, data) {
    this.reload();
  }

  handleError(error, data) {
    console.warn("Something went wrong");
    console.error(error);

    this.reload();
  }

  reload() {
    window.location.reload()
  }
}
