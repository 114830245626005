import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";

export default class AddActionPromptDismissed {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;
    this.handleDismissWarning = this.handleDismissWarning.bind(this);
    this.handleDismissClick = this.handleDismissClick.bind(this);
    this.canHandleDismissClick = this.canHandleDismissClick.bind(this);
    this.sendDismissRequest = this.sendDismissRequest.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  bindListeners() {
    document.addEventListener('click', this.handleDismissWarning);
    document.addEventListener('click', this.handleDismissClick);
  }

  handleDismissWarning(event) {
    if(!_.has(event.target.dataset, "actionMessageWarn")) return;

    event.preventDefault();
    const parent = event.target.parentElement;
    parent.classList.add("d-none");
    (parent.nextElementSibling || parent.previousElementSibling).classList.remove("d-none");
  }

  handleDismissClick(event) {
    if (!this.canHandleDismissClick(event)) return;
    this.sendDismissRequest(event.target);
    event.preventDefault();
  }

  canHandleDismissClick(event) {
    return _.has(event.target.dataset, 'actionMessageDismiss');
  }

  sendDismissRequest(clickedButton) {
    actionType = clickedButton.dataset["actionMessageDismiss"]
    const url = '/me/participant'
    const init = {
      method: "PUT",
      body: JSON.stringify({ participant: { [`${actionType}_action_prompt_dismissed`]: true } }),
      credentials: 'include',
      headers: {
        "content-type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    };
    fetch(url, init).then(this.checkStatus).then(this.parseJSON).then(
        (response) => {
          if (response.id) {
            this.handleSuccess(response, clickedButton);
          } else {
            this.handleFailure(response);
          }
        }
    ).catch((error) => { this.handleError(error)})
  }

  handleSuccess(response, clickedButton) {
    const prompt = clickedButton.closest('[data-action-prompt]');
    if (prompt) {
      this.dh.addClass(prompt, 'fade');
    }
  }

  handleError(error) {
    console.log(error);
  }

  parseJSON(response) {
    return response.json()
  }

  handleFailure(errors) {
    console.log(errors);
  }

};
