import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";
import "chartkick/chart.js"

export default class ImpactCharts {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;
    _.bindAll(this, "updateChart", "updateFormField", "getChartParams", "updateSelected", "chartNameFor", "chartOptionsFor", "chartTypeFor");
  }

  bindListeners() {
    this.dh.on("[data-impact-chart-calendar]", "click", this.updateChart);
    this.dh.on("button[data-impact-chart-period]", "click", this.updateChart);
  }

  get CHART_DATA_PATH() { return "/dashboards/impacts/get_chart_data" }

  setDefaults() {
    Chart.defaults.global.defaultFontFamily = "'Galaxie Polaris', sans-serif";
    Chart.defaults.global.defaultFontSize = 10;
  }

  updateChart(event) {
    event.preventDefault();
    const chartName = this.chartNameFor(event.target);
    const fieldName = event.target.dataset["impactChartField"];
    const value = event.target.dataset["impactChartValue"];
    this.updateFormField(chartName, fieldName, value);
    new Chartkick["LineChart"](chartName, `${this.CHART_DATA_PATH}?${this.getChartParams(chartName)}`, this.chartOptionsFor(chartName));

    this.updateSelected(event.target);
  }

  updateFormField(chartName, fieldName, value) {
    window[chartName + "_form"][fieldName].value = value;
    const updateEl = document.getElementById(chartName + "_" + fieldName)
    if(updateEl)
      updateEl.innerHTML = value;
  }

  getChartParams(chartName) {
    let ret = "";
    const fieldNames = [`chart_${this.chartTypeFor(chartName)}`, "chart_period", "chart_start", "chart_end"];
    fieldNames.forEach(fieldName => {
      ret += fieldName + "=" + window[chartName + "_form"][fieldName].value + "&";
    });
    return ret.slice(0, ret.length - 1);
  }

  updateSelected(el) {
    const parent = el.parentNode;
    for(let c = 0; c < parent.children.length; c++) {
      parent.children[c].classList.remove("selected");
    }
    el.classList.add("selected");
  }

  chartNameFor(element) {
    return this.dh.closest(element, "[data-impact-chart]").dataset["impactChart"]
  }

  chartOptionsFor(chartName) {
    return document.querySelector(`[data-impact-chart='${chartName}']`).dataset["impactChartOptions"];
  }

  chartTypeFor(chartName) {
    return document.querySelector(`[data-impact-chart='${chartName}']`).dataset["impactChartType"];
  }

}
