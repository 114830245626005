import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js"

export default class AddRemoveFields {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;

    this.handleRemoveField = this.handleRemoveField.bind(this);
    this.handleAddField = this.handleAddField.bind(this);
    this.canHandleAddRemove = this.canHandleAddRemove.bind(this);
    this.interpolateValuesToForm = this.interpolateValuesToForm.bind(this);
  }

  bindListeners() {
    document.addEventListener('click', this.canHandleAddRemove);
  }

  handleRemoveField(event) {
    event.preventDefault();
    const formField = this.dh.closest(event.target, '[data-removable-form-field]');
    if (!formField) return;
    const destroy = formField.nextElementSibling;
    if (destroy && destroy.dataset.removableFieldDestroy) destroy.value = true;
    formField.remove();
  }

  handleAddField(event) {
    event.preventDefault();
    let formFieldTemplate = document.querySelector('[data-form-field-template], [data-template-interpolated]');
    if (!formFieldTemplate) return null;

    let newFormField = formFieldTemplate.innerHTML;
    const addButton = document.querySelector('[data-add-form-field]').parentNode;
    if (!addButton) return;

    if (!!formFieldTemplate.dataset.templateInterpolated) {
      newFormField = this.interpolateValuesToForm(newFormField)
    }

    addButton.insertAdjacentHTML('beforebegin', newFormField);
  }

  interpolateValuesToForm(form) {
    const template = _.template(form);
    return template({ modelId: _.uniqueId("0") }); // Prefix with a "0" so that there is no overlap with existing IDs
  }

  canHandleAddRemove(event) {
    if (_.has(event.target.dataset, 'removeFormField')) {
      this.handleRemoveField(event);
    } else if (_.has(event.target.dataset, 'addFormField')) {
      this.handleAddField(event);
    } else {
      return null;
    }
  }

};
