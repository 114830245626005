import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";

export default class Referral {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;
    this.handleDismissClick = this.handleDismissClick.bind(this);
    this.handleCopyClick = this.handleCopyClick.bind(this);
    this.createFlashMessage = this.createFlashMessage.bind(this);
    this.canHandleDismissClick = this.canHandleDismissClick.bind(this);
    this.canHandleCopyClick = this.canHandleCopyClick.bind(this);
  }

  bindListeners() {
    document.addEventListener('click', this.handleDismissClick);
    document.addEventListener('click', this.handleCopyClick);
  }

  handleDismissClick(event) {
    if (!this.canHandleDismissClick(event)) return
    const input = document.createElement("input");
    const form = this.dh.closest(event.target, '[data-referral]');
    input.setAttribute("type", "hidden");
    input.setAttribute("name", "_method");
    input.setAttribute("value", "delete");
    form.appendChild(input);
    form.submit();
  }

  canHandleDismissClick(event) {
    return _.has(event.target.dataset, 'referralDismiss');
  }

  canHandleCopyClick(event) {
    return _.has(event.target.dataset, 'copyInviteUrl');
  }

  createFlashMessage(value) {
    // clears any existing flash messages so they don't stack in DOM
    const existing = document.querySelectorAll('[data-toggleable="notification-copied"]');
    if (existing.length > 0) {
      [...existing].forEach(function(e) {
        e.remove();
      });
    }

    const container = document.querySelector(".notifications-container");
    if (!container) return null;

    const wrapper = document.createElement("div");
    wrapper.className = "notification warning";
    wrapper.dataset.toggleable = "notification-copied";

    const message = document.createElement("span");
    message.className = "message";
    message.innerHTML = value;
    wrapper.appendChild(message);

    const close = document.createElement("a");
    close.href = "#";
    close.className = "close";
    close.dataset.toggleTarget = "notification-copied";
    close.innerHTML = "close";
    wrapper.appendChild(close);

    container.appendChild(wrapper);
  }

  // https://github.com/castiron/hammer/blob/73f65e1811071373d986e51bd7062bd87752a560/Source/typo3conf/ext/t3site/Sites/Main/Resources/Assets/Script/Coffeescript/scripts.coffee#L590
  // Blake++
  handleCopyClick(event) {
    if (!this.canHandleCopyClick(event)) return
    const url = document.querySelector('[data-copy-invite-url]');
    const copied = document.querySelector('[data-copied-message]');
    let selection, range;

    if (document.execCommand('copy')) {
      if (document.body.createTextRange) { // IE support
        range = document.body.createTextRange();
        range.moveToElementText(url);
        range.select();
      } else if (window.getSelection) { // other browsers
        selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(url);
        selection.removeAllRanges();
        selection.addRange(range);
      }

      document.execCommand('copy');
      this.createFlashMessage('Copied to clipboard');
    } else {
      this.createFlashMessage('Could not copy');
    }
  }

};
