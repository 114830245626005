//For use with WTF Forms (http://wtfforms.com) style Checkbox markup
import DomHelpers from "../utility/dom_helpers.js"

export default class Checkboxes {
  constructor(dh = new DomHelpers()) {
    this.dh = dh;

    this.getIndicator = this.getIndicator.bind(this);
    this.initializeDropdownCheckboxes = this.initializeDropdownCheckboxes.bind(this);
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
    this.handleCheckboxFocus = this.handleCheckboxFocus.bind(this);
    this.handleCheckboxBlur = this.handleCheckboxBlur.bind(this);
  }

  bindListeners() {
    window.addEventListener("turbo:load", this.initializeDropdownCheckboxes);
    window.addEventListener('change', this.handleCheckboxClick);
    window.addEventListener('focus', this.handleCheckboxFocus);
    window.addEventListener('blur', this.handleCheckboxBlur);
  }

  initializeDropdownCheckboxes(event) {
    document.querySelectorAll("[data-select-dropdown]").forEach((dropdown) => {
      dropdown.querySelectorAll("input[type='checkbox']").forEach((checkbox) => {
        if(checkbox.checked) this.handleDropdownCheckbox(checkbox, dropdown);
      });
    });
  }

  handleCheckboxClick(event) {
    const checkbox = event.target;
    if (!this.isCheckbox(checkbox)) return false;
    
    this.checkState(checkbox)

    const parentDropdown = checkbox.closest(".dropdown")
    if(parentDropdown && parentDropdown.dataset["selectDropdown"]) {
      this.handleDropdownCheckbox(checkbox, parentDropdown);
    }
  }

  handleCheckboxFocus(event) {
    const checkbox = event.target;
    if (!this.isCheckbox(checkbox)) return false;
    this.setFocus(checkbox);
  }

  handleCheckboxBlur(event) {
    const checkbox = event.target;
    if (!this.isCheckbox(checkbox)) return false;
    this.unsetFocus(checkbox);
  }

  setFocus(checkbox) {
    this.dh.addClass(this.getIndicator(checkbox), 'focus');
  }

  unsetFocus(checkbox) {
    this.dh.removeClass(this.getIndicator(checkbox), 'focus');
  }

  isCheckbox(el) {
    return el.type && el.type === 'checkbox';
  }

  getWrap(checkbox) {
    return this.dh.closest(checkbox, 'label.checkbox')
  }

  getIndicator(checkbox) {
    const wrap = this.getWrap(checkbox);
    if (wrap) {
      return wrap.querySelector('span.toggle-indicator');
    }
  }

  checkState(checkbox) {
    const indicator = this.getIndicator(checkbox);
    if (!indicator) return;
    if (checkbox.checked) {
      this.dh.addClass(indicator, 'checked');
    } else {
      this.dh.removeClass(indicator, 'checked');
    }
  }

  handleDropdownCheckbox(checkbox, dropdown) {
    const button = document.querySelector(`[data-dropdown-toggle=${dropdown.dataset["dropdown"]}`);
    const labelContent = this.getWrap(checkbox).textContent;
    const valueArray = button.textContent.split(/\s?;\s?/).filter((v) => v && v.trim().length > 0);

    if(button.textContent.includes(`${labelContent}`)) {
      valueArray.splice(valueArray.indexOf(labelContent), 1);
      button.innerHTML = valueArray.join("; ") || "&nbsp"
    } else {
      valueArray.push(labelContent)
      button.textContent = valueArray.join("; ")
    }
  }

};
