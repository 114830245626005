import DomHelpers from "../utility/dom_helpers.js";
import tippy from 'tippy.js';
import confetti from "canvas-confetti";

/**
 * Handles showing and managing tutorial content, as defined by a server-rendered meta tag.
 */
export default class Tutorial {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;

    const reducedMotionQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
    this.reducedMotion = reducedMotionQuery && reducedMotionQuery.matches;
  }

  bindListeners() {
    window.addEventListener("turbo:load", this.initializeTutorialPage.bind(this));
    window.addEventListener("turbo:before-cache", this.closeAllTips.bind(this, true, true));
    window.addEventListener("click", this.handleTipClick.bind(this));
    window.addEventListener("click", this.handleConfetti.bind(this));
    window.addEventListener("eco-action-select", this.removeTutorialHighlight.bind(this) )
  }

  removeTutorialHighlight = () => {
    document.querySelectorAll(".button.highlight").forEach((n) => n.classList.remove("highlight"));
  }

  /**
   * Gets data from the tutorial meta tag ([name='eco-tutorial']) and renders and/or sets
   * listeners for each tip.
   */
  getTutorialContent() {
    this.metaElement = document.querySelector("meta[name='eco-tutorial']");
    if(!this.metaElement) {
      this.tutorialContent = null;
      return;
    }

    const metaContent = JSON.parse(this.metaElement.content);

    this.tutorialContent = metaContent.steps;
    this.tutorialContent.forEach((entry, index) => entry.id = index);
    this.tutorialMeta = metaContent.meta || {};
    this.tippyInstances = [];
  }

  initializeTutorialPage() {
    this.getTutorialContent();
    if(!this.tutorialContent) return;

    this.removeTutorialHighlight();
    this.createEndTutorialTip();

    this.tutorialContent.forEach(entry => {
      if(entry.event) {
        this.createEventCallback(entry);
      }
    });

    this.step = 1;
    this.createTutorialStep();
  }

  /**
   * Creates tutorials from tutorial content object.
   * If an entry defines a property named 'event', it is an event name and a listener should be
   * created. This callback renders a tip on the event target.
   * Otherwise, render a tip immediately on the first found element that matches the 'target'
   * property as a CSS selector.
   */
  createTutorialStep() {
    this.tutorialContent.forEach(entry => {
      if(!entry.event && entry.step == this.step) {
        this.createTipFromSelector(entry)
      }
    });

    setTimeout(() => {
      const ref = document.querySelector("[data-tippy-root] [data-theme~='ecochallenge'][data-state='visible']")
      if(ref) {
        ref.scrollIntoView(false);
      }
    }, 500);
  }

  /**
   * Create a callback to create a tip on custom event.
   * If the tutorial step's "event" is "once", the event should only trigger once. All subsequent
   * events should be ignored.
   */
  createEventCallback(entry) {
    const callback = (e) => {
      const target = entry.target ? document.querySelector(entry.target) : e.target
      if(target) this.createTip(target, entry);
    }

    document.addEventListener(entry.event, callback, { once: true });
  }

  /**
   * Create tip from a CSS selector on first found element.
   */
  createTipFromSelector(entry) {
    const target = document.querySelector(entry.target);
    if(!target) return;

    this.createTip(target, entry);
  }

  /**
   * Creates a special tip to end the tutorial
   */
  createEndTutorialTip() {
    const target = document.querySelector(".dashboard-header");
    const tip = tippy(target, {
      content: `<button class="button-primary minimal big" data-tutorial-action="stop">${this.tutorialMeta.i18n.end_tutorial || "End Tutorial"}</button>`,
      allowHTML: true,
      theme: "light button",
      arrow: false,
      placement: "bottom-end",
      trigger: "manual",
      interactive: true,
      hideOnClick: false,
      showOnCreate: true
    });

    const button = tip.popper.querySelector("button[data-tutorial-action='stop']");
    button.setAttribute("data-tip-id", tip.id);

    this.endTutorialTip = tip
  }

  /**
   * Create a tip on an element with provided content and options.
   */
  createTip(target, entry) {
    if(entry.content) {
      const tip = tippy(target, {
        allowHTML: true,
        arrow: entry.hasOwnProperty("arrow") ? entry.arrow : true,
        theme: entry.theme || "ecochallenge",
        placement: entry.placement || "top",
        animation: this.reducedMotion ? "fade" : "perspective-extreme",
        trigger: "manual",
        interactive: true,
        hideOnClick: false,
        showOnCreate: true,
        delay: 500,
        onClickOutside: this.onClickOutside.bind(this)
      })

      this.tippyInstances.push(tip);

      if(entry.next) {
        entry.content += `
          <br />
          <button class="button-primary space-top" data-tutorial-action="next" data-tip-id={{TIP_ID}}>${this.tutorialMeta.i18n.next || "Next"}</button>
        `;
      }

      tip.setContent(entry.content.replace("TIP_ID", tip.id));

      const container = tip.reference._tippy.popper;
      const box = container.querySelector(".tippy-box[data-theme~='ecochallenge']");
      if(!box) return;

      if(entry.closeable) {
        const closeBtn = document.createElement("button");
        closeBtn.classList.add("tutorial-close");
        closeBtn.setAttribute("data-tutorial-action", "close")
        closeBtn.setAttribute("data-tip-id", tip.id);
        closeBtn.role = "button";
        closeBtn.innerHTML = "&times;";
        box.appendChild(closeBtn);
        // Adding a listener to the element itself is needed to prevent underlying links from triggering
        closeBtn.addEventListener("click", (e) => e.preventDefault());
      }

      if(entry.close_event) {
        document.addEventListener(entry.close_event, (e) => {
          this.closeTip(tip);
        });
      }
    }

    setTimeout(() => {
      if(entry.glow) target.classList.add("tutorial-glow");
      if(entry.confetti) confetti();
    }, 500);
  }

  /**
   * Gets a Tippy instance by ID
   */
   getTipById(tipId) {
     return this.tippyInstances.find((t) => t.id == tipId);
   }

  /**
   * Close menus on click outside.
   */
  onClickOutside(tip) {
    if(tip.menuState == "open") {
      this.restoreContent(tip);
    }
  }

  /**
   * Handle tutorial action clicks.
   */
  handleTipClick = async (e) => {
    const target = e.target;
    if(!target.dataset["tipId"]) return;

    const tip = this.getTipById(target.dataset["tipId"]);

    if(target.dataset["tutorialAction"] == "next") {
      this.nextStep(e);
    } else if(target.dataset["tutorialAction"] == "menu") {
      this.showTipMenu(tip);
    } else if(target.dataset["tutorialAction"] == "close") {
      this.closeTip(tip);
    } else if(target.dataset["tutorialAction"] == "stop") {
      this.destroyTutorial();
    } else {
      return;
    }

    e.preventDefault();
    return false;
  }

  nextStep(event) {
    this.closeAllTips();
    this.step++;
    this.createTutorialStep();
  }

  /**
   * Show menu on "x" click.
   */
  showTipMenu(tip) {
    if(!tip) return;

    if(tip.menuState == "open") {
      this.restoreContent(tip);
      tip.menuState = "closed"
    } else {
      this.cachedContent = tip.popper.querySelector(".tippy-content").innerHTML;
      tip.menuState = "open"
      tip.setContent(this.menuContent(tip));
    }
  }

  /**
   * Restore content to a tippy when the menu is closed.
   */
  restoreContent(tip) {
    if(this.cachedContent) tip.setContent(this.cachedContent);
  }

  /**
   * Close a single tip tip and remove any glow.
   */
  closeTip(tip, immediately = false) {
    if(!tip) return;

    if(immediately) {
      tip.destroy();
    } else {
      tip.hide();
      setTimeout(() => tip.destroy(), 500);
    }

    tip.reference.classList.remove("tutorial-glow");
  }

  /**
   * Hide all tips.
   */
  closeAllTips(includeEndTutorial = false, immediately = false) {
    Object.entries(this.tippyInstances || {}).forEach(([target, tip]) => {
      this.closeTip(tip, immediately);
    });

    if(includeEndTutorial) this.closeTip(this.endTutorialTip, immediately);

    document.querySelectorAll(".tutorial-glow").forEach((el) => el.classList.remove("tutorial-glow"));
  }

  /**
   * Submit a request to the server to nullify tutorial steps, effectively ending the tutorial.
   * Then, hide all currently-visible tips.
   */
  async destroyTutorial() {
    if(this.destroyPending) return;

    this.destroyPending = true;

    const response = await fetch("/me/tutorial", {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken()
      }
    });

    if(response.ok) this.closeAllTips(true);
    this.metaElement.remove();

    this.destroyPending = false;
    return response;
  }

  /**
   * Confetti!
   */
   handleConfetti = (e) => {
     if(e.target.dataset["confetti"]) confetti();
   }

}
