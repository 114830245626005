import Rails from "@rails/ujs";
import _ from "lodash";

const UNSET = Symbol("UNSET");

export default class DomHelpers {
  hasClass(el, className) {
    if (el.classList)
      return el.classList.contains(className);
    else
      return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'))
  }

  hasDataAttribute(dataset, attribute) {
    const set = Object.assign({}, dataset);
    return _.has(set, attribute);
  }

  getData(element, path, defaultValue = undefined) {
    const dataset = _.get(element, "dataset");
    const value = _.get(dataset, path, UNSET);

    if (value === UNSET) {
      return defaultValue;
    }

    try {
      return JSON.parse(value);
    } catch (_e) {
      return value;
    }
  }

  getRawMetaContent(name) {
    const selector = `meta[name="${name}"]`;

    return _.chain(document).invoke("querySelector", selector).get("content");
  }

  getMetaContentCSV(name) {
    return this.getRawMetaContent(name).toString().split(",").value()
  }

  getMetaContentString(name) {
    return this.getRawMetaContent(name).toString().value();
  }

  addClass(el, className) {
    if (el.classList) {
      el.classList.add(className)
    } else if (!this.hasClass(el, className)) {
      el.className += " " + className
    }
  }

  removeClass(el, className) {
    if (el.classList) {
      el.classList.remove(className);
    } else if (this.hasClass(el, className)) {
      const reg = new RegExp('(\\s|^)' + className + '(\\s|$)')
      el.className=el.className.replace(reg, ' ')
    }
  }

  addAttribute(el, attributeName, attributeValue) {
    if (el && attributeName) {
      el.setAttribute(attributeName, attributeValue || '');
    }
  }

  removeAttribute(el, attributeName) {
    if (el && el.hasAttribute(attributeName)) {
      el.removeAttribute(attributeName);
    }
  }

  toggleClass(el, className) {
    if (el.classList) {
      el.classList.toggle(className);
    } else {
      const classes = el.className.split(' ');
      const existingIndex = classes.indexOf(className);

      if (existingIndex >= 0)
        classes.splice(existingIndex, 1);
      else
        classes.push(className);

      el.className = classes.join(' ');
    }
  }

  show(els) {
    const targets = els instanceof Array ? els : Array.from(arguments);
    targets.forEach((el) => { if(el) el.classList.remove("d-none"); })
  }

  hide(els) {
    const targets = els instanceof Array ? els : Array.from(arguments);
    targets.forEach((el) => { if(el) el.classList.add("d-none"); })
  }

  /**
   * @return {boolean}
   */
  formIsValid(form) {
    if (!form || typeof form.checkValidity !== "function") {
      return true;
    }

    return form.checkValidity();
  }

  ready(f) {
    if(document.readyState != 'loading') {
      f();
    } else {
      document.addEventListener('DOMContentLoaded', f);
    }
  }

  serializeFormToObject(element, additionalParam) {
    const params = [];
    let inputs = [element];

    if (Rails.matches(element, "form")) {
      inputs = _.toArray(element.elements);
    }

    inputs.forEach(function(input) {
      if (!input.name || input.disabled) {
        return;
      }

      if (Rails.matches(input, 'select')) {
        return toArray(input.options).forEach(function(option) {
          if (option.selected) {
            return params.push({
              name: input.name,
              value: option.value
            });
          }
        });
      } else if (input.checked || ['radio', 'checkbox', 'submit'].indexOf(input.type) === -1) {
        return params.push({
          name: input.name,
          value: input.value
        });
      }
    });

    if (additionalParam) {
      params.push(additionalParam);
    }

    const serialized = {};

    return _.transform(params, (res, param, _index) => {
      if (param.name != null) {
        const { name, value } = param;

        _.set(res, name, value);
      }
    }, serialized);
  }

  /**
   * @param {object} options
   * @param {string} options.title
   * @param {string} options.body
   * @param {string} options.yayLabel
   * @param {string} [options.nayLabel]
   * @param {boolean} [options.hideNay]
   * @param {function} [options.onYay]
   * @param {function} [options.onNay]
   * @return {void}
   */
  showModal(options = {}) {
    const { title, body, form, yayLabel, nayLabel, onYay = _.noop, onNay = _.noop } = options;

    let { hideNay, hideYay, hideClose } = options;

    if (typeof hideNay !== "boolean") {
      hideNay = !Boolean(nayLabel);
    }

    const detail = {
      title, body, form, yayLabel, nayLabel, onYay, onNay, hideNay, hideYay, hideClose
    };

    for (const handler of ["onYay", "onNay"]) {
      if (typeof detail[handler] !== "function") {
        detail[handler] = _.noop
      }
    }

    document.dispatchEvent(new CustomEvent("showModal", { detail }));
  }

  insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  getScrollTop() {
    return document.body.scrollTop || document.documentElement.scrollTop;
  }

  setScrollTop(value) {
    document.body.scrollTop = value;
    document.documentElement.scrollTop = value;
  }

  closest(el, selector) {
    const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
    while (el) {
      if (matchesSelector.call(el, selector)) {
        break;
      }
      el = el.parentElement;
    }
    return el;
  }

  matches(elm, selector) {
    let matches = (elm.document || elm.ownerDocument).querySelectorAll(selector),
        i = matches.length;
    while (--i >= 0 && matches.item(i) !== elm) {}
    return i > -1;
  }

  targetMatches(target, selector) {
    if (typeof target.matches === "function") {
      return target.matches(selector);
    } else if (typeof target.msMatchesSelector === "function") {
      return target.msMatchesSelector(selector);
    } else {
      console.warn("Missing target.matches or target.msMatchesSelector for %s", selector);
      console.dir(target);

      return false;
    }
  }

  on(selector, event, handler) {
    const wrappedHandler = (evt) => {
      let target = evt.target;

      while (target != null) {
        const isMatch = this.targetMatches(target, selector);

        if (isMatch) {
          handler(evt);

          return;
        }

        target = target.parentElement;
      }
    };

    document.addEventListener(event, wrappedHandler, true);

    const off = () => {
      document.removeEventListener(event, wrappedHandler, true);
    };

    return { selector, event, off, wrappedHandler };
  }

  debounce(fn, delay) {
    let timer = null;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        fn.apply(context, args);
      }, delay);
    };
  }

  /**
   * @param {string} url
   * @return {void}
   */
  visit(url) {
    Turbo.visit(url);
  }
}
