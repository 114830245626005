import * as React from "react";
import useMenuState from "./useMenuState";
import MenuContent from "./MenuContent";
import classNames from "classnames";

import type { PostLocalizedContentScope } from '../../../../types/posts/post_types';

type Props = {
  label: Label;
  scopedToTeam?: boolean;
  showOrg?: boolean;
  showTeam?: boolean;
  localizedContent: PostLocalizedContentScope;
  handleChange: (value: string) => void;
};

export type Label = "event" | "team" | "org" | "myself" | "similar_actions";

export default function ScopeDropdown({
  label,
  handleChange,
  scopedToTeam,
  localizedContent,
  ...menuProps
}: Props) {
  const { disclosureProps, contentProps } = useMenuState<
    HTMLButtonElement,
    HTMLUListElement
  >(false, true);

  const toggleClasses = classNames("dropdown-toggle", {
    open: contentProps.visible,
  });

  return (
    <div className="dropdown-toggle-wrapper">
      <button
        className={toggleClasses}
        {...disclosureProps}
        type="button"
        disabled={!!scopedToTeam}
      >
        <span>{scopedToTeam ? localizedContent.team : localizedContent[label]}</span>
        {!scopedToTeam && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 113.44 64"
            height={16}
            width={16}
          >
            <title>caretDown</title>
            <polygon points="56.72 64 0 7.28 7.28 0 56.72 49.54 106.26 0 113.44 7.28 56.72 64" />
          </svg>
        )}
      </button>
      <MenuContent
        handleChange={handleChange}
        localizedContent={localizedContent}
        {...contentProps}
        {...menuProps}
      />
    </div>
  );
}
