import DomHelpers from "../utility/dom_helpers.js"

export default class LinkedSelects {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;
    this.handleParentSelectChange = this.handleParentSelectChange.bind(this);
    this.checkAllChildStates = this.checkAllChildStates.bind(this);
  }

  bindListeners() {
    document.addEventListener('change', this.handleParentSelectChange);
    document.addEventListener('turbo:load', this.checkAllChildStates);
  }

  handleParentSelectChange(event) {
    const target = event.target;
    if (this.isParentSelect(event.target)) {
      const form = this.dh.closest(target, 'form');
      this.checkLinkedChildren(form, event.target);
    }
  }

  checkAllChildStates() {
    const parents = document.querySelectorAll('[data-field-function-parent]');
    [...parents].forEach((parent) => {
      const form = this.dh.closest(parent, 'form');
      if (form) {
        this.checkLinkedChildren(form, parent);
      }
    });
  }

  isParentSelect(el) {
    if (el.dataset.hasOwnProperty('fieldFunctionParent')) return true;
    if (this.dh.closest(el, '[data-field-function-parent]')) return true;
    return false;
  }

  checkLinkedChildren(form, parent) {
    const parentIdentifier = parent.dataset.fieldName;
    const value = parent.value;
    const selector = '[data-field-function-child="'+ parentIdentifier +'"]';
    const linkedChildren = form.querySelectorAll(selector);
    [...linkedChildren].forEach((child) => {
      if (child.dataset.hasOwnProperty('showOnParentValue')) {
        if (child.dataset.showOnParentValue == value) {
          child.style.display = '';
        } else {
          child.style.display = 'none';
        }
      }
    });
  }

};
