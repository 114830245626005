import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js"

export default class AutoCompletes {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;

    this.updateList = this.updateList.bind(this);
    this.handleFetchResponse = this.handleFetchResponse.bind(this);
    this.actOnFetchResponse = this.actOnFetchResponse.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.ensureInputListeners = this.ensureInputListeners.bind(this);
    this.ensureResultsContainers = this.ensureResultsContainers.bind(this);
    this.disableBrowserAutoComplete = this.disableBrowserAutoComplete.bind(this);
    this.resultsTemplate = _.template('<% _.forEach(items, function(item) { %><li><%- item %></li><% }); %>');
  }

  bindListeners() {
    const debounced = _.debounce(this.handleInputChange, 150);

    document.addEventListener('keyup', debounced);
    document.addEventListener('change', debounced);
    document.addEventListener('click', this.handleItemClick);
    document.addEventListener('turbo:load', this.ensureResultsContainers);
    document.addEventListener('turbo:load', this.disableBrowserAutoComplete);
    document.addEventListener('turbo:load', this.ensureInputListeners);
  }

  ensureInputListeners() {
    const wrappers = document.querySelectorAll('[data-auto-complete]');
    [...wrappers].forEach((wrapper) => {
      if(!wrapper.dataset.hasOwnProperty('autoCompleteInitialized')) {
        const input = wrapper.querySelector('input');
        input.addEventListener('blur', (event) => {
          setTimeout(() => {
            this.clearAndHideResults(wrapper)
          }, 200);
        });
        input.addEventListener('focus', (event) => {
          this.updateList(wrapper)
        });

        wrapper.dataset.autoCompleteInitialized = true;
      }
    })
  }

  handleInputChange(event) {
    if (!this.isAutoCompleteInput(event.target)) return false;
    const wrapper = this.dh.closest(event.target, '[data-auto-complete');
    this.updateList(wrapper);
  }

  handleItemClick(event) {
    if (!this.isAutoCompleteListItem(event.target)) return false;
    const wrapper = this.dh.closest(event.target, '[data-auto-complete');
    this.updateInput(event.target, wrapper);
  }

  disableBrowserAutoComplete() {
    const wrappers = document.querySelectorAll('[data-auto-complete]');
    [...wrappers].forEach((wrapper) => {
      wrapper.querySelector('input').setAttribute("autocomplete", "off");
    })
  }

  updateInput(item, wrapper) {
    const input = wrapper.querySelector('input');
    input.value = item.innerHTML;
    this.clearAndHideResults(wrapper);
  }

  ensureResultsContainers() {
    const wrappers = document.querySelectorAll('[data-auto-complete]');
    [...wrappers].forEach((wrapper) => {
      this.ensureResultsContainer(wrapper);
    })
  }

  isAutoCompleteInput(el) {
    if (el.dataset.hasOwnProperty('autoComplete')) return true;
    if (this.dh.closest(el, '[data-auto-complete]')) return true;
    return false;
  }

  isAutoCompleteListItem(el) {
    if (this.dh.closest(el, '[data-auto-complete]') && el.tagName == 'LI') return true;
    return false;
  }

  ensureResultsContainer(wrapper) {
    if (wrapper.querySelector('div.autocomplete-results')) return;
    const input = wrapper.querySelector('input');
    const results = document.createElement('div');
    results.classList.add('autocomplete-results');
    results.style.display = 'none';
    this.dh.insertAfter(results, input);
    this.ensureResultsList(results);
    return results;
  }

  ensureResultsList(results) {
    const list = document.createElement('ul');
    list.classList.add('autocomplete-list');
    results.appendChild(list);
    return list;
  }

  clearAndHideResults(wrapper) {
    wrapper.querySelector('div.autocomplete-results').style.display = 'none';
    wrapper.querySelector('ul.autocomplete-list').innerHTML = "";
  }

  updateList(wrapper) {
    const input = wrapper.querySelector('input');
    const searchWord = input.value;
    if (searchWord.length < 1) {
      this.clearAndHideResults(wrapper);
    } else {
      const target = `${wrapper.dataset.autoComplete}/?search=${searchWord}`;
      fetch(target, {
        method: 'get'
      }).then((response) => { this.handleFetchResponse(wrapper, response) });
    }
  }

  actOnFetchResponse(items, wrapper) {
    const focused = document.activeElement;
    const input = wrapper.querySelector('input');
    if (items.length == 0 || focused !== input) {
      this.clearAndHideResults(wrapper);
      return;
    }
    const results = this.resultsTemplate({ 'items': items });
    wrapper.querySelector('div.autocomplete-results').style.display = 'block';
    wrapper.querySelector('ul.autocomplete-list').innerHTML = results;
  }

  handleFetchResponse(wrapper, response) {
    response.json().then((json) => { this.actOnFetchResponse(json, wrapper) })
  }

};
