import React, { PropsWithChildren, useEffect, useRef } from "react";
// import Tippy from "@tippyjs/react/headless";
import Tippy, { TippyProps } from "@tippyjs/react";
import TippyHeadless, {
  TippyProps as TippyHeadlessProps,
} from "@tippyjs/react/headless";

type Props = {
  children: JSX.Element;
} & Omit<TippyHeadlessProps, "className">;

export default function Tooltip({
  children,
  content,
  placement = "bottom",
  ...tippyProps
}: Props) {
  return (
    <TippyHeadless
      render={(attrs) => (
        <div className="tooltip-light" {...attrs}>
          {content}
          <div className="tooltip-light__arrow" data-popper-arrow="">
            hi i'm arrow
          </div>
        </div>
      )}
      placement={placement}
      {...tippyProps}
    >
      <div>{children}</div>
    </TippyHeadless>
  );
}
