export default class Disclosure {
  bindListeners() {
    // Set an identifier for each disclosure and disclosure open trigger
    document.querySelectorAll("[data-disclosure]").forEach((el, i) => {
      el.setAttribute("data-disclosure", i);
    });
    document.querySelectorAll("[data-disclosure-open]").forEach((el, i) => {
      el.addEventListener("click", this.handleClick);
      el.setAttribute("data-disclosure-open", i);
    });
  }

  handleClick(e) {
    // Stop any events from being triggered
    e.stopPropagation();
    e.preventDefault();
    const toggleEl = e.target;

    // Get this disclosure's identifier
    const i = toggleEl.getAttribute("data-disclosure-open");

    // If the element has query params, silently update the url query
    if (toggleEl.href && window) {
      const path = window.location.href.split("?")[0];
      const query = toggleEl.href.split("?")[1];
      const newPath = `${path}?${query}`;
      window.history.pushState({ path: newPath }, "", newPath);
    }

    // Toggle open state for all disclosures on the page
    const content = document.querySelectorAll(`[data-disclosure]`);
    content.forEach((el) => {
      const j = el.getAttribute("data-disclosure");
      if (i === j) {
        el.setAttribute("data-open", "true");
      } else {
        el.removeAttribute("data-open");
      }
    });
  }
}
