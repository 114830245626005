export default class EcoChallengeBody {

  constructor() {
    this.handleLoad = this.handleLoad.bind(this);
  }

  bindListeners() {
    window.addEventListener('load', this.handleLoad);
    document.addEventListener('turbo:render', this.handleLoad);
  }

  adjustHeight() {
    if (document.body.classList.contains('layout-dashboard')) {
      function heightAggregator(element, additional) {
        var heights = 0;
        [...element.childNodes].forEach(function(node) {
          if (node.offsetHeight) {
            heights = heights + node.offsetHeight;
          }
        });
        additional.forEach(function(value) {
          heights = heights + value;
        });
        return heights;
      }

      const sidebar= document.querySelector('.dashboard-sidebar');
      const main = document.querySelector('.dashboard-main');

      if (sidebar && main) {
        const sidebarHeight = heightAggregator(sidebar, [parseInt(window.getComputedStyle(sidebar).paddingTop, 10)]);

        if (sidebarHeight) {
          sidebar.style.minHeight = `${sidebarHeight}px`;
          main.style.minHeight = `${sidebarHeight}px`;
        }
      }
    }
  }

  handleLoad() {
    this.adjustHeight();
  }

};
