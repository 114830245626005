import { useState, useEffect, useMemo, useRef, useId } from "react";

export default function useDisclosureState<
  T extends HTMLElement,
  N extends HTMLElement
>(initialVisible = false, clickOutsideToHide: boolean) {
  const [visible, setVisible] = useState(initialVisible);
  const toggleVisible = () => setVisible(!visible);

  const id = useId();

  const disclosureRef = useRef<T | null>(null);
  const contentRef = useRef<N | null>(null);

  const hideOnClickOutside = (e: React.MouseEvent<HTMLElement>) => {
    if (
      disclosureRef?.current &&
      disclosureRef.current.contains(e.target as Node)
    )
      return;
    if (contentRef?.current && contentRef.current.contains(e.target as Node))
      return;

    setVisible(false);
  };

  useEffect(() => {
    if (clickOutsideToHide) {
      document.addEventListener(
        "click",
        (hideOnClickOutside as unknown) as EventListener
      );
      return () =>
        document.removeEventListener(
          "click",
          (hideOnClickOutside as unknown) as EventListener
        );
    }
  });

  function handleKeyDown(event: React.KeyboardEvent<HTMLElement>) {
    if (event.key !== "Escape") return;
    setVisible(false);
    if (disclosureRef.current) disclosureRef.current.focus();
  }

  const disclosureProps = {
    ref: disclosureRef,
    type: "button",
    visible,
    "aria-expanded": visible,
    "aria-controls": id,
    onClick: toggleVisible,
    onKeyDown: handleKeyDown,
  };
  const contentProps = {
    ref: contentRef,
    id,
    visible,
    toggleVisible,
    onKeyDown: handleKeyDown,
  };

  const state = useMemo(
    () => ({
      visible,
      disclosureProps,
      contentProps,
    }),
    [visible, id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    setVisible(initialVisible);
  }, [initialVisible]);

  return state;
}
