import * as React from "react";
import classNames from "classnames";

import type { PostLocalizedContentScope } from '../../../../types/posts/post_types';

type Props = {
  visible: boolean;
  toggleVisible: () => void;
  ref: React.MutableRefObject<HTMLUListElement | null>;
  id: string;
  onKeyDown: (e: React.KeyboardEvent<HTMLElement>) => void;
  handleChange: (val: string) => void;
  showOrg?: boolean;
  showTeam?: boolean;
  localizedContent: PostLocalizedContentScope;
};

export default function MenuContent(props: Props) {
  const {
    visible,
    handleChange,
    toggleVisible,
    showOrg,
    showTeam,
    localizedContent,
    ...restProps
  } = props;

  const dropdownClasses = classNames("dropdown dropdown-wrapper", {
    open: visible,
  });

  const onClick = (value: string) => () => {
    handleChange(value);
    toggleVisible();
  };

  return (
    <div className="dropdown-wrapper">
      <ul className={dropdownClasses} {...restProps}>
        <li>
          <button onClick={onClick("event")} className="dropdown-filter">
            {localizedContent.event}
          </button>
        </li>
        {showOrg && (
          <li>
            <button onClick={onClick("org")} className="dropdown-filter">
              {localizedContent.org}
            </button>
          </li>
        )}
        {showTeam && (
          <li>
            <button onClick={onClick("team")} className="dropdown-filter">
              {localizedContent.team}
            </button>
          </li>
        )}
        <li>
          <button onClick={onClick("myself")} className="dropdown-filter">
            {localizedContent.myself}
          </button>
        </li>
        <li>
          <button
            onClick={onClick("similar_actions")}
            className="dropdown-filter"
          >
            {localizedContent.similar_actions}
          </button>
        </li>
      </ul>
    </div>
  );
}
