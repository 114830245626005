import React from "react";
import baseSvgProps from "./baseSvgProps";

const ChevronRightSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" {...baseSvgProps} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m5.72 14.957-1.378-1.414L9.99 7.75 4.342 1.957 5.72.543l7.027 7.207-7.027 7.207Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronRightSvg;
