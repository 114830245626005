import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    document.addEventListener("turbo:load", this.calculateFontSize.bind(this));
  }

  calculateFontSize () {
    if (!this.isOverflown()) { return; }

    let fontSizeStr = this.element.style.fontSize || window.getComputedStyle(this.element, null).getPropertyValue('font-size');
    let [_, fontSize, unit] = this.extractFontSizeValue(fontSizeStr);
    const step = this.stepFor(unit)

    while (this.isOverflown() && fontSize > 0) {
      fontSize -= step;
      this.resize(fontSize, unit);
    }

    setTimeout(() => {
      if(this.isOverflown()) {
        fontSize -= step;
        this.resize(fontSize, unit);
      }
    }, 300)
  }

  isOverflown() {
    return this.element.scrollWidth > this.element.clientWidth;
  }

  resize(fontSize, unit) {
    
    this.element.style.fontSize = `${fontSize}${unit}`;
  }

  extractFontSizeValue(string) {
    return string.match(/([\d.]+)(\w+)/);
  }

  stepFor(unit) {
    if(unit.includes("em")) {
      return 0.1;
    } else {
      return 1;
    }
  }

}