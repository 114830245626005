import cable from "../../cable.js";

export default function() {
  cable.subscriptions.create("ParticipantUpdatesChannel", {
    connected: () => {
    },

    disconnected: () => {
    },

    rejected: () => {
    },

    received: (data) => {
      if (data.updates) {
        data.updates.forEach((update) => {
          const attr = '[' + update.key + ']';

          [...document.querySelectorAll(attr)].forEach((el) => {
            el.innerHTML= update.content;
          });
        });
      }

      if (data.award_points_list) {
        [...document.querySelectorAll('[data-award-points-list]')].forEach((el) => {
          el.innerHTML = data.award_points_list;
        });
      }

      [...document.querySelectorAll(`[data-points-event-burst="${data.id}"]`)].forEach((el) => {
        el.innerHTML = data.points_event_burst;
      });

      [...document.querySelectorAll(`[data-points-team-burst="${data.id}"]`)].forEach((el) => {
        el.innerHTML = data.points_team_burst;
      });

      [...document.querySelectorAll(`[data-points-total="${data.id}"]`)].forEach((el) => {
        el.innerHTML = data.current_point_total;
      });

      [...document.querySelectorAll(`[data-points-this-week="${data.id}"]`)].forEach((el) => {
        el.innerHTML = data.points_this_week;
      });

      [...document.querySelectorAll(`[data-team-points="${data.id}"]`)].forEach((el) => {
        el.innerHTML = data.team_current_point_total;
      });

      [...document.querySelectorAll(`[data-team-total-points-during-team-burst="${data.id}"]`)].forEach((el) => {
        el.innerHTML = data.team_total_points_during_team_burst;
      });
    }
  });
}
