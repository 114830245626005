import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js"

class ActionSelectionContext {
  constructor({ actions, event, parent, target, trigger, form = null }) {
    _.defProps(this, { actions, event, form, parent, target, trigger });

    const params = this.getTargetData("currentParams", {});

    _.defProps(this, { params });
  }

  get apiPath() {
    return this.isCustomActionCrudEvent ? this.customActionApiPath : this.selectionApiPath;
  }

  get selectionApiPath() {
    return _.get(this, "parent.dataset.apiPath");
  }

  get customActionApiPath() {
    return _.get(this, "parent.dataset.customActionApiPath");
  }

  get dh() {
    return this.actions.dh;
  }

  get customAction() {
    return this.getParentData("customAction", null);
  }

  get customizable() {
    return !this.customAction && this.getParentData("customizable", false);
  }

  get isCustomAction() {
    return Boolean(this.customAction);
  }

  get isCustomActionCrudEvent() {
    return (this.isCustomAction && this.trigger.includes("cyo-"));
  }

  get teammatesAtActionLimit() {
    return parseInt(this.parent.dataset.teammatesAtActionLimit) > 0;
  }

  get forPreexisting() {
    return _.get(this, "params.preExisting", false);
  }

  get hasCompletedActions() {
    return this.getParentData("participantActionHasCompleted", false);
  }

  get button() {
    return this.parent.querySelector("[data-describe-button] [data-action-select]")
  }

  get selectButton() {
    return this.button;
  }

  get teammateActionLimitWarning() {
    return this.parent.querySelector("[data-warning='teammate-action-limit']")
  }

  get destroyButton() {
    return this.parent.querySelector("[data-destroy-controls] [data-action-destroy]")
  }

  get warning() {
    return this.parent.querySelector("[data-warning='deselect']");
  }

  get destroyCancel() {
    return this.parent.querySelector("[data-destroy-nevermind]");
  }

  get destroyControls() {
    return this.parent.querySelector("[data-destroy-controls]");
  }

  get teammateActionLimitWarning() {
    return this.parent.querySelector("[data-warning='teammate-action-limit']")
  }

  get selectCancel() {
    return this.parent.querySelector("[data-select-nevermind]");
  }

  get selectControls() {
    return this.parent.querySelector("[data-select-controls]");
  }

  get description() {
    return this.parent.querySelector("[data-description]");
  }

  get cyoControls() {
    return this.parent.querySelector("[data-cyo-controls]")
  }

  get utility() {
    return this.parent.querySelector("[data-utility] a");
  }

  get content() {
    return this.parent.querySelector("[data-action-content]");
  }

  getParentData(path, defaultValue) {
    return this.dh.getData(this.parent, path, defaultValue);
  }

  getTargetData(path, defaultValue) {
    return this.dh.getData(this.target, path, defaultValue);
  }

  buildPayloadForCreate() {
    if (this.isCustomAction) {
      if (!this.form) {
        // When deleting / deselecting a custom action, or selecting
        // a previously-deselected custom action, there's no payload
        // to send.
        return {};
      }

      return this.dh.serializeFormToObject(this.form);
    }

    const selection = {
      is_preexisting: this.forPreexisting
    };

    const inputs = this.parent.querySelectorAll("[data-input]");

    _.each(inputs, (input) => {
      selection[input.name] = this.sanitizeInput(input.value, input.getAttribute("type"));
    });

    return { selection };
  }

  sanitizeInput(value, type) {
    if (type === "number") {
      return _.truncate(value, { length: 10, omission: "" });
    } else {
      return value;
    }
  }
}

export default class Actions {
  constructor(dh = new DomHelpers()) {
    this.dh = dh;
    this.warned = false;

    _.bindAll(this, "handleActionLinkAdd", "handleActionLinkRemove", "handleActionSelect", "handleOverlayClick", "handleAssignmentChange");
  }

  bindListeners() {
    this.dh.on("[data-action-link-add]", "click", this.handleActionLinkAdd);
    this.dh.on("[data-action-link-remove]", "click", this.handleActionLinkRemove);
    this.dh.on(".assignment-options__input", "change", this.handleAssignmentChange);
    this.dh.on("[data-action] [data-trigger]", "click", this.handleActionSelect);
    this.dh.on("[data-custom-action] [data-trigger]", "click", this.handleActionSelect)
    this.dh.on(".overlay", "click", this.handleOverlayClick);
  }

  handleActionSelect(event) {
    const { target } = event;
    const { trigger } = target.dataset;

    const parent = this.dh.closest(target, "[data-action], [data-custom-action]");
    const form = this.dh.closest(target, "form");

    const context = new ActionSelectionContext({
      target, trigger, event, form, parent, actions: this
    });

    event.preventDefault();

    switch (trigger) {
      case "destroy-confirm":
        this.setStateDestroyConfirm(context);

        break;
      case "destroy":
      case "cyo-destroy":
        this.startDestroy(context);

        break;
      case "destroy-nevermind":
        this.setStateSelected(context);

        break;
      case "select-confirm":
        this.setStateSelectConfirm(context);
        break;
      case "select-nevermind":
        this.setStateUnselected(context);
        break;
      case "create":
        if (this.validateCustomization(context)) {
          this.startCreate(context);
        }

        break;
      case "customize":
        if (context.forPreexisting) {
          return this.startCreate(context);
        }

        this.showCustomize(context);

        break;
      case "cyo-update":
        if(context.isCustomAction) {
          this.startUpdate(context);
        }

        break;
    }
  }

  handleActionLinkAdd(event) {
    event.preventDefault();

    const { target } = event;

    const container = target.parentElement;

    if (!container) {
      return null;
    }

    const valueToInt = _.flow(_.property("value"), _.toSafeInteger);

    const lastIndex = _.chain(container.querySelectorAll(`[type="hidden"][name$="[index]"]`)).map(valueToInt).max().value() || 0;

    const nextIndex = lastIndex + 1;

    const template = _.get(event, "target.dataset.actionLinkTemplate", "");

    const replaced = template.replace(/REPLACE_INDEX/g, nextIndex);

    target.insertAdjacentHTML("beforebegin", replaced);

    this.toggleActionLinkRemove(container);
  }

  handleActionLinkRemove(event) {
    event.preventDefault();

    const { target } = event;

    const container = this.dh.closest(target, "[data-action-link-container]");
    const group = this.dh.closest(target, "[data-action-link-group]");

    if (!container || !group) {
      return null;
    }

    const destroy = container.querySelector("[data-action-link-destroy]");
    if(destroy) {
      destroy.value = "true";
    }

    group.remove();

    this.toggleActionLinkRemove(container);
  }

  handleAssignmentChange(event) {
    const targetURL = _.get(event, "target.value", null);

    if (targetURL) {
      this.dh.visit(targetURL);
    }
  }

  validateCustomization(context) {
    const inputs = context.parent.querySelectorAll("[data-input]");

    const validationResults = _.map(inputs, (input) => {
      const wrapper = this.dh.closest(input, ".form-field");

      const value = input.value;

      if (value || this.dh.targetMatches(input, "[data-optional]")) {
        this.dh.removeClass(wrapper, "error");

        return true;
      } else {
        this.dh.addClass(wrapper, "error");

        return false;
      }
    });

    return _.every(validationResults, _.identity);
  }

  handleOverlayClick(event) {
    event.preventDefault();

    this.hideCustomize();
  }

  startCreate(context) {
    const payload = context.buildPayloadForCreate();

    this.doFetch(context, context.apiPath, "POST", payload);
  }

  startUpdate(context) {
    const payload = context.buildPayloadForCreate();

    this.doFetch(context, context.apiPath, "PUT", payload);
  }

  startDestroy(context) {
    this.doFetch(context, context.apiPath, "DELETE", {});
  }

  async doFetch(context, url, method, payload = null) {
    const options = {
      method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken()
      }
    };

    if (method === "POST" || method === "PUT") {
      options.body = JSON.stringify(payload);
    }

    try {
      const response = await fetch(url, options);

      if (response.redirected && response.status < 300) {
        // Handle turbo redirect
        return this.dh.visit(response.url);
      } else if (response.status >= 300 && !response.redirected) {
        const error = new Error(response.statusText);

        error.response = response;

        throw error;
      }

      let data = response;

      if (method === "POST" || method === "PUT" || method === "GET") {
        data = await response.json();
      }

      await this.handleSuccess(context, data, method);
    } catch (error) {
      await this.handleError(context, error);
    }
  }

  setStateSelected(context) {
    const {description, warning, teammateActionLimitWarning, utility, cyoControls, selectButton, destroyControls, selectControls, content} = context;

    this.dh.show(description, cyoControls, selectButton);
    this.dh.hide(warning, teammateActionLimitWarning, destroyControls, selectControls);

    if (utility) {
      context.utility.dataset.trigger = "destroy";

      if (context.forPreexisting) {
        this.dh.addClass(utility, "selected");
      } else {
        this.dh.hide(utility);
      }
    }

    selectButton.dataset.trigger = (context.hasCompletedActions) ? "destroy-confirm" : "destroy";

    if (context.forPreexisting) {
      this.dh.addClass(content, "pre-selected");

      this.dh.hide(selectButton);
    } else {
      this.dh.addClass(selectButton, "selected");
      this.dh.removeClass(selectButton, "warn");

      selectButton.innerHTML = selectButton.innerHTML.replace(/(Assign|Select)/, "$&ed");
    }
  }

  setStateUnselected(context) {
    const {customizable, selectButton, warning, teammateActionLimitWarning, destroyControls, selectControls, cyoControls, description, utility, content} = context;

    selectButton.dataset.trigger = this.selectButtonTrigger(context)

    this.dh.show(description, cyoControls, selectButton);
    this.dh.hide(warning, teammateActionLimitWarning, destroyControls, selectControls);

    this.dh.removeClass(content, "pre-selected");
    this.dh.removeClass(selectButton, "selected");
    this.dh.removeClass(selectButton, "warn");

    if (utility) {
      utility.dataset.trigger = customizable ? "customize" : "create";
      this.dh.show(utility);

      this.dh.removeClass(utility, "selected");
    }

    selectButton.innerHTML = selectButton.innerHTML.replace(/(Select)ed|(Assign)ed/, "$1$2");
  }

  selectButtonTrigger(context) {
    if(context.teammatesAtActionLimit) {
      return "select-confirm";
    } else if(context.customizable) {
      return "customize";
    } else {
      return "create";
    }
  }

  setStateDestroyConfirm(context) {
    const {selectButton, destroyControls, warning, description, cyoControls} = context;

    this.dh.hide(selectButton, description, cyoControls);
    this.dh.show(destroyControls, warning);

    if(context.isCustomAction && event.target.closest("[data-cyo-controls]")) {
      context.destroyButton.dataset.trigger = "cyo-destroy";
    } else {
      context.destroyButton.dataset.trigger = "destroy";
    }
  }

  setStateSelectConfirm(context) {
    const { selectButton, selectControls, teammateActionLimitWarning, description, cyoControls } = context;

    this.dh.hide(selectButton, description, cyoControls)
    this.dh.show(selectControls, teammateActionLimitWarning);
  }

  async handleSuccess(context, data, method) {
    if (method === "DELETE") {
      // we just deleted the participant action
      this.setStateUnselected(context);
    } else {
      if (data.event_actions_warning_limit) {
        this.openModalWarn(context, data);
      }

      if (data.participant_action_count === 1) {
        this.openModalFirstAction(context, data);
      }

      // we just created or updated a participant action
      this.hideCustomize();

      this.setStateSelected(context);

      const selectEvent = new Event("eco-action-select", { bubbles: true });
      context.parent.dispatchEvent(selectEvent);
    }
  }

  async handleError(context, error) {
    if (error.response && error.response.status) {
      const {response} = error;

      const contentType = response.headers.get("Content-Type");

      console.info("handleError status: %s", response.status);
      console.dir(response);
      console.info("Content-Type: %s", contentType);

      try {
        const errors = await error.response.json();

        console.dir({ errors });

        if (errors.hasOwnProperty("base")) {
          this.openModalLimitReached(errors.base);
        }

        if (errors.hasOwnProperty("input_1_value") || errors.hasOwnProperty("input_2_value") || errors.hasOwnProperty("input_3_value")) {
          this.appendErrors(context, errors);
        }
      } catch (err) {
        console.error(err);

        throw new Error("Missing or invalid response");
      }
    } else {
      throw error;
    }
  }

  openModalFirstAction(context, data) {
    if (context.forPreexisting) {
      return null;
    }

    if (data.participant_action_count > 1) {
      return null;
    }

    let message = `Once the event starts on ${data.participant_start_date} you can begin checking in and sharing your progress from your Dashboard.`;

    if (data.event_daily_limit > 0 && data.event_one_time_limit > 0) {
      const dailyLimit = data.event_daily_limit === 1 ? '1 daily action' : `up to ${data.event_daily_limit} daily actions`;

      const oneTimeLimit = data.event_one_time_limit === 1 ? '1 one-time action' : `up to ${data.event_one_time_limit} one-time actions`;

      message = `You can select up to ${dailyLimit} and ${oneTimeLimit} for this event.<br /><br />` + message
    } else if (data.event_daily_limit > 0) {
      const actionLimit = data.event_daily_limit === 1 ? '1 daily action' : `up to ${data.event_daily_limit} daily actions`;

      message = `You can select ${actionLimit} and unlimited one-time actions for this event.<br /><br />` + message
    } else if (data.event_one_time_limit > 0) {
      const actionLimit = data.event_one_time_limit === 1 ? '1 one-time action' : `up to ${data.event_one_time_limit} one-time actions`;

      message = `You can select unlimited daily actions and ${actionLimit} for this event.<br /><br />` + message
    }

    this.dh.showModal({
      title: "Nice! You have selected your first action.",
      body: message,
      yayLabel: "View my dashboard",
      onYay: () => {
        window.location = "/dashboards"
      },
    });
  }

  openModalWarn(context, data) {
    if (context.forPreexisting) {
      return null;
    }

    if (data.participant_action_count < data.event_actions_warning_limit) {
      return null;
    }

    if (!this.warned) {
      this.dh.showModal({
        title: "Whoa. That's a lot of actions.",
        body: `You may want to try averaging between 1-${data.event_actions_warning_limit} actions per day.`,
        yayLabel: "Sounds good",
      });

      this.warned = true;
    }
  }

  openModalLimitReached(message) {
    this.dh.showModal({
      title: "Action could not be added!",
      body: message,
      yayLabel: "Ok",
    });
  }

  appendErrors(context, errors) {
    for (const key of Object.keys(errors)) {
      const wrapper = context.parent.querySelector(`[data-error-${key.replace(/_/g, "-")}]`);
      if(wrapper)
        wrapper.innerHTML = `${errors[key]}<br>`;
    }
  }

  showCustomize(context) {
    const overlay = document.querySelector(".overlay");

    this.dh.addClass(overlay, "state-customize");
    this.dh.addClass(context.parent, "state-customize");

    const selectEvent = new Event("eco-action-customize", { bubbles: true });
    context.parent.dispatchEvent(selectEvent);
  }

  hideCustomize() {
    const customizeCards = document.querySelectorAll(".challenge-actions-list .state-customize");
    const overlay = document.querySelector(".overlay");

    this.dh.removeClass(overlay, "state-customize");

    _.each(customizeCards, (card) => {
      this.dh.removeClass(card, "state-customize");

      const errors = card.querySelectorAll("[data-errors]");

      _.each(errors, (err) => {
        err.innerHTML = "";
      });
    });
  }

  toggleActionLinkRemove(container) {
    if (!container) {
      return;
    }

    const removeButtons = container.querySelectorAll("[data-action-link-remove]");

    const linkCount = _.size(removeButtons);

    const shouldBeDisabled = linkCount === 1;

    _.each(removeButtons, function(button) {
      if (shouldBeDisabled) {
        button.setAttribute("disabled", true);
      } else {
        button.removeAttribute("disabled");
      }
    });
  }
};
