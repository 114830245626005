import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";

export default class Togglers {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;
    this.handleTogglerClick = this.handleTogglerClick.bind(this);
    this.handleTogglerSelect = this.handleTogglerSelect.bind(this);
    this.toggle = this.toggle.bind(this);
    this.initFormStates = this.initFormStates.bind(this);
    this.keepErrorsOpen = this.keepErrorsOpen.bind(this);
  }

  bindListeners() {
    window.addEventListener('click', this.handleTogglerClick);
    window.addEventListener('change', this.handleTogglerSelect);
    window.addEventListener('turbo:load', this.initFormStates);
  }

  initFormStates() {
    if (!document.querySelector("form")) return;

    const toggleables = document.evaluate('//@*[starts-with(name(.), "data-toggleable")]', document.body, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
    if (!toggleables) return;

    for (let i = 0; i < toggleables.snapshotLength; i++) {
      const toggableable = toggleables.snapshotItem(i).ownerElement;
      this.keepErrorsOpen(toggableable);
    }
  }

  handleTogglerClick(event) {
    if(event.target.tagName == "SELECT") return;
    
    let toggle;
    if (event.target.dataset && event.target.dataset.hasOwnProperty('toggleTarget')) {
      toggle = event.target;
    } else {
      toggle = this.dh.closest(event.target, '[data-toggle-target]');
    }
    if (toggle) {
      event.preventDefault();
      event.stopPropagation();
      this.toggle(toggle);
    }
  }

  handleTogglerSelect(event) {
    const toggleable = event.target.dataset["toggleTarget"];
    if(!toggleable) return;

    const selected = event.target.selectedOptions[0];
    if(!selected) return;

    // If selected option doesn't have an action specified, fallback to the
    // select's action, else default to close
    const action = selected.dataset["toggleAction"] || event.target.dataset["toggleAction"] || "close";

    event.preventDefault();
    event.stopPropagation();
    this.toggle(selected, toggleable, action);
  }

  toggle(toggle, toggleable = null, action = null) {
    let toggleables;
    const toggleableId = toggleable || toggle.dataset.toggleTarget;
    const toggleAction = action || toggle.dataset.toggleAction || "toggle";

    if(toggleableId == "all") {
      toggleables = document.querySelectorAll(".form-divider-group");
    } else {
      toggleables = document.querySelectorAll(`[data-toggleable=${toggleableId}]`);
    }
    [...toggleables].forEach((toggleable) => {
      this.doAction(toggleable, toggleAction);
    });
  }

  doAction(toggleable, action = "toggle") {
    switch(action) {
      case "toggle":
        this.dh.toggleClass(toggleable, "closed");
        break;
      case "open":
        this.dh.removeClass(toggleable, "closed");
        break;
      case "close":
        this.dh.addClass(toggleable, "closed");
    }
  }

  // In forms, if there is a child field with errors, make sure the parent is left open
  keepErrorsOpen(toggle) {
    const fields = toggle.querySelectorAll(".form-field");
    if (!fields) return false;
    [...fields].forEach(field => {
      const errors = field.querySelector(".field_with_errors");
      if (errors) return this.dh.removeClass(toggle, "closed");
    })
  }
};
