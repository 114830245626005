import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";

export default class DashboardCalendars {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;

    this.handleCalendarNav = this.handleCalendarNav.bind(this);
  }

  bindListeners() {
    document.addEventListener('click', this.handleCalendarNav);
  }

  handleCalendarNav(event) {
    if (!this.mayHandleCalendarNav(event.target)) return;
    event.preventDefault();
    if (_.has(event.target.dataset, 'calendar')) {
      var parent = this.dh.closest(event.target, '[data-dropdown-calendar]');
    } else if (_.has(event.target.dataset, 'checkIn')) {
      var parent = this.dh.closest(event.target, '[data-check-in-days]');
    }
    this.doFetch(event.target.href, parent)
    }

  mayHandleCalendarNav(element) {
    return _.has(element.dataset, 'prevMonth') || _.has(element.dataset, 'nextMonth');
  }

  parseJSON(response) {
    return response.json()
  }

  doFetch(url, element) {
    const init = {
      method: "GET",
      credentials: 'include',
      headers: {
        "content-type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    };
    fetch(url, init).then(this.checkStatus).then(this.parseJSON).then(
        (response) => { this.handleSuccess(element, response) }
    ).catch((error) => { this.handleError(error)})
  }

  handleSuccess(element, data) {
    element.innerHTML = data.html;
  }

  checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response
    } else {
      var error = new Error(response.statusText || response.status);
      error.response = response;
      throw error
    }
  }

  handleError(card, error) {
    // TODO: Show this error to the user
  }

}
