import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js"

export default class CountrySelects {
  constructor(dh = new DomHelpers()) {
    this.dh = dh;

    const countryCodes = this.dh.getMetaContentCSV("location-countries");

    const isLocationCountry = _.chain(countryCodes).map((countryCode) => {
      return _.matchesProperty("value", countryCode)
    }).overSome().value();

    this.isLocationCountry = isLocationCountry;

    _.bindAll(
      this,
      "handleCountrySelectChange",
      "getCountryWrap",
      "getCountryInput",
      "checkAllFormStates",
      "checkState"
    );
  }

  bindListeners() {
    document.addEventListener("change", this.handleCountrySelectChange);
    document.addEventListener("turbo:load", this.checkAllFormStates);
  }

  handleCountrySelectChange(event) {
    const target = event.target;

    if (this.isCountrySelect(event.target)) {
      const form = this.dh.closest(target, "form");
      this.checkState(form);
    }
  }

  checkAllFormStates() {
    const forms = document.querySelectorAll("[data-form-country]");

    [...forms].forEach((form) => {
      this.checkState(form);
    });
  }

  isCountrySelect(el) {
    if (el.dataset.hasOwnProperty("formFunction") && el.dataset.formFunction === "country") return true;

    if (this.dh.closest(el, `[data-form-function="country"]`)) return true;

    return false;
  }

  getWrapperFor(form, name) {
    return form.querySelector(`[data-form-function="${name}"]`);
  }

  getInputFor(form, name) {
    const wrapper = this.getWrapperFor(form, name);

    if (!wrapper) {
      return null;
    }

    return wrapper.querySelector(`[name$="[${name}]"]`);
  }

  getCountryWrap(form) {
    return this.getWrapperFor(form, "country");
  }

  getCountryInput(form) {
    const el = this.getCountryWrap(form);

    return el ? el.querySelector("select") : null;
  }

  toggleDisplayFor(form, name, value) {
    const displayValue = value ? "" : "none";

    const el = this.getWrapperFor(form, name);

    if (el) {
      _.set(el, "style.display", displayValue);

      this.toggleInputFor(form, name, value);
    }
  }

  toggleInputFor(form, name, value) {
    const input = this.getInputFor(form, name);

    if (!input) {
      return null;
    }

    value = Boolean(value);

    if (Boolean(value)) {
      input.removeAttribute("disabled");
    } else {
      input.setAttribute("disabled", "disabled");
      input.value = "";
    }
  }

  hideWrapper(form, name) {
    this.toggleDisplayFor(form, name, false);
  }

  showWrapper(form, name) {
    this.toggleDisplayFor(form, name, true);
  }

  checkState(form) {
    const countrySelect = this.getCountryInput(form);

    if (this.isLocationCountry(countrySelect)) {
      this.showWrapper(form, "state");
      this.hideWrapper(form, "state_other");
      this.hideWrapper(form, "city");
    } else {
      this.hideWrapper(form, "state");
      this.showWrapper(form, "state_other");
      this.showWrapper(form, "city")
    }
  }
};
