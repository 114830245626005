import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js"

export default class Uploads {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;

    this.handleUploadChange = this.handleUploadChange.bind(this);
    this.handleUploadClear = this.handleUploadClear.bind(this);
  }

  bindListeners() {
    window.addEventListener('change', this.handleUploadChange);
    window.addEventListener('click', this.handleUploadClear);
  }

  handleUploadClear(event) {
    if (this.dh.hasClass(event.target, 'file-clear')) {
      event.preventDefault();
      const el = event.target;
      const parent = this.dh.closest(el, ".file");
      const input = parent.querySelector('input');
      const deleteCheckBox = parent.querySelector('[data-file-delete]');
      const toHide = parent.querySelectorAll('[data-file-delete-hide]');
      const displayEl = parent.querySelector('.file-overlay');
      if (input) {
        input.value = null;
        displayEl.innerHTML = "Choose File...";
        if (deleteCheckBox) deleteCheckBox.checked = true;
        [...toHide].forEach((hideEl) => {
          hideEl.style.display = "none";
        })
      }
    }
  }

  handleUploadChange(event) {
    if (event.target.type == "file" && _.has(event.target.dataset, "upload")) {
      const el = event.target;
      const parent = this.dh.closest(el, ".file");
      const deleteCheckBox = parent.querySelector('[data-file-delete]');
      const displayEl = parent.querySelector('.file-overlay');
      const file = el.files[0];
      if (file) {
        displayEl.innerHTML = el.files[0].name;
        if (deleteCheckBox) deleteCheckBox.checked = false
      }
    }
  }

};
