export default class Tracking {

  bindListeners() {
    document.addEventListener("turbo:load", this.trackPageEvents);
  }

  fb(event) {
    fbq('track', event);
  }

  /**
    When a page loads, Rails may render a function called "trackPageEvents" scoped to the window
    If this function exists, execute it after turbo:load
  */
  trackPageEvents() {
    if(typeof window.trackPageEvents === 'function') {
      window.trackPageEvents();
    }
  }

};
