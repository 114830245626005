import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";

import QRCode from "qrcode";

export default class Invitation {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;

    this.init = this.init.bind(this);
    this.handleInvitationEdit = this.handleInvitationEdit.bind(this);
    this.canHandleInvitationEdit = this.canHandleInvitationEdit.bind(this);
  }

  bindListeners() {
    document.addEventListener('turbo:load', this.init);
    document.addEventListener('input', this.handleInvitationEdit);
  }

  init() {
    if (!document.querySelector('[data-invitation-preview]')) return;
    this.message = document.querySelector('[data-invitation-message]');
    this.messagePreview = document.querySelector('[data-custom-message]');
    this.messageLimit = document.querySelector('[data-invitation-message-limit]');
    this.updateRemaining();
    this.renderQRCode();
  }

  renderQRCode() {
    const url = document.querySelector("[data-copy-invite-url]").innerHTML;
    const canvas = document.querySelector("canvas[data-invite-qr-code]")
    QRCode.toCanvas(canvas, url, function (err) {
      console.log(err);
    })
  }

  handleInvitationEdit(event) {
    if (!this.canHandleInvitationEdit(event)) return;
    this.messagePreview.innerHTML = this.message.value.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    this.updateRemaining();
  }

  updateRemaining() {
    this.messageLimit.innerHTML = this.calculateRemaining();
  }

  calculateRemaining() {
    const limit = 500;
    const currentValue = this.message.value.length;
    let diff = limit - currentValue;
    return diff < 0 ? `${Math.abs(diff)} characters over limit.` : `${diff} characters remaining.`;
  }

  canHandleInvitationEdit(event) {
    return _.has(event.target.dataset, 'invitationMessage');
  }

};
