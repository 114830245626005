import React from "react";
import baseSvgProps from "./baseSvgProps";

const CheckmarkSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={17} height={17} viewBox="0 0 17 15" {...baseSvgProps} {...props}>
    <path d="M1 7.5 6.5 13l9-12" stroke="currentColor" strokeWidth={2} />
  </svg>
);

export default CheckmarkSvg;
