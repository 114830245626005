import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";
import ModalHelpers from "../utility/modal_helpers.js";

export default class Reflections {
  constructor(dh = new DomHelpers(), mh = new ModalHelpers()) {
    this.dh = dh;
    this.mh = mh;
    this.data = {};

    this.handleReflectionsClick = this.handleReflectionsClick.bind(this);
    this.handleReflectionSuccess = this.handleReflectionSuccess.bind(this);
    this.dismissModal = this.dismissModal.bind(this);
    this.setStateComplete = this.setStateComplete.bind(this);
    this.windowKeyDownHandler = null;
  }

  bindListeners() {
    document.addEventListener("click", this.dismissModal);
    document.addEventListener("click", this.handleReflectionsClick);
    document.addEventListener(
      "reflectionSuccess",
      this.handleReflectionSuccess
    );
  }

  handleReflectionsClick(event) {
    const el = this.getReflectionElement(event);
    const activeModal = document.querySelector("[data-modal-reflection]");
    if (!el) return null;
    event.preventDefault();
    this.data = this.setData(el.dataset);
    this.setModalData(this.data);
    this.mh.showModal(activeModal);
    this.windowKeyDownHandler = (event) => {
      const { key, shiftKey } = event;
      if (key !== "Escape" && key !== "Tab") return;
      key === "Escape" && this.dismissModal(null, true);
      key === "Tab" && this.mh.handleTab(event, activeModal);
    };
    this.setFocus(activeModal);
    window.addEventListener("keydown", this.windowKeyDownHandler);
  }

  handleReflectionSuccess() {
    const activeModal = document.querySelector(
      "[data-modal-reflection-complete]"
    );
    this.mh.hideModal(document.querySelector("[data-modal-reflection]"));
    this.mh.showModal(activeModal);
    this.setFocus(activeModal);
    this.setStateComplete(this.data);
  }

  setFocus(modal) {
    const focusable = this.mh.focusable(modal);
    if (focusable[0]) focusable[0].focus();
  }

  setData(data) {
    const out = {
      body: data.reflectionPrompt,
      participantActionId: data.reflectionParticipantActionId,
    };
    return Object.assign({}, out);
  }

  setModalData(data) {
    const modal = document.querySelector("[data-modal-reflection]");
    const body = modal.querySelector("[data-modal-body]");
    const participantAction = modal.querySelector(
      "[data-reflection-participant-action-id]"
    );
    const input = modal.querySelector("[data-reflection-input]");
    body.innerHTML = data.body;
    participantAction.value = data.participantActionId;
    input.value = "";
  }

  dismissModal(event, isKeyEvent) {
    if (
      !isKeyEvent &&
      !this.dh.hasDataAttribute(event.target.dataset, "reflectionDismiss")
    )
      return null;
    this.mh.hideModal(document.querySelector("[data-modal-reflection]"));
    this.mh.hideModal(
      document.querySelector("[data-modal-reflection-complete]")
    );
    this.removeMessages();
    window.removeEventListener("keydown", this.windowKeyDownHandler);
  }

  removeMessages() {
    const messagesEl = document.querySelector(
      "[data-modal-reflection] [data-editor-message]"
    );
    if (!messagesEl) return null;
    return (messagesEl.innerHTML = "");
  }

  setStateComplete(data) {
    const els = document.querySelectorAll(
      `[data-reflection-question="${data.participantActionId}"]`
    );
    els.forEach((el) => {
      const label = el.querySelector("span");
      this.dh.removeClass(el, "unanswered");
      this.dh.addClass(el, "answered");
      el.dataset.reflectionState = "answered";
      label.innerHTML = "Reflection Answered";
    });
  }

  getReflectionElement(event) {
    const el = this.dh.closest(event.target, "[data-reflection-question]");
    if (!el) return null;
    if (el.dataset.reflectionState !== "unanswered") return null;
    return el;
  }
};
