import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";

export default class Dropdowns {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;

    this.handleDropdownClick = this.handleDropdownClick.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.closeDropdowns = this.closeDropdowns.bind(this);
  }

  bindListeners() {
    document.addEventListener('click', this.handleDropdownClick);
    document.addEventListener('turbo:request-start', this.closeDropdowns);
  }

  handleDropdownClick(event) {
    const toggle = this.dh.closest(event.target, '[data-dropdown-toggle]');
    if (toggle) {
      event.preventDefault();
      event.stopPropagation();
      this.toggleDropdown(toggle);
    } else {
      this.closeDropdowns(null, null, event.target, event.target);
    }
  }

  toggleDropdown(toggle) {
    const dropdownId = toggle.dataset.dropdownToggle;
    const dropdowns = document.querySelectorAll(`[data-dropdown=${dropdownId}]`);
    const focusElement = this.dh.closest(toggle, '[data-focus-target]');
    [...dropdowns].forEach((dropdown) => {
      this.dh.toggleClass(dropdown, 'open');
      this.dh.toggleClass(toggle, 'open');
      if (focusElement) {
        this.dh.toggleClass(focusElement, 'focused');
      }
      this.closeDropdowns(dropdown, toggle, focusElement);
    });
  }

  closeDropdowns(currentDropdown = null, currentToggle = null, currentFocus = null, clickTarget = null) {
    if (clickTarget) {
      const parentDropdown = this.dh.closest(clickTarget, '[data-dropdown]');
      if (parentDropdown && !this.dh.hasDataAttribute(parentDropdown.dataset, ["dropdownAutoclose"])) return;
    }
    const dropdowns = document.querySelectorAll('[data-dropdown]');
    [...dropdowns].forEach((dropdown) => {
      if (dropdown !== currentDropdown) {
        this.dh.removeClass(dropdown, 'open');
      }
    });
    const toggles = document.querySelectorAll('[data-dropdown-toggle]');
    [...toggles].forEach((toggle) => {
      if (toggle !== currentToggle) {
        this.dh.removeClass(toggle, 'open');
      }
    });
    const focusedElements = document.querySelectorAll('[data-focus-target]');
    [...focusedElements].forEach((element) => {
      if (element !== currentFocus) {
        this.dh.removeClass(element, 'focused');
      }
    });
  }

};
