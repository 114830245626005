// Smooth Scrolling without jQuery
// Based on: https://coderwall.com/p/hujlhg/smooth-scrolling-without-jquery
import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";

export default class SmoothScroll {
  constructor() {
    this.dh = new DomHelpers;
  }

  smoothScroll(element, offset, callback) {
    var offset = typeof offset !== 'undefined' ? offset : 0;

    var targetRect = element.getBoundingClientRect();
    var target = targetRect.top + this.dh.getScrollTop();

    // Set scroll speed based on where element is
    var duration = target/2;

    if (duration === 0) {
      this.dh.setScrollTop(target);
    }

    var startTime = Date.now();
    var endTime = startTime + duration;

    var startTop = this.dh.getScrollTop();
    var distance = target - startTop - offset;
    // Running value that gets updated to compare frame scrolls
    var previousTop = startTop;

    // Looping frame scroll
    var scrollFrame = function() {
      // based on http://en.wikipedia.org/wiki/Smoothstep
      var smoothStep = function(start, end, point) {
        if (point <= start) { return 0; }
        if (point >= end) { return 1; }
        var x = (point - start) / (end - start);
        return x * x * (3 - 2 * x);
      }

      // If scroll was interrupted, stop that stuff
      if (this.dh.getScrollTop() !== previousTop) {
        return;
      }

      // Set scrolltop for this frame
      var now = Date.now();
      var point = smoothStep(startTime, endTime, now);
      this.dh.setScrollTop(Math.round(startTop + (distance * point)));

      // Check if done
      if (now >= endTime) {
        // Run a callback if there is one
        if (callback) { callback(); }
        return;
      }

      previousTop = this.dh.getScrollTop();

      // Schedule next frame
      setTimeout(scrollFrame, 0);
    };

    // Start animation
    setTimeout(scrollFrame, 0);
  }
}
