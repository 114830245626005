/**
 * Entry point for the build script in your package.json
 *
 * Uses webpack for JS bundling.
 * @see webpack.config.js
 */

// Import basics
import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";

// Utilities
import LodashMixins from "./utility/lodash_mixins.js";
import DomHelpers from "./utility/dom_helpers.js";
import ModalHelpers from "./utility/modal_helpers.js";

// Import analytics classes
import Analytics from "./analytics/analytics.js";
import Tracking from "./analytics/tracking.js";

// Import form helpers
import AddRemoveFields from "./forms/add_remove_fields.js";
import Autocompletes from "./forms/autocompletes.js";
import AutosubmitForm from "./forms/autosubmit_form.js";
import CharacterCount from "./forms/character_count.js";
import Checkboxes from "./forms/checkboxes.js";
import CountrySelects from "./forms/country_selects.js";
import EventAdminForm from "./forms/event_admin.js";
import RelatedFields from "./forms/related_fields.js";
import SelectAlls from "./forms/select_alls.js";
import ShowHideFields from "./forms/show_hide_fields.js";
import Upload from "./forms/upload.js";

// Import UI classes
import Actions from "./ui/actions.js";
import AddActionPromptDismissed from "./ui/add_action_prompt_dismissed.js";
import Checkin from "./ui/checkin.js";
import Confirm from "./ui/confirm.js";
import Corrections from "./ui/corrections.js";
import DashboardCalendars from "./ui/dashboard_calendars.js";
import Donations from "./ui/donations.js";
import DropdownNavigation from "./ui/dropdown_navigation.js";
import Dropdowns from "./ui/dropdowns.js";
import Disclosure from "./ui/disclosure.js";
import EcochallengeBody from "./ui/ecochallenge_body.js";
import EcochallengeSharing from "./ui/ecochallenge_sharing.js";
import EcochallengeSidebar from "./ui/ecochallenge_sidebar.js";
import EcochallengeVideo from "./ui/ecochallenge_video.js";
import Filters from "./ui/filters.js";
import FormSelectGrouped from "./ui/form_select_grouped.js";
import HtmlPreview from "./ui/html_preview.js";
import ImpactCharts from "./ui/impact_charts.js";
import Invitations from "./ui/invitations.js";
import JoinTeamButtons from "./ui/join_team_buttons.js";
import Links from "./ui/links.js";
import Modal from "./ui/modal.js";
import Posts from "./ui/posts.js";
import Referral from "./ui/referral.js";
import Reflections from "./ui/reflections.js";
import Sliders from "./ui/sliders.js";
import TeamLists from "./ui/team_lists.js";
import Togglers from "./ui/togglers.js";
import Tooltips from "./ui/tooltips.js";
import Tutorial from "./ui/tutorial.js";

// React
import React from "react";
window.React = React;
import ReactRenderer from "./components/renderer.js";

// Import ActionCable
import ParticipantCable from "./cable/subscription/participant_updates.js";

// External libraries
import Trix from "trix";
import Cocoon from "../../vendor/assets/javascripts/cocoon-vanilla.js";
import Chartkick from "chartkick";
import { Chart } from "chart.js"
import "chartkick/chart.js" // Instructs Chartkick to use chart.js

// Stimulus
import "./controllers";

//
// Boot everything up!
//
window.EcoChallenge = {};
window.Rails = Rails;
window.Chartkick = Chartkick
window.Chart = Chart

// Basics
Rails.start();

// Shared utilities to pass into classes that use them
let dh = (EcoChallenge.DomHelpers = new DomHelpers());
let mh = (EcoChallenge.ModalHelpers = new ModalHelpers());

// Analytics
EcoChallenge.Analytics = new Analytics();
EcoChallenge.Tracking = new Tracking();

// Forms
EcoChallenge.AddRemoveFields = new AddRemoveFields(dh);
EcoChallenge.Autocomplete = new Autocompletes(dh);
EcoChallenge.CharacterCount = new CharacterCount(dh);
EcoChallenge.Checkboxes = new Checkboxes(dh);
EcoChallenge.CountrySelects = new CountrySelects(dh);
EcoChallenge.EventAdminForm = new EventAdminForm();
EcoChallenge.RelatedFields = new RelatedFields(dh);
EcoChallenge.SelectAlls = new SelectAlls(dh);
EcoChallenge.ShowHideFields = new ShowHideFields(dh);
EcoChallenge.Upload = new Upload(dh);

// UI
EcoChallenge.Actions = new Actions(dh);
EcoChallenge.AddActionPromptDismissed = new AddActionPromptDismissed(dh);
EcoChallenge.AutosubmitForm = new AutosubmitForm(dh);
EcoChallenge.Checkin = new Checkin(dh);
EcoChallenge.Confirm = new Confirm(dh);
EcoChallenge.Corrections = new Corrections(dh);
EcoChallenge.DashboardCalendars = new DashboardCalendars(dh);
EcoChallenge.Donations = new Donations(dh, mh);
EcoChallenge.DropdownNavigation = new DropdownNavigation(dh);
EcoChallenge.Dropdowns = new Dropdowns(dh);
EcoChallenge.Disclosure = new Disclosure(dh, mh);
EcoChallenge.EcochallengeBody = new EcochallengeBody();
EcoChallenge.EcochallengeSharing = new EcochallengeSharing();
EcoChallenge.EcochallengeSidebar = new EcochallengeSidebar(dh);
EcoChallenge.EcochallengeVideo = new EcochallengeVideo(dh, mh);
EcoChallenge.Filters = new Filters(dh);
EcoChallenge.FormSelectGrouped = new FormSelectGrouped();
EcoChallenge.HtmlPreview = new HtmlPreview();
EcoChallenge.ImpactCharts = new ImpactCharts(dh);
EcoChallenge.Invitations = new Invitations(dh);
EcoChallenge.JoinTeamButtons = new JoinTeamButtons(dh);
EcoChallenge.Links = new Links(dh);
EcoChallenge.Modal = new Modal(dh, mh);
EcoChallenge.Posts = new Posts(dh);
EcoChallenge.Referral = new Referral(dh);
EcoChallenge.Reflections = new Reflections(dh, mh);
EcoChallenge.Sliders = new Sliders(dh);
EcoChallenge.TeamLists = new TeamLists(dh, mh);
EcoChallenge.Togglers = new Togglers(dh);
EcoChallenge.Tooltips = new Tooltips(dh);
EcoChallenge.Tutorial = new Tutorial(dh);

EcoChallenge.ReactRenderer = new ReactRenderer();

// Bind event listeners

Object.values(EcoChallenge).forEach((obj) => {
  if (typeof obj.bindListeners === "function") obj.bindListeners();
});

// Start ActionCable
ParticipantCable();

Turbo.setFormMode("optin")