import { useState, useCallback } from "react";
import { DateRange, Checkbox, ScopeDropdown } from "../inputs";
import { Search } from "../Search";
import {
  getDefaultValues,
  setAllSearchParams,
  clearSearchParams,
} from "../links";

import type { Label as ScopeLabel } from "../inputs/ScopeDropdown/index";
import type { PostLocalizedContent } from "../../../types/posts/post_types";

export type Filters = {
  filter_type: ScopeLabel;
  filter_id?: string | null;
  images_only: boolean;
  hide_reflection_posts: boolean;
  start_date: Date | null;
  end_date: Date | null;
  page?: string;
};

type Props = {
  path: string;
  scoped_to?: ScopeLabel;
  scoped_to_id?: string;
  show_org?: boolean;
  user_org_id?: string;
  show_team?: boolean;
  locale?: string;
  localized_content: PostLocalizedContent;
};

export type Param = keyof Filters;

export default function FiltersContainer({
  path,
  scoped_to,
  scoped_to_id,
  show_org,
  user_org_id,
  show_team,
  locale,
  localized_content
}: Props) {
  const {
    filter_type,
    start_date,
    end_date,
    images_only,
    hide_reflection_posts,
  } = getDefaultValues();

  const initialFilters = {
    filter_type: scoped_to || filter_type,
    images_only,
    hide_reflection_posts,
    start_date,
    end_date,
    filter_id: scoped_to_id,
  };

  const [filters, setFilters] = useState<Omit<Filters, "page">>(initialFilters);

  const updateFilters = useCallback(
    (param: string) => {
      return (value: Date | (Date | null)[] | string | boolean | null) => {
        if (param === "dates") {
          const newStart = Array.isArray(value)
            ? value[0]
            : typeof value !== "boolean" && typeof value !== "string"
            ? value
            : null;
          const newEnd = Array.isArray(value) ? value[1] : null;
          return setFilters({
            ...filters,
            start_date: newStart,
            end_date: newEnd,
          });
        }
        const filterIdValue =
          scoped_to_id || (param === "filter_type" && value === "org")
            ? user_org_id
            : null;
        const filterIdParam = filterIdValue ? { filter_id: filterIdValue } : {};
        return setFilters({ ...filters, [param]: value, ...filterIdParam });
      };
    },
    [filters]
  );

  const onUpdate = useCallback(() => {
    setAllSearchParams({ path, filters });
  }, [filters, path]);

  const onClear = useCallback(() => {
    clearSearchParams(path);
  }, [path]);

  return (
    <div className="filters-container">
      {false && <Search />}
      <div className="feed-filters">
        {!scoped_to && (
          <span className="feed-filters__label">{localized_content.show_posts_from}</span>
        )}
        <div className="feed-filters__inputs form-field">
          {!scoped_to && (
            <ScopeDropdown
              label={filters.filter_type}
              scopedToTeam={!!scoped_to_id}
              showOrg={show_org}
              showTeam={show_team}
              handleChange={updateFilters("filter_type")}
              localizedContent={localized_content.scope}
            />
          )}
          <DateRange
            start_date={filters.start_date}
            end_date={filters.end_date}
            locale={locale}
            handleChange={updateFilters("dates")}
          />
          <Checkbox
            checked={filters.images_only}
            label={localized_content.images_only}
            handleChange={updateFilters("images_only")}
          />
          <Checkbox
            checked={filters.hide_reflection_posts}
            label={localized_content.hide_reflection_posts}
            handleChange={updateFilters("hide_reflection_posts")}
          />
          <div className="button-group">
            <button className="button-primary dull small" onClick={onClear}>
              {localized_content.clear}
            </button>
            <button className="button-primary dark small" onClick={onUpdate}>
              {localized_content.submit}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
