export default class Analytics {

  constructor() {
    this.recordPageViews = this.recordPageViews.bind(this);
    this.recordSubdomainPageView = this.recordSubdomainPageView.bind(this);
    this.recordEventPageView = this.recordEventPageView.bind(this);
    this.triggerEvent = this.triggerEvent.bind(this);
  }

  bindListeners() {
    document.addEventListener("turbo:load", this.recordPageViews);
  }

  recordPageViews() {
    // Generic Ecochallenge page view trigger
    this.triggerEvent('ecochallengePageView', { 'virtualUrl': location.pathname + location.search });

    this.recordSubdomainPageView();
    this.recordEventPageView();
  }

  recordSubdomainPageView() {
    const body = document.querySelector('body');
    const subdomain = body.dataset.gtmSubdomain;
    if (!subdomain) return null;

    const eventName = `${subdomain}EventPageView`;

    this.triggerEvent(eventName);
  }

  recordEventPageView() {
    const body = document.querySelector('body');
    const subdomain = body.dataset.gtmSubdomain;
    const pageName = body.dataset.gtmPageName;
    if (!subdomain || !pageName) return null;

    const eventName = `${subdomain}Event${pageName}PageView`;


    this.triggerEvent(eventName);
  }

  triggerEvent(name, options = {}) {
    if (!window.dataLayer) return null;

    const event = Object.assign({}, { "event": name }, options);

    dataLayer.push(event);
  }

};
