import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";

export default class Corrections {
  constructor(dh = new DomHelpers()) {
    this.dh = dh;

    _.defProps(this, { dh });

    _.bindAll(this, "handleLoad", "handleStrategyChange");
  }

  bindListeners() {
    this.dh.on(`form.new_correction input[type="radio"][name$="[strategy]"]`, "change", this.handleStrategyChange);
    document.addEventListener("turbo:load", this.handleLoad);
  }

  /**
   * @return {void}
   */
  handleLoad() {
    const actionCards = document.querySelectorAll(`form.new_correction .action-card`);

    _.each(actionCards, (card) => {
      this.toggleValueInputsFor(card);
    });
  }

  /**
   * @param {Event} event
   * @return {void}
   */
  handleStrategyChange(event) {
    const { target } = event;

    if (!target) {
      return null;
    }

    const card = this.dh.closest(target, ".action-card");

    if (!card) {
      throw new Error("Could not find card for target");
    }

    this.toggleValueInputsFor(card);
  }

  /**
   * @param {HTMLDivElement} card
   * @return {HTMLInputElement?}
   */
  selectedStrategyInputFor(card) {
    return card.querySelector(`input[type="radio"][name$="[strategy]"]:checked`);
  }

  /**
   * @return {void}
   */
  toggleValueInputsFor(card) {
    const strategyInput = this.selectedStrategyInputFor(card);

    const valueInputs = card.querySelectorAll(`input[type="number"][name$="[value]"]`);

    _.each(valueInputs, (valueInput) => {
      const valueField = this.dh.closest(valueInput, ".form-field");

      const hidesValueInput = this.dh.targetMatches(strategyInput, "[data-hides-value-input]")

      if (hidesValueInput) {
        valueInput.removeAttribute("required");
        valueField.style.display = "none";
        valueField.style.visibility = "hidden";
      } else {
        valueInput.setAttribute("required", true);
        valueField.style.removeProperty("display");
        valueField.style.removeProperty("visibility");
      }
    });
  }
}
