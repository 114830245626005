import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";

export default class FormSelectGrouped {

  constructor() {
    this.init = this.init.bind(this);
  }

  bindListeners() {
    document.addEventListener('turbo:load', this.init);
  }

  init() {
    const groups = document.querySelectorAll('[data-form-select-grouped]');
    if (groups.length > 0) {
      [...groups].forEach((group) => {
        this.wrapSelects(group);
      });
    }
  }

  wrapSelects(group) {
    const selects = group.getElementsByTagName('select');
    [...selects].forEach((select) => {
      const wrapper = document.createElement('div');
      wrapper.className = 'select';
      wrapper.appendChild(select);
      group.appendChild(wrapper);
    });
  }

}
