import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";

export default class Sliders {

  constructor(dh = new DomHelpers(), selector="data-slider") {
    this.dh = dh;
    this.selector = selector
    this.init = this.init.bind(this);
    this.advance = this.advance.bind(this);
  }

  bindListeners() {
    document.addEventListener('turbo:load', this.init);
  }

  init() {
    const sliderSections = document.querySelectorAll(`[`+this.selector+`]`);

    [...sliderSections].forEach((sliderSection) => {
      const slider = sliderSection.querySelector('.slider');
      const sliderContainer = sliderSection.querySelector('.slider-container')

      this.layoutSlider(slider, sliderContainer);

      window.addEventListener('resize', this.dh.debounce(() => { this.layoutSlider(slider, sliderContainer)}, 200));
      this.getSlidesAdvancer(sliderSection, 'left').addEventListener('click', (event) => { this.advance(event, slider); });
      this.getSlidesAdvancer(sliderSection, 'right').addEventListener('click', (event) => { this.advance(event, slider); });
    });
  }

  // Assumes the slider element never starts with any bottom padding
  layoutSlider(slider, sliderContainer) {
    slider.style.paddingBottom = 0;
    sliderContainer.style.height = slider.offsetHeight+'px';
    slider.style.paddingBottom = '17px';
  }

  getSliderSections(selector) {
    return document.querySelectorAll(`[`+this.selector+`]`);
  }

  getSlides(slider) {
    return slider.children;
  }

  getSlidesAdvancer(sliderSection, direction) {
    return sliderSection.querySelectorAll(`[`+this.selector+`-advance=`+direction+`]`)[0];
  }

  getSlider(sliderSection) {
    return sliderSection.querySelector('.slider');
  }

  getSlideWidth(slider) {
    return this.getSlides(slider)[0].offsetWidth;
  }

  advance(event, slider) {
    event.preventDefault();
    const slides = this.getSlides(slider);

    switch(event.target.dataset['sliderAdvance']) {
      case 'left':
        this.scroller(slider, -1);
        break;
      case 'right':
        this.scroller(slider, 1);
        break;
    }
  }

  //Assumes all slides are the same width!!
  scroller(slider, advanceDirection) {
    const scrollPos = slider.scrollLeft + (advanceDirection * this.getSlideWidth(slider))
    const scrollEnd = slider.scrollWidth - slider.offsetWidth;

    if (scrollPos >= scrollEnd) {
      if (slider.dataset.end === 'true') {
        this.tweener(slider, 0, 500);
      } else {
        this.tweener(slider, scrollEnd, 500);
        slider.dataset.end = 'true';
      }
    } else if (scrollPos <= 0 ) {
      if (slider.dataset.end === 'true') {
        this.tweener(slider, scrollEnd, 500);
      } else {
        this.tweener(slider, 0, 500);
        slider.dataset.end = 'true';
      }
    } else {
      this.tweener(slider, scrollPos, 500);
      slider.dataset.end = 'false';
    }
  }

  // For smoother scrolling when the buttons initiate the scrolling
  tweener(slider, target, duration) {
    target = Math.round(target);
    duration = Math.round(duration);

    if (duration < 0) {
      return Promise.reject("bad duration");
    }

    if (duration === 0) {
      slider.scrollLeft = target;
      return Promise.resolve();
    }

    const startTime = Date.now();
    const endTime = startTime + duration;

    const startScrollPos = slider.scrollLeft;
    const distance = target - startScrollPos;

    // based on http://en.wikipedia.org/wiki/Smoothstep
    let smoothStep = function(start, end, point) {
      if(point <= start) { return 0; }
      if(point >= end) { return 1; }
      const delta = (point - start) / (end - start);
      return delta*delta*(3 - 2*delta);
    }

    return new Promise(function(resolve, reject) {
      let previousScrollPos = slider.scrollLeft;

      let scroll_frame = function() {
        if(slider.scrollLeft != previousScrollPos) {
          reject("interrupted");
          return;
        }

        const now = Date.now();
        const point = smoothStep(startTime, endTime, now);
        const frameScrollPos = Math.round(startScrollPos + (distance * point));
        slider.scrollLeft = frameScrollPos;

        if(now >= endTime) {
          resolve();
          return;
        }

        if(slider.scrollLeft === previousScrollPos
          && slider.scrollLeft !== frameScrollPos) {
          resolve();
          return;
        }
        previousScrollPos = slider.scrollLeft;

        setTimeout(scroll_frame, 0);
      }

      // boostrap the animation process
      setTimeout(scroll_frame, 0);
    }).catch((reason) => { console.log(reason); });
  }
}
