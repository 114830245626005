import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";
import ModalHelpers from "../utility/modal_helpers.js";

export default class Donations {

  constructor(dh = new DomHelpers(), mh = new ModalHelpers()) {
    this.dh = dh;
    this.mh = mh;
    this.windowKeyDownHandler = null;

    _.bindAll(this, "init", "handleAmountChange", "handleFormSubmit", "hideDonationModal", "setExternalRefs");

    this.modal = document.querySelector('[data-modal-donation]');
    this.form = this.modal ? this.modal.querySelector("#donation-form") : null;
  }

  bindListeners() {
    document.addEventListener("turbo:load", this.setExternalRefs);

    document.addEventListener("turbo:load", this.init);
    this.dh.on("#donation-amount", "change", this.handleAmountChange);
    this.dh.on("#donation-form", "submit", this.handleFormSubmit);
    this.dh.on("button[data-donation-dismiss]", "click", this.hideDonationModal);
    this.dh.on("button[data-donation-amount]", "click", (event) => {
      const donationAmount = _.get(event, "target.dataset.donationAmount");

      if (donationAmount) {
        this.setDonationAmount(donationAmount);
      }
    });
  }

  init() {
    this.consumeDonationParam();
  }

  consumeDonationParam() {
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.get('donate')) {
      if(this.modal) {
        urlParams.delete('donate');

        const defaultDonation = urlParams.get("default_donation")
        if(defaultDonation) {
          this.setDonationAmount(defaultDonation)
          urlParams.delete("default_donation");
        }

        const paramString = urlParams.toString();
        const url = `${window.location.origin}${window.location.pathname}${paramString ? `?${urlParams.toString()}` : ""}`;
        window.history.replaceState(null, null, url);
        this.showDonateModal();
      } else {
        const donateLink = document.querySelector(".desktop-donation a");
        const donateUrl = donateLink.href;
        window.location.href = donateUrl;
      }
    }
  }

  createStripeElements() {
    this.stripe = Stripe(this.dh.getMetaContentString("stripe-key"));
    this.elements = this.stripe.elements(
      {
        mode: "payment",
        currency: "usd",
        amount: 3000,
        appearance: {
          theme: "stripe",
          labels: "floating"
        }
      }
    );
    this.paymentElement = this.elements.create("payment", { fields: { billingDetails: { address: { postalCode: "never" } } } })
    this.paymentElement.addEventListener("change", this.handleCardError);
    this.paymentElement.mount(document.getElementById("stripe-element"));
  }

  setButtonListeners() {
    const donateButtons = document.querySelectorAll('[data-donate-button]');
    if(donateButtons) {
      donateButtons.forEach((el) => {
        el.addEventListener("click", (event) => {
          event.preventDefault();
          const trigger = this.dh.closest(event.target, "[data-donate-button]")
          this.showDonateModal(trigger);
        });
      });
    }
  }

  setExternalRefs() {
    this.modal = document.querySelector('[data-modal-donation]');
    this.setButtonListeners();
  }

  loadStripeApi() {
    const jsEl = document.createElement("script");
    jsEl.src = "https://js.stripe.com/v3/"
    document.head.appendChild(jsEl);
    jsEl.onload = () => { this.createStripeElements() };
  }

  showDonateModal(trigger) {
    if (!this.stripeLoaded) {
      this.loadStripeApi();
      this.stripeLoaded = true;
    } else {
      this.createStripeElements();
    }

    if(trigger && trigger.dataset["donateDefault"]) {
      this.setDonationAmount(trigger.dataset["donateDefault"]);
    }

    this.mh.showModal(this.modal);

    this.windowKeyDownHandler = (event) => {
      const { key, shiftKey } = event;
      if (key !== "Escape" && key !== "Tab") return;
      key === "Escape" && this.hideDonationModal();
      key === "Tab" && this.mh.handleTab(event, this.modal);
    };

    const focusable = this.mh.focusable(this.modal);
    if (focusable[0]) focusable[0].focus();

    window.addEventListener("keydown", this.windowKeyDownHandler);

    if(window.doublethedonation && window.doublethedonation.plugin) {
      window.doublethedonation.plugin.load_plugin();
    }
  }

  hideDonationModal() {
    this.mh.hideModal(this.modal);
    window.removeEventListener("keydown", this.windowKeyDownHandler);
  }

  setDonationAmount(amount) {
    const target = document.getElementById("donation-amount");
    target.value = parseFloat(amount).toFixed(2);
    target.dispatchEvent(new Event("change"))
  }

  async handleFormSubmit(event) {
    event.preventDefault();

    if(!this.stripe) return;

    try {
      

      const result = await this.elements.submit(this.paymentElement);

      if (result.error) {
        this.handleCardError(result);
      } else {
        const data = new FormData(event.target);

        this.hideDonationModal();

        this.dh.showModal({
          title: "Please wait...",
          body: "Processing your donation payment.",
          hideYay: true,
          hideNay: true,
          hideClose: true
        });

        this.submit(event.target, data);
      }
    } catch (error) {
      this.handleCardError({ error });
    }
  }

  async submit(form, data) {
    try {
      const response = await fetch(form.action, {
        method: form.method,
        headers: {
          "accept": "application/json",
          "X-CSRF-Token": data.get("authenticity_token")
        },
        body: data
      });

      const body = await response.json();
      if (body.errors) {
        this.handleErrors(body.errors);
      } else {
        const {error} = await this.stripe.confirmPayment({
          elements: this.elements,
          clientSecret: body.paymentIntent,
          confirmParams: {
            return_url: window.location.href,
            payment_method_data: {
              billing_details: {
                address: {
                  postal_code: data.get("postal_code")
                }
              }
            }
          },
          redirect: "if_required",
        });

        if(error) return this.handleErrors(error);

        this.dh.showModal({
          title: "Thank you!",
          body: this.successMessage(),
          yayLabel: "Close"
        });
        // Submit Facebook Pixel tracker for donation
        EcoChallenge.Tracking.fb("Donation");
      }
    } catch (error) {
      this.handleCardError({ error });
    }
  }

  titleMessage(pointsAwarded) {
    if(pointsAwarded > 0) {
      return "Congratulations!"
    } else {
      return "Thank you!"
    }
  }

  successMessage() {
    return `
    <div>
      Your points will be awarded after the payment has processed.
    </div>
    <div>
      ${this.thankYouMessage}
    </div>
    `
  }

  pointsMessage(pointsAwarded) {
    if(pointsAwarded > 0) {
      return `You have earned ${pointsAwarded} points!`;
    } else if(pointsAwarded == 0) {
      return "You have reached the maximum number of points awardable for donations in this event.";
    } else {
      return `
        There may have been a problem assigning you your points for this donation. If you don't see
        points that you expected to be awarded on your dashboard in the next few minutes, please
        contact support using the link at the bottom of the page.
      `
    }
  }

  get thankYouMessage() {
    const meta = document.querySelector("meta[name='dtd-url']")
    const href = meta ? meta.content : null

    return `
      <br />Thank you for supporting Ecochallenge.org. A donation receipt will be emailed to you shortly.
      <br /><br /><a class="outlined space-above" style="color: #949494;" target="_blank" href="${href || "/?donate=true"}">
        Please take a moment to check to see if your employer will match your donation!
      </a>
    `;
  }

  handleErrors(errors) {
    this.form.querySelectorAll("input").forEach((input) => {
      const parent = input.parentNode;
      const errorEl = parent.querySelector(".error-message")
      if(errors[input.name]) {
        parent.classList.add("error");
        if(errorEl) errorEl.textContent = `${input.name.replace("donation_", "")} ${errors[input.name]}.`;
      } else {
        parent.classList.remove("error");
        if(errorEl) errorEl.textContent = ''
      }
    });
    this.showDonateModal();
  }

  handleAmountChange(event) {
    if("currency" in event.target.dataset) {
      if(isNaN(parseFloat(event.target.value))) {
        event.target.value = "0.00";
        event.target.parentElement.classList.add("error");
      } else {
        const amount = parseFloat(event.target.value).toFixed(2)
        event.target.value = amount;
        event.target.parentElement.classList.remove("error");

        if(this.elements) {
          this.elements.update({amount: amount * 100});
        }
      }
    }
  }

  handleCardError(event) {
    const errorEl = document.getElementById("stripe-card-errors");
    if(event.error) {
      errorEl.textContent = event.error.message;
    } else {
      errorEl.textContent = "";
    }
  }

}
