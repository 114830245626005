export function getMonthName(dateStr: string) {
  const date = new Date(dateStr);
  if (date) {
    return date.toLocaleString("default", { month: "short", timeZone: "UTC" });
  }
  return null;
}

export function getYear(dateStr: string) {
  const date = new Date(dateStr);
  if (date) {
    return date.getFullYear();
  }
  return null;
}

export function getTooltipDate(dateStr: string) {
  const date = new Date(dateStr);
  if (date) {
    return date
      .toLocaleString("default", {
        month: "short",
        day: "numeric",
        year: "numeric",
        timeZone: "UTC",
      })
      .toLocaleLowerCase();
  }
  return null;
}

export function getDisplayDateRange(dateStart: string, dateEnd: string) {
  const monthStart = getMonthName(dateStart);
  const monthEnd = getMonthName(dateEnd);
  const yearStart = getYear(dateStart);
  const yearEnd = getYear(dateEnd);

  return yearStart !== yearEnd
    ? `${monthStart} ${yearStart} - ${monthEnd}, ${yearEnd}`
    : monthStart !== monthEnd
    ? `${monthStart} - ${monthEnd}, ${yearEnd}`
    : `${monthStart}, ${yearEnd}`;
}
