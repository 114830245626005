import type { Filters, Param } from "./Filters/Filters";
import type { Label as ScopeLabel } from "./inputs/ScopeDropdown/index";

export const getHrfFromParams = ({
  path,
  param,
  value,
}: {
  path: string;
  param: string;
  value: string;
}) => {
  const params = new URLSearchParams(window.location.search);
  params.set(param, value);
  return `${path}?${params.toString()}`;
};

export const setSingleSearchParam = ({
  path,
  param,
  value,
}: {
  path: string;
  param: string;
  value: string;
}) => {
  const params = new URLSearchParams(window.location.search);
  params.set(param, value);

  const link = document.createElement("a");
  link.href = `${path}?${params.toString()}`;
  link.click();
  link.remove();
};

export const setAllSearchParams = ({
  path,
  filters,
}: {
  path: string;
  filters: Filters;
}) => {
  const params = new URLSearchParams();
  Object.keys(filters).forEach((param) => {
    const filterValue = filters[param as Param];

    let paramValue;
    if (typeof filterValue === "boolean") {
      if (filterValue) {
        paramValue = "1";
      } else {
        paramValue = "0";
      }
    } else if (filterValue && typeof filterValue === "object") {
      paramValue = filterValue.toString();
    } else {
      paramValue = filterValue;
    }

    if (paramValue) params.set(param, paramValue);
  });

  const link = document.createElement("a");
  link.href = `${path}?${params.toString()}`;
  link.click();
  link.remove();
};

export const clearSearchParams = (path: string) => {
  const link = document.createElement("a");
  link.href = path;
  link.click();
  link.remove();
};

export const getDefaultValues = (): Filters => {
  const params = new URLSearchParams(window.location.search);
  const filter_type = (params.get("filter_type") ?? "event") as ScopeLabel;
  const filter_id = params.get("filter_id");
  const maybeStartDate = params.get("start_date") as string;
  const start_date = maybeStartDate ? new Date(maybeStartDate) : null;
  const maybeEndDate = params.get("end_date") as string;
  const end_date = maybeEndDate ? new Date(maybeEndDate) : null;
  const images_only = !!parseInt(params.get("images_only") ?? "0");
  const hide_reflection_posts = !!parseInt(
    params.get("hide_reflection_posts") ?? "0"
  );
  const page = params.get("page") ?? "1";
  return {
    filter_type,
    filter_id,
    start_date,
    end_date,
    images_only,
    hide_reflection_posts,
    page,
  };
};
