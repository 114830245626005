import { forwardRef, Ref, useId } from "react";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  description?: string;
  handleChange: (val: boolean | string) => void;
}

function Checkbox(
  { label, disabled, description, handleChange, ...inputProps }: Props,
  ref: Ref<HTMLInputElement>
) {
  const uid = useId();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e.target.checked);
  };

  return (
    <label htmlFor={uid} className="checkbox large">
      <input
        id={uid}
        type="checkbox"
        ref={ref}
        className="screen-reader-text"
        disabled={disabled}
        onChange={onChange}
        {...inputProps}
      />
      <span className="toggle-indicator" />
      {label && (
        <div>
          <span className="">{label}</span>
        </div>
      )}
    </label>
  );
}

export default forwardRef(Checkbox);
