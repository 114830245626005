import DomHelpers from "../utility/dom_helpers.js"

export default class EventAdminForm {

  PUBLIC_EVENT_CONFIRM = "Do you want to set public event defaults? This will override various current settings for such as visibility, access, and social sharing."
  PUBLIC_EVENT_FIELDS = {
    "event[unlisted]": false,
    "event[settings][show_password_prompt_on_home]": false,
    "event[settings][disallow_non_participants]": false,
    "event[event_configuration_attributes][show_sms_opt_in]": true,
    "event[settings][show_department]": false,
    "event[settings][enable_social_sharing]": true,
  }

  constructor(dh = new DomHelpers()) {

  }

  bindListeners() {
    document.addEventListener("change", this.handlePublicEventChange);
  }

  handlePublicEventChange = event => {
    const target = event.target;
    if(target.name != "event[is_public]") return;
    if(!target.checked) return;

    if(confirm(this.PUBLIC_EVENT_CONFIRM)) {
      Object.keys(this.PUBLIC_EVENT_FIELDS).forEach((field_name) => {
        const fields = document.querySelectorAll(`[name='${field_name}']`).forEach((field) => {
          if(field.type != "checkbox") return;

          field.checked = this.PUBLIC_EVENT_FIELDS[field_name];
          window.EcoChallenge.Checkboxes.checkState(field);
        });
      });
    }
  }

}
