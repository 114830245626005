import { SVGProps } from "react";

const defaultProps = {
  xmlns: "http://www.w3.org/2000/svg",
  role: "presentation",
};

export default function CalendarIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 26 26"
      width={26}
      height={26}
      fill="none"
      {...defaultProps}
      {...props}
    >
      <rect
        x="3.25"
        y="6.5"
        width="19.5"
        height="16.25"
        rx="2"
        stroke="currentColor"
      />
      <path
        d="M3.25 10.5C3.25 8.61438 3.25 7.67157 3.83579 7.08579C4.42157 6.5 5.36438 6.5 7.25 6.5H18.75C20.6356 6.5 21.5784 6.5 22.1642 7.08579C22.75 7.67157 22.75 8.61438 22.75 10.5V10.8333H3.25V10.5Z"
        fill="currentColor"
      />
      <path
        d="M7.58325 3.25L7.58325 6.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M18.4167 3.25L18.4167 6.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <rect
        x="7.58325"
        y="13"
        width="4.33333"
        height="2.16667"
        rx="0.5"
        fill="currentColor"
      />
      <rect
        x="7.58325"
        y="17.3333"
        width="4.33333"
        height="2.16667"
        rx="0.5"
        fill="currentColor"
      />
      <rect
        x="14.0833"
        y="13"
        width="4.33333"
        height="2.16667"
        rx="0.5"
        fill="currentColor"
      />
      <rect
        x="14.0833"
        y="17.3333"
        width="4.33333"
        height="2.16667"
        rx="0.5"
        fill="currentColor"
      />
    </svg>
  );
}
