import DomHelpers from "../utility/dom_helpers.js";
import _ from "lodash";

export default class Checkin {
  // state-complete
  // card-container
  // transition-interstitial
  // transition-post-input-form
  // transition-complete
  // transition-undo

  constructor(dh = new DomHelpers()) {
    this.dh = dh;

    const transitionTimeouts = new WeakMap();

    const transitionNames = [
      "complete",
      "interstitial",
      "post-input-form",
      "undo"
    ];

    Object.defineProperties(this, {
      dh: {
        value: dh
      },

      transitionNames: {
        value: transitionNames,
      },

      transitionTimeouts: {
        value: transitionTimeouts
      }
    });

    _.bindAll(this, "handleCheckinClick", "handleCheckinNoneClick", "handleErrorCorrection", "handleSubmitClick", "cleanUpTransitions", "toTransitionClass");
  }

  bindListeners() {
    this.dh.on(".new_post_input_values", "input", this.handleErrorCorrection);

    document.addEventListener("submit", this.handleSubmitClick);
    document.addEventListener("click", this.handleCheckinClick);
    document.addEventListener("click", this.handleCheckinNoneClick);
    document.addEventListener("turbo:before-cache", this.cleanUpTransitions)
  }

  cleanUpTransition(name, callback) {
    const className = `transition-${name}`;
    const selector = `.${className}`;

    [...document.querySelectorAll(selector)].forEach((card) => {
      this.clearTransitionTimeoutFor(card);

      this.removeClasses(card, className);

      if (typeof callback === "function") {
        callback(card);
      }
    });
  }

  cleanUpTransitions() {
    this.cleanUpTransition("interstitial");

    this.cleanUpTransition("complete", (card) => {
      this.addClasses(card, "status-complete");
    });

    this.cleanUpTransition("undo", (card) => {
      this.removeClasses(card, "status-complete");
    });
  }

  /**
   * @return {void}
   */
  async handleCheckinClick(event) {
    const trigger = event.target;

    if (!this.isCheckinMethodTrigger(trigger)) {
      return;
    }

    const card = this.getCard(trigger);

    const { dataset: { checkinStrategy } } = card;
    const { dataset: { checkinMethod } } = trigger;

    if (checkinStrategy === "post_input") {
      if (checkinMethod === "post") {
        await this.transitionToPostInputForm(card);
      } else if (checkinMethod === "delete") {
        await this.undo(card);
        await this.transitionToUndone(card);
      }
    } else {
      if (checkinMethod === "post") {
        await this.checkin(card);
        await this.transitionToInterstitial(card);
      } else if (checkinMethod === "delete") {
        await this.undo(card);
        await this.transitionToUndone(card);
      }
    }
  }

  /**
   * @return {void}
   */
  async handleCheckinNoneClick(event) {
    const trigger = event.target;
    if(!trigger.matches("#checkin_none")) return;

    const parent = this.dh.closest(trigger, "[data-checkin-none]");

    if(!parent) {
      return;
    }

    if(parent.dataset.checkinNone === "triggered") {
      if(event.target.tagName !== "A") {
        event.preventDefault();
        return false;
      }
    } else {
      await this.checkinNone(parent);
      parent.dataset.checkinNone = "triggered";
      return true;
    }
  }

  /**
   * @return {void}
   */
  async handleSubmitClick(event) {
    const trigger = event.target;

    const form = this.dh.closest(trigger, "form");

    if (!this.dh.hasClass(form, "new_post_input_values")) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (!this.dh.formIsValid(form)) {
      const submit = form.querySelector(`input[type="submit"]`);

      if (submit) {
        submit.disabled = true;
      }

      return;
    }

    await this.submitPostInputValuesFrom(form);
  }

  handleErrorCorrection(event) {
    const trigger = event.target;

    const form = this.dh.closest(trigger, "form");

    if (!form) {
      return null;
    }

    const formIsValid = this.dh.formIsValid(form);

    const submit = form.querySelector(`input[type="submit"]`);

    if (formIsValid) {
      submit.removeAttribute("disabled");
    } else {
      submit.setAttribute("disabled", "disabled");
    }
  }

  async checkin(card) {
    const date = card.dataset.checkinDate;

    const payload = { completed_action: { participant_action_id: card.dataset.checkinParticipantAction } };

    return await this.doFetch(card, `/me/completed_actions/${date}`, "POST", payload);
  }

  async checkinNone(element) {
    const date = element.dataset.checkinDate;
    const payload = {};

    return await this.doFetch(element,  `/me/checkin/${date}`, "POST", payload);
  }

  async undo(card) {
    const completeActionId = card.dataset.checkinCompletedAction;

    return await this.doFetch(card, `/me/completed_actions/${completeActionId}`, "DELETE");
  }

  async getFetchOptions(method) {
    const options = {
      method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken()
      }
    };

    return options;
  }

  /**
   * @return {void}
   */
  async submitPostInputValuesFrom(form) {
    const card = this.getCard(form);
    const options = await this.getFetchOptions("POST");

    options.headers["Content-Type"] = "application/x-www-form-urlencoded";

    options.body = Rails.serializeElement(form);

    try {
      const response = await fetch(form.action, options);

      if (response.status >= 400 && response.status != 422) {
        const error = new Error(response.statusText || response.status);

        error.response = response;

        throw error;
      }

      const data = await response.json();

      if (data.save_state === "successful") {
        card.setAttribute("data-checkin-completed-action", data.completed_action_id);
        form.reset();
        await this.transitionToInterstitial(card);
      } else {
        this.showError(card, data.errors);
      }
    } catch (error) {
      await this.handleError(card, error)
    }
  }

  showError(card, errors = []) {
    this.addClasses(card, "state-error");
    const errString = errors.join("<br />");
    card.querySelectorAll('.post-input-error').forEach(node => { node.innerHTML = errString; });
    [...card.querySelectorAll(`.new_post_input_values input[type="submit"]`)].forEach((submit) => {
      submit.removeAttribute("disabled");
    });
  }

  async doFetch(card, url, method, payload = null) {
    const options = await this.getFetchOptions(method);

    if (payload) {
      options.body = JSON.stringify(payload);
    }

    let data = null;

    try {
      const response = await fetch(url, options);

      if (response.status >= 400) {
        const error = new Error(response.statusText || response.status);

        error.response = response;

        throw error;
      }

      if (method !== "DELETE") {
        data = await response.json();

        if (card) {
          card.setAttribute("data-checkin-completed-action", data.id);
        }
      } else {
        if (card) {
          card.removeAttribute("data-checkin-completed-action");
        }
      }
    } catch(error) {
      await this.handleError(card, error);
    }

    return Promise.resolve(card, data);
  }

  async handleError(card, error) {
    // TODO: Show this error to the user

    console.error(error);

    if (_.has(error, "response")) {
      const { response } = error;

      const data = await _.result(response, "json");

      console.dir({ data, response });
    }
  }

  hide(target) {
    if (target) {
      this.dh.addClass(target, "hidden");
    }
  }

  show(target) {
    if (target) {
      this.dh.removeClass(target, "hidden");
    }
  }

  isCheckinMethodTrigger(element) {
    return _.has(element.dataset, 'checkinMethod');
  }

  /**
   * @deprecated
   */
  isCheckinCardsContainer(element) {
    if (_.has(element.dataset, 'checkinCards')) return true;
    if (this.dh.closest(element, '[data-checkin-cards]')) return true;
    return false;
  }

  /**
   * @deprecated
   */
  isCheckinCardOrChild(element) {
    if (_.has(element.dataset, 'checkin')) return true;
    if (this.dh.closest(element, '[data-checkin]')) return true;
    return false;
  }

  getCheckinCardsContainer(card) {
    return this.dh.closest(card, '[data-checkin-cards]');
  }

  areAnyComplete(container) {
    if (!container) {
      return null;
    }

    return container.querySelectorAll('[data-checkin-completed-action][data-action-frequency="daily"]').length > 0
  }

  updateParticipantCheckinLanguage(card) {
    const checkinNoneContainer = document.querySelector('[data-checkin-none]');

    this.hide(checkinNoneContainer);

    if (card.dataset.actionFrequency === "one-time") {
      return;
    }

    const container = this.getCheckinCardsContainer(card);
    const checkedIn = document.querySelector('[data-prompt-checked-in]');
    const notCheckedIn = document.querySelector('[data-prompt-not-checked-in]');

    if (this.areAnyComplete(container)) {
      this.show(checkedIn);
      this.hide(notCheckedIn);
    } else {
      this.show(notCheckedIn);
      this.hide(checkedIn);
    }
  }

  async transitionToUndone(card) {
    this.activateTransitionFor(card, "undo");

    // state-complete only appears on page load
    this.removeClasses(card, "state-complete");

    [...card.querySelectorAll(`.new_post_input_values input[type="submit"]`)].forEach((submit) => {
      submit.removeAttribute("disabled");
    });

    await this.finalizeTransitionFor(card, 1500);
  }

  async transitionToInterstitial(card) {
    this.removeClasses(card, "state-error");
    this.activateTransitionFor(card, "interstitial");

    await this.finalizeTransitionFor(card, 5000, async () => {
      await this.transitionToCompleted(card);
    });
  }

  async transitionToPostInputForm(card) {
    this.activateTransitionFor(card, "post-input-form");

    await this.finalizeTransitionFor(card, 500);
  }

  async transitionToCompleted(card) {
    if (this.stateIsTransitionInterstitial(card)) {
      this.activateTransitionFor(card, "complete");

      await this.finalizeTransitionFor(card, 1500);
    }
  }

  async finalizeTransitionFor(card, delay, callback) {
    this.clearTransitionTimeoutFor(card);

    card.setAttribute("data-transitioning", true);

    this.updateParticipantCheckinLanguage(card);

    const newTimeout = setTimeout(async () => {
      card.removeAttribute("data-transitioning");

      if (typeof callback === "function") {
        await callback();
      }
    }, delay);

    this.transitionTimeouts.set(card, newTimeout);
  }

  clearTransitionTimeoutFor(card) {
    const currentTimeout = this.transitionTimeouts.get(card);

    if (currentTimeout) {
      clearTimeout(currentTimeout);
    }

    this.transitionTimeouts.delete(card);
  }

  /**
   * @deprecated
   */
  getContainer(child) {
    return this.dh.closest(child, "[data-checkin-cards]")
  }

  /**
   * @deprecated
   */
  getCard(child) {
    return this.dh.closest(child, "[data-checkin]")
  }

  stateIsTransitionInterstitial(card) {
    return this.dh.hasClass(card, 'transition-interstitial');
  }

  activateTransitionFor(card, name) {
    const classToAdd = this.toTransitionClass(name);
    const classesToRemove = _(this.transitionNames).without(name).map(this.toTransitionClass).value();

    this.swapClasses(card, classToAdd, ...classesToRemove);
  }

  swapClasses(card, classToAdd, ...classesToRemove) {
    this.addClasses(card, classToAdd);

    this.removeClasses(card, ...classesToRemove);
  }

  addClasses(card, ...classNames) {
    _.flatten(classNames).forEach((className) => {
      this.dh.addClass(card, className);
    });
  }

  removeClasses(card, ...classNames) {
    _.flatten(classNames).forEach((className) => {
      this.dh.removeClass(card, className);
    });
  }

  toTransitionClass(name) {
    return `transition-${name}`;
  }
};
