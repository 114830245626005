import React, { useCallback, useMemo, useState } from "react";
import Tooltip from "../atomic/Tooltip";
import Icons from "../icons";
import CheckInDay, { EventDay } from "./CheckInDay";
import { getTooltipDate, getDisplayDateRange } from "./helpers";

type Props = {
  eventDays: EventDay[];
  currentDay: string;
  ordinalDay: number;
  participantCurrentDay: string;
  todayIsDayOfMessage: string;
  dayOfMessage: string;
  allComplete: string;
  checkedIn: string;
  notCheckedIn: string;
};

const MS_PER_DAY = 60 * 60 * 26 * 1000;
const DAYS_VISIBLE = 14;

function CheckInDays(props: Props) {
  const {
    eventDays,
    currentDay,
    ordinalDay,
    participantCurrentDay,
    todayIsDayOfMessage,
    dayOfMessage,
    allComplete,
    checkedIn,
    notCheckedIn,
  } = props;

  // -1 is before event, 0 is in progress, 1 is after event
  const eventState = currentDay < eventDays[0]["date"]
    ? -1
    : currentDay > eventDays.at(-1)["date"]
    ? 1
    : 0

  const maxPage = Math.ceil(eventDays.length / DAYS_VISIBLE);

  const initPage =
    eventDays.length > DAYS_VISIBLE && ordinalDay >= 0
      ? Math.ceil(ordinalDay / DAYS_VISIBLE) - 1
      : ordinalDay < 0 && eventState > 0
      ? maxPage
      : 0

  const [page, setPage] = useState<number>(initPage);

  const displayDays = useMemo(() => {
    // If the event spans less than the days visible, reutrn all days
    if (eventDays.length < DAYS_VISIBLE) {
      return eventDays;
    }

    // Check that we're showing a two full weeks
    const start =
      page * DAYS_VISIBLE + DAYS_VISIBLE > eventDays.length
        ? eventDays.length - DAYS_VISIBLE
        : page * DAYS_VISIBLE;

    return eventDays.slice(start, start + DAYS_VISIBLE);
  }, [page]);

  const setNavBackward = useCallback(() => {
    setPage(page - 1 < 0 ? 0 : page - 1);
  }, [page, setPage]);

  const setNavForward = useCallback(() => {
    setPage(page + 1 > maxPage ? maxPage : page + 1);
  }, [page, setPage]);

  const dayMessage = useMemo(() => {
    if(ordinalDay < 0) {
      return ""
    } else if(participantCurrentDay === currentDay) {
      return todayIsDayOfMessage
    } else {
      return dayOfMessage
    }
  }, [ordinalDay])

  if (eventDays.length <= 0) return <></>;

  return (
    <div className="check-in-days">
      <div className="check-in-days__header">
        <div className="check-in-days__date">
          {dayMessage}
        </div>
        <span className="check-in-days__month-label">
          {getDisplayDateRange(
            displayDays[0].date,
            displayDays[displayDays.length - 1].date
          )}
        </span>
        {eventDays.length > DAYS_VISIBLE && (
          <div>
            <button
              className="check-in-days__prev"
              onClick={setNavBackward}
              aria-disabled={page === 0}
            >
              <Icons.ChevronLeft />
            </button>
            <button
              className="check-in-days__next"
              onClick={setNavForward}
              aria-disabled={page + 1 >= maxPage}
            >
              <Icons.ChevronRight />
            </button>
          </div>
        )}
      </div>
      <ul className="check-in-days__days">
        {displayDays.map((day) => (
          <li key={day.date}>
            <Tooltip
              content={
                <div className="check-in-day__tooltip">
                  <div className="check-in-day__tooltip-month">
                    {getTooltipDate(day.date)}
                  </div>
                  <div>
                    {day.is_complete
                      ? allComplete
                      : day.has_checkin
                      ? checkedIn
                      : notCheckedIn}
                  </div>
                </div>
              }
            >
              <CheckInDay {...{ day, currentDay, participantCurrentDay }} />
            </Tooltip>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CheckInDays;
