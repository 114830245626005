import CalendarIcon from "./CalendarIcon";
import Picker from "@wojtekmaj/react-daterange-picker";

const DateRange = ({
  start_date,
  end_date,
  locale,
  handleChange,
}: {
  start_date: Date | null;
  end_date: Date | null;
  locale: string | undefined;
  handleChange: (value: (Date | null)[] | Date | null) => void;
}) => {
  return (
    <div className="date-range">
      <Picker
        format="M/d/y"
        navigationLabel={({ label }) => label}
        value={[start_date, end_date]}
        onChange={handleChange}
        calendarIcon={<CalendarIcon />}
        formatShortWeekday={(locale, date) => date.toString().slice(0, 2)}
        showNeighboringMonth={false}
        rangeDivider="to"
        showLeadingZeros={false}
        shouldOpenCalendar={({ reason }) => (reason === "focus" ? false : true)}
        locale={locale}
      />
    </div>
  );
};

export default DateRange;
