import _ from "lodash";

export default class EcoChallengeSharing {

  bindListeners() {
    if(!window.addthis) return;

    window.addEventListener('load', this.initAddThis);
    document.addEventListener('ready', this.refreshAddThis);
    document.addEventListener('turbo:load', this.refreshAddThis);
  }

  initAddThis() {
    // addthis.init()
  }

  refreshAddThis() {
    // addthis.toolbox('.addthis_toolbox')
  }

};
