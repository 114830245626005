import DomHelpers from "../utility/dom_helpers.js";

export default class TeamLists {
  constructor(dh = new DomHelpers(), mh = new ModalHelpers()) {
    this.dh = dh;
    this.mh = mh;

    this.handleToggleTeamList = this.handleToggleTeamList.bind(this);
  }

  bindListeners() {
    document.addEventListener("click", this.handleToggleTeamList);
  }

  handleToggleTeamList(event) {
    const listDiv = event.target.closest(".team-list");

    if(!listDiv) return;
    if (!event.target.dataset.hasOwnProperty("expand") && !event.target.dataset.hasOwnProperty("collapse")) return;

    event.preventDefault();
    event.stopPropagation();

    const collapsed = listDiv.querySelector(".collapsed");
    const expanded = listDiv.querySelector(".expanded");

    if(event.target.dataset.hasOwnProperty("expand")) {
      collapsed.classList.add("d-none");
      expanded.classList.add("show");
    } else {
      collapsed.classList.remove("d-none");
      expanded.classList.remove("show");
    }
  }
}