import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";

export default class Filters {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleVisibilityToggleClick = this.handleVisibilityToggleClick.bind(this);
    this.init = this.init.bind(this);
  }

  bindListeners() {
    document.addEventListener('turbo:load', this.init);
    document.addEventListener('change', this.handleFilterChange);
    document.addEventListener('click', this.handleVisibilityToggleClick)
  }

  init() {
    const toggleEl = document.querySelector(".filter-toggle-group");
    if (!toggleEl) return null;
    const textEl = toggleEl.querySelector('.text');
    if (!textEl) return null;
    const showLabel = textEl.dataset.showLabel;
    const hideLabel = textEl.dataset.hideLabel;

    if (toggleEl.classList.contains('closed')) {
      textEl.innerHTML = showLabel;
      textEl.setAttribute('class', 'text icon-caret-down');
    } else {
      textEl.innerHTML = hideLabel;
      textEl.setAttribute('class', 'text icon-caret-up');
    }
  }

  handleVisibilityToggleClick(event) {
    if (!this.canToggleFilters(event.target)) return null;
    const toggle = this.dh.closest(event.target, '.filter-toggle-group');
    if (!toggle) return null;
    this.handleVisibilityToggle(toggle);
  }

  handleVisibilityToggle(toggleEl) {
    if (!toggleEl) return null;
    const textEl = toggleEl.querySelector('.text');
    this.doToggleVisibility(toggleEl, textEl);
  }

  doToggleVisibility(toggleEl, textEl) {
    if (!toggleEl || !textEl) return null;
    const showLabel = textEl.dataset.showLabel;
    const hideLabel = textEl.dataset.hideLabel;

    if (toggleEl.classList.contains('closed')) {
      this.dh.removeClass(toggleEl, 'closed');
      this.dh.addClass(toggleEl, 'open');
      this.dh.removeClass(textEl, 'icon-caret-down');
      this.dh.addClass(textEl, 'icon-caret-up');
      textEl.innerHTML = hideLabel;
    } else {
      this.dh.addClass(toggleEl, 'closed');
      this.dh.removeClass(toggleEl, 'open');
      this.dh.removeClass(textEl, 'icon-caret-up');
      this.dh.addClass(textEl, 'icon-caret-down');
      textEl.innerHTML = showLabel;
    }
  }

  canToggleFilters(el) {
    if (!el) return null;
    return el.getAttribute('data-filter-toggle') !== null;
  }

  handleFilterChange(event) {
    const filter = this.dh.closest(event.target, '[data-filter]');
    const form = this.dh.closest(event.target, '[data-search-filter]');
    if (filter && form) {
      event.preventDefault();
      event.stopPropagation();
      form.submit();
    }
  }

};
