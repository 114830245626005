import _ from "lodash";

export default (function() {
  function defProps(target, props) {
    Object.defineProperties(target, _.mapValues(props, (value) => ({ value })));

    return target;
  }

  _.mixin({ defProps });
})();
