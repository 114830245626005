import DomHelpers from "../utility/dom_helpers.js"

export default class CharacterCount {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;

    this.start = this.start.bind(this);
    this.updateRemaining = this.updateRemaining.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  bindListeners() {
    document.addEventListener('trix-focus', this.start);
    document.addEventListener('trix-change', this.handleEdit);
  }

  start(event) {
    const el = event.target;
    this.scope = this.dh.closest(el, '[data-character-minimum]');
    if (!this.scope) return null;

    this.minimum = this.scope.getAttribute("data-character-minimum");
    this.template = this.scope.getAttribute("data-character-limit-template");
    this.toolbar = this.scope.querySelector('.rte trix-toolbar');
    this.input = this.scope.querySelector('.rte trix-editor');
    if (!this.toolbar || !this.input) return null;

    this.addLimitContainer();
  }

  addLimitContainer() {
    const container = document.createElement('div');
    this.dh.addClass(container, "character-count");
    this.toolbar.appendChild(container);
  }

  handleEdit() {
    if (!this.scope) return null;
    this.updateRemaining();
  }

  updateRemaining() {
    const el = this.scope.querySelector(".character-count");
    if (!el) return null;
    el.innerHTML = this.calculateRemaining();
  }

  calculateRemaining() {
    const el = this.input.querySelector("div");
    if (!el) return "";

    let charCount = 0;
    let node = el.firstChild;
    while(node) {
      charCount += this.getNodeCharCount(node);
      node = node.nextSibling;
    }

    let diff = this.minimum - charCount;
    if(diff <= 0) return null;

    return this.template.replace("%{count}", diff);
  }

  getNodeCharCount(node) {
    if(node.nodeName == "FIGURE" || node.dataset?.trixSerialize) {
      // Ignore figures (Trix uploads)
      return 0;
    } else if(node.nodeType == 3) {
      // Text node
      return node.data.length;
    } else {
      return Array.from(node.childNodes).reduce((memo, child) => memo += this.getNodeCharCount(child), 0) || 0;
    }
  }

};
