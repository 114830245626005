import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";
import SmoothScroll from "../utility/smooth_scroll.js";

export default class Links {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;
    this.ss = new SmoothScroll;
    this.getAnchorStatus = this.getAnchorStatus.bind(this);
    this.isAnchor = this.isAnchor.bind(this);
    this.anchorsPresent = false;
    this.init = this.init.bind(this);
  }

  bindListeners() {
    document.addEventListener('turbo:load', this.init);
  }

  init() {
    //Find all links
    const links = document.links;
    [...links].forEach((anchor) => {
      var linkPrefix = anchor.getAttribute('href').substring(0, 4);
      if (linkPrefix.charAt(0) === '#') {
        this.isAnchor(anchor);
      }
    });
  }

  isAnchor(anchor) {
    var link = anchor.getAttribute('href').substring(1);
    var match = document.querySelector('a[name="' + link + '"]');

    if (match) {
      this.anchorsPresent = true;
      var headerHeight;

      if (document.querySelector('.global-header')) {
        headerHeight = document.querySelector('.global-header').offsetHeight;
      } else if (document.querySelector('.dashboard-header')) {
        headerHeight = document.querySelector('.dashboard-header').offsetHeight;
      }

      anchor.addEventListener('click', (event) => {
        this.smoothScroll(event, match, headerHeight);
      });
      return true;
    }
  }

  smoothScroll(event, match, offset, callback) {
    event.preventDefault();
    this.ss.smoothScroll(match, offset, callback);
  }

  getAnchorStatus() {
    return this.anchorsPresent;
  }

}
