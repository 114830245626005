import { useEffect, useMemo, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ApiInterface from "../ApiInterface";
import ImpactIcon from "./ImpactIcon";
import ImpactForm from "./ImpactTypeForm";
import Collapsible from 'react-collapsible';

function SortableImpactTypeList({ locales = [] }) {

  const api: ApiInterface = new ApiInterface("api/admin/impact_types");
  const [sortedImpactTypes, setImpactTypes] = useState([]);
  const [errors, setErrors] = useState([]);
  const [sorting, setSorting] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const impactTypeFetcher = async () => {
      const response = await api.get("");
      const body = await response.json();
      setImpactTypes(body.data);
    }

    impactTypeFetcher();
  }, []);

  const toggleSorting = (event) => {
    if(sorting) {
      submitSort();
    }

    setSearchQuery(null);
    setSorting(!sorting);
  }

  const hasUnsavedImpactType = useMemo(() => sortedImpactTypes.some((type) => !type.id), [sortedImpactTypes])

  function onDragEnd(result): void {
    if(result.source.index == result.destination.index) return;

    const newSortedImpactTypes = Array.from(sortedImpactTypes);
    const [movedImpactType] = newSortedImpactTypes.splice(result.source.index, 1);
    newSortedImpactTypes.splice(result.destination.index, 0, movedImpactType);
    newSortedImpactTypes.forEach((impact, index) => { impact.sort = index })

    setImpactTypes(newSortedImpactTypes);
  }

  function getListStyle(_isDraggingOver: boolean) {
    return {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
      listStyle: "none"
    };
  }

  function getItemStyle(isDragging: boolean, draggableStyle: object) {
    return {
      background: isDragging ? "cornsilk" : "white",
      boxShadow: isDragging ? "10px 10px 10px gray" : "none",
      margin: "3px 0",
      ...draggableStyle
    }
  }

  const handleResponse = async (response) => {
    if (response.ok) {
      const body = await response.json();
      setImpactTypes(body.data);
    } else if (response.status < 500) {
      const body = await response.json();
      setErrors(body.errors);
    } else {
      setErrors(["Something went wrong."])
    }
  }

  async function submitSort() {
    const payload = {
      impact_types: sortedImpactTypes.map((it) => {
        return {
          id: it.id,
          sort: it.sort
        }
      })
    };

    const response = await api.post("sort_order", payload)
    handleResponse(response);
  }

  const applyDefaultSort = async (event: React.SyntheticEvent) => {
    const response = await api.post("apply_default_sort_order");
    handleResponse(response);
  }

  const handleDelete = (index: number) => {
    const impactTypes =[...sortedImpactTypes]
    impactTypes.splice(index, 1);
    setImpactTypes(impactTypes);
  }

  const handleNew = event => {
    const newImpactType = {
      name: "",
      key: "",
      sort: 0,
      icon: "",
      color: "",
      singular_unit: "",
      plural_unit: "",
      singular_label: "",
      plural_label: "",
      card_pre_label: "",
      card_post_label: "",
      card_singular_unit: "",
      card_plural_unit: ""
    }

    setImpactTypes([newImpactType].concat(Array.from(sortedImpactTypes)));
  }

  const listItems = sortedImpactTypes.map((impact: Object, index: number) => {
    if(sorting) {
      return(
        <Draggable key={impact.key || `NEW-${index}`} draggableId={impact.key} index={index}>
          {(provided, snapshot) => (
            <li ref={provided.innerRef} className={impact.color} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
              <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "2px 30px"}}>
                <div>{(impact.sort || 0) + 1}</div>
                <div>{impact.name} ({impact.key})</div>
                <div><ImpactIcon impact={impact} sizeClass="small" /></div>
              </div>
            </li>
          )}
        </Draggable>
      );
    } else {
      if(!searchQuery || (impact.key && impact.key.replaceAll(/[\s_]/g, "").includes(searchQuery.toUpperCase().replaceAll(/[\s_]/g, "")))) {
        return (
          <li key={impact.key || `new-${index}`} className={impact.color} style={getItemStyle(false, {})}>
            <div>
              <ImpactForm impactType={impact} locales={locales} index={index} onDelete={handleDelete} />
            </div>
          </li>
        );
      }
    }
  });

  const setSearch = event => {
    setSearchQuery(event.target.value);
  }

  return (
    <div style={{marginTop: "20px"}}>

      {errors && (
        <div style={{color: "red"}}>
          <ul>
            {Object.entries(errors).map((key, message, i) => <li key={`error-${i}`}>message</li>)}
          </ul>
        </div>
      )}

      {sortedImpactTypes.length &&
        (<>
          <div style={{display: "flex", justifyContent: "space-around"}}>
            <button className="button-primary large" onClick={toggleSorting} disabled={hasUnsavedImpactType}>
              {sorting ? "End and Save" : "Sort Impact Types"}
            </button>

            {sorting && (
              <>
                <div />

              <button className="button-primary large" onClick={applyDefaultSort}>
                Apply Default Sort Order
              </button>
              </>
            ) || (
              <>
                <button className="button-primary large" onClick={handleNew}>
                  Create New
                </button>

                <div className="form-field">
                  <input type="text" placeholder="Filter By Name" onChange={setSearch} value={searchQuery}></input>
                </div>
              </>
            )}
          </div>

          <div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="impact-list">
                {(provided, snapshot) => (
                  <ul {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                    {listItems}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </>)
        ||
        <div>Loading...</div>
      }

    </div>
  );
}

export default SortableImpactTypeList;
