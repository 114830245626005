import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";

export default class DropdownNavigation {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;
    this.handleChange = this.handleChange.bind(this);
    this.mayHandleDropdownNavigation = this.mayHandleDropdownNavigation.bind(this);
  }

  bindListeners() {
    document.addEventListener('change', this.handleChange);
  }

  handleChange(event) {
    if (!this.mayHandleDropdownNavigation(event.target)) return;
    const url = event.target.options[event.target.options.selectedIndex].dataset.trigger;
    if (!url) return null;
    Turbo.visit(url);
  }

  mayHandleDropdownNavigation(element) {
    return _.has(element.dataset, 'dropdownNav');
  }

};
