import { useState, useEffect, useLayoutEffect, useCallback, useRef } from "react";
import ApiInterface from "../ApiInterface";
import { useDebounce } from "./useDebounce";
import Typeahead from "../atomic/Typeahead";

type Props = {
  id: string;
  selected?: Org;
  idFieldName: string;
  nameFieldName: string;
  placeholder?: string;
  eventId?: string;
  allowClear?: boolean;
  allowCreate?: boolean;
  triggerChangeEvent?: boolean;
  limitToEventOrganizations?: boolean;
  required?: boolean;
};

type Org = {
  label: string;
  value: number | null;
};

export default function OrganizationTypeahead({
  id,
  selected,
  idFieldName,
  nameFieldName,
  placeholder,
  eventId,
  allowClear = false,
  allowCreate = true,
  triggerChangeEvent = false,
  limitToEventOrganizations = false,
  required = false
}: Props) {
  const [selectedOrg, setSelectedOrg] = useState<Org | null>(selected || null);
  const [orgs, setOrgs] = useState<Org[]>([]);
  const [query, setQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const debouncedQuery = useDebounce<string>(query, 300);

  const api: ApiInterface = new ApiInterface("/api/v1/organizations");

  // Fetch and set the list of orgs
  const orgFetcher = useCallback(async (sword: string) => {

    const args = {
      sword: sword.toLowerCase(),
      limit_to_event_organizations: limitToEventOrganizations
    };
    const response = await api.get("", args);
    const body = await response.json();

    if(body.error) {
      setErrorMessage(body.error);
      setOrgs([])
      return;
    }

    const hasExactMatch = sword
      ? body.some((org: Org) => org.label.toLowerCase() == sword.toLowerCase())
      : false

    const createOptions = allowCreate && sword && !hasExactMatch
      ? [
          {
            value: null,
            label: sword,
          },
        ]
      : [];

    if (!body) {
      setOrgs(createOptions);
      return;
    }

    setOrgs([...body, ...createOptions]);
  }, []);

  const onInputChange = useCallback((q: string) => setQuery(q), []);

  const onChange = useCallback(
    (o: Org | null) => setSelectedOrg((v) => (v?.value !== o?.value ? o : v)),
    []
  );

  const idFieldRef = useRef(null);
  const nameFieldRef = useRef(null);

  // Used for when an input change needs to trigger an external JS event, such as filter form submission
  useLayoutEffect(() => {
    if (triggerChangeEvent) {
      if (selectedOrg && idFieldRef.current && selectedOrg != selected) {
        idFieldRef.current?.dispatchEvent(new Event('change', { bubbles: true }));
      } else if(!selectedOrg && nameFieldRef.current) {
        nameFieldRef.current?.dispatchEvent(new Event('change', { bubbles: true }));
      }
    }
  }, [selectedOrg])

  const displayValue = useCallback((o: Org) => o?.label ?? "", []);

  // Triggers on mount and when "debouncedValue" changes
  useEffect(() => {
    orgFetcher(debouncedQuery);
  }, [debouncedQuery]);

  return (
    <>
      <Typeahead<Org>
        id={id}
        options={orgs}
        onInputChange={onInputChange}
        onChange={onChange}
        placeholder={placeholder}
        value={selectedOrg}
        allowClear={allowClear}
        createPrefix="Create new Organization named"
        displayValue={displayValue}
        required={required}
      />
      {!!selectedOrg && !selectedOrg.value ? (
        <input
          type="hidden"
          name={nameFieldName}
          value={`${selectedOrg.label || ''}`}
          ref={nameFieldRef}
        />
      ) : (
        <input
          type="hidden"
          name={idFieldName}
          value={`${selectedOrg?.value || ''}`}
          ref={idFieldRef}
          data-filter={true}
        />
      )}
      {errorMessage && (
        <div className="error">
          <div className="error-message">
            {errorMessage}
          </div>
        </div>
      )}
    </>
  );
}
