import React, { useMemo, useRef, useState } from "react";
import ImpactIcon from "./ImpactIcon";
import ApiInterface from "../ApiInterface";

export default function ImpactTypeForm({ impactType = {}, locales = [], index, onDelete }) {
    
    const api: ApiInterface = new ApiInterface("api/admin/impact_types")

    const [impactCount, setImpactCount] = useState("singular");

    const [data, setData] = useState({ ...impactType })
    const [locale, setLocale] = useState(locales[0])
    const [errors, setErrors] = useState([]);
    const [dirty, setDirty] = useState(false);
    const [saved, setSaved] = useState(false);
    const [preserveKey, setPreserveKey] = useState(!!data.key);

    const formRef = useRef(undefined);

    const LOCALIZED_ATTRS = [
        "singular_unit",
        "plural_unit",
        "singular_label",
        "plural_label",
        "card_pre_label",
        "card_post_label",
        "card_singular_unit",
        "card_plural_unit"
    ];

    const submit = async (event) => {
        event.preventDefault();
        if(!formRef.current.checkValidity()) {
            formRef.current.reportValidity();
            return;
        };

        const formData = new FormData(formRef.current);
        Object.entries(data).forEach(([key, value]) => {
            formData.set(key, value == null ?  "" : value);
        });

        const response = await api.submitResource(formData);
        if(response.ok) {
            const body = await response.json();
            setData(body.data);
            handleSave();
        } else {
            const body = await response.json();
            setErrors(body.errors);
        }
    }

    const revertChanges = () => {
        if(!data.id) return onDelete(index);

        setData({...impactType});
        setSaved(false);
        setDirty(false);
    }

    const updateData = (key, value) => {
        setDirty(true);

        setData({ ...data, [key]: value });
    }

    const updateDataObject = (update = {}) => {
        setDirty(true);
        setData({ ...data, ...update })
    }

    const handlePreserveKeyChange = event => {
        setPreserveKey(event.target.checked);

        if(event.target.checked) {
            updateDataObject({ key: impactType.key });
        } else {
            const newKey = data.name.toUpperCase().replaceAll(/\W/g, "_");
            updateDataObject({ key: newKey });
        }
    }

    const handleSave = () => {
        setDirty(false);
        setSaved(true);
        setPreserveKey(true);
        setTimeout(() => setSaved(false), 1000);
    }

    const handleAttributeChange = event => {
        const key = event.target.name;
        const value = event.target.value;
        updateData(key, value);
    }

    const handleNameChange = event => {
        // Key should be static if impact type is already persisted
        if(preserveKey) return handleAttributeChange(event);

        const newKey = event.target.value.toUpperCase().replaceAll(/\W/g, "_");
        updateDataObject({ name: event.target.value, key: newKey })
    }

    const handleImpactCount = event => {
        setImpactCount(event.target.dataset.impactCount || "singular")
    }

    const handleLocaleChange = event => {
        setLocale(event.target.dataset.locale);
    }

    const normalizedLocale = useMemo(() => locale.replaceAll("-", "_").toLowerCase(), [locale]);

    const fieldPropsFor = (fieldName: string, required: boolean = false) => {
        const localizedFieldName = LOCALIZED_ATTRS.includes(fieldName) ? `${fieldName}_${normalizedLocale}` : fieldName;

        return {
            key: localizedFieldName,
            name: localizedFieldName,
            value: data[localizedFieldName],
            placeholder: fieldName.replaceAll("_", " ").toUpperCase(),
            onChange: handleAttributeChange,
            autoComplete: "off",
            required: required
        };
    }

    const pluralNumber = useMemo(() => Math.floor(Math.random() * 100) + 1, []);

    const iconOptions = [
        "Bike",
        "Bus",
        "Choose",
        "Community",
        "Energy",
        "Food",
        "Foot",
        "Health",
        "Materials",
        "Money",
        "Nature",
        "Simplicity",
        "Smokestack",
        "Vehicle",
        "Water",
        "Custom"
    ].map(i => {
        return <option value={i.toLowerCase()} key={i.toLowerCase()}>{i}</option>
    });

    const colorOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, "Blazers"].map(c => {
        const val = c.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false })
        return <option value={`spectrum${val}`} key={`spectrum${val}`}>Spectrum{c}</option>
    });

    const handleCustomIconChange = event => {
        updateData("custom_icon_url", URL.createObjectURL(event.target.files[0]));
    }

    const impactCard = (
        <div className={`impact-card ${data.color}`} style={{maxWidth: "315px", border: "1px solid gainsboro"}}>
            <div className="icon">
                {data.icon == "custom" && 
                    (<label style={{cursor: "pointer"}}>
                        <ImpactIcon impact={data} sizeClass="medium" />
                    <input type="file" {...fieldPropsFor("custom_icon")} style={{ display: "none" }} onChange={handleCustomIconChange}></input>
                    </label>)
                    ||
                    (<ImpactIcon impact={data} sizeClass="medium" />)
                }
                
            </div>
            <div className="form-field">
                Icon:&nbsp;
                <select {...fieldPropsFor("icon", true)}>
                    <option key="none" disabled></option>
                    {iconOptions}
                </select>
            </div>

            <div className="form-field">
                Color:&nbsp;
                <select {...fieldPropsFor("color", true)}>
                    <option key="none" disabled></option>
                    {colorOptions}
                </select>
            </div>
            <div className="label">up to</div>
            <div className="count">{impactCount == "singular" ? "1" : pluralNumber}</div>
            <div className="units form-field">
                <input type="text" {...fieldPropsFor(`${impactCount}_unit`)} />
            </div>
            <div className="description form-field">
                <input type="text" {...fieldPropsFor(`${impactCount}_label`)} />
            </div>
        </div>
    )

    const actionCard = (
        <div className="participant-action-cards" style={{flexGrow: "0"}}>
            <div className={`card-container transition-interstitial spectrum01`}>
                <div className="interstitial impact-form" style={{ transform: "scale(1.12, 1) rotateY(0deg)", display: "flex", flexDirection: "column" ,justifyContent: "space-between" }}>
                    <header>Congratulations Participant!</header>
                    <div>
                        <div className="form-field">
                          <input type="text" {...fieldPropsFor("card_pre_label")} />
                        </div>
                        <span>
                            {impactCount == "singular" ? "1" : pluralNumber}<br />
                            <div className="form-field">
                                <input type="text" {...fieldPropsFor(`card_${impactCount}_unit`)} style={{width: "100%"}} /> <br />
                            </div>
                            <div className="form-field">
                                <input type="text" {...fieldPropsFor(`card_post_label`)} style={{ width: "100%" }} />
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )

    const localeOptions = (
        locales.map((l) => {
            return <button key={l} type="button" onClick={handleLocaleChange} className={`button-primary ${locale == l ? "" : 'grey'} small`} data-locale={l}>{l}</button>
        })
    )

    return (
        <div className={`impact-form form-cards__card ${data.id ? "" : "new"}`}>

            {errors && (
                <div style={{ color: "red" }}>
                    <ul>
                        {Object.entries(errors).map(([key, message], i) => <li key={`error-${i}`}>{`${key}: ${message}`}</li>)}
                    </ul>
                </div>
            )}

            <form ref={formRef}>
                <input type="hidden" {...fieldPropsFor("id")} />

                <div className="header">
                    <div className="form-field">
                        <label className="required">Impact Type Name</label>
                        <input type="text" {...fieldPropsFor("name", true)} onChange={handleNameChange}/>
                    </div>
                    <div className="form-field">
                        <div>
                            <label style={{display: "inline-block"}}>Key</label>
                            {data.id && (
                            <div style={{display: "inline-block", float: "right"}}>
                                <input type="checkbox" value="preserveKey" checked={preserveKey} onChange={handlePreserveKeyChange} />
                                <label style={{display: "inline-block", paddingLeft: "5px"}}>Preserve Key</label>
                            </div>)}
                        </div>
                        <div style={{fontWeight: "bold", textAlign: "center"}}>{data.key || "..."}</div>
                        <input type="hidden" {...fieldPropsFor("key", true)} />
                    </div>
                </div>

                <div className="cards">
                    <div className="toggles">
                        <div>Locale:</div>
                        {localeOptions}
                    </div>
                    <div className="toggles">
                        <button type="button" onClick={handleImpactCount} className={`button-primary ${impactCount == "singular" ? "" : "grey"} small`} data-impact-count="singular">Singular</button>
                        <button type="button" onClick={handleImpactCount} className={`button-primary ${impactCount == "plural" ? "" : "grey"} small`} data-impact-count="plural">Plural</button>
                    </div>

                    {impactCard}
                    {actionCard}
                </div>

                <hr />

                <div className={`controls ${saved ? "saved" : ""}`}>
                    <div style={{fontWeight: "bold"}}>
                        {
                            (!data.id && <div style={{color: "var(--accent-secondary"}}>NEW</div>)
                            || (saved && <div style={{color: "white"}}>SAVED</div>)
                            || (<div />)
                        }
                    </div>
                    <div>
                        <button className="button-primary" onClick={submit} disabled={!dirty}>Submit Changes</button>
                        <button className={`button-primary ${data.id ? "grey" : "danger"}`} onClick={revertChanges} disabled={data.id && !dirty}>{data.id ? "Revert" : "Delete"}</button>
                    </div>
                </div>
            </form>
        </div>
    );
}