import tippy from 'tippy.js';

export default class Tooltips {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;

    const reducedMotionQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
    this.reducedMotion = reducedMotionQuery && reducedMotionQuery.matches;
  }

  bindListeners() {
    window.addEventListener("turbo:load", this.initializeTips.bind(this));
    // window.addEventListener("turbo:before-cache", this.closeAllTips.bind(this, true, true));
  }

  initializeTips() {
    document.querySelectorAll("[data-tooltip]").forEach((el) => {
      tippy(el, {
        content: el.dataset["tooltip"],
        allowHTML: true,
        placement: "bottom",
        theme: "tooltip"
      });
    });
  }

}
