import DomHelpers from "../utility/dom_helpers.js"

export default class AutosubmitForm {
  constructor(dh = new DomHelpers()) {
    this.dh = dh;

    this.handleFormChange = this.handleFormChange.bind(this);
  }

  bindListeners() {
    document.addEventListener('change', this.handleFormChange);
  }

  async handleFormChange(event) {
    const input = event.target;
    if(!input.dataset.hasOwnProperty("submitOnChange")) return;

    const form = input.closest("form");
    if(!form) return;

    const endpoint = form.action;
    const data = new FormData(form);
    const method = data.get("_method") || form.method;

    const response = await fetch(endpoint, {
      method: method,
      body: data
    });

    if(!response.ok) {
      const json = await response.json();
      const errors = json?.errors || ["Something went wrong"];
      const errorContainer = form.querySelector(".error-message")

      errorContainer.innerHTML = errors.join("<br />")
      input.checked = !input.checked;
    }
  }
}