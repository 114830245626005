import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";
import ModalHelpers from "../utility/modal_helpers.js";

export default class Modal {
  constructor(dh = new DomHelpers(), mh = new ModalHelpers()) {
    this.dh = dh;
    this.mh = mh;

    this.modalVisible = false;
    this.yayEventHandler = null;
    this.nayEventHandler = null;
    this.inputEventHandler = null;
    this.windowKeyDownHandler = null;
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.dismissModal = this.dismissModal.bind(this);
    this.setModalElementVisibility = this.setModalElementVisibility.bind(this);
    this.setupListeners = this.setupListeners.bind(this);
    this.destroyListeners = this.destroyListeners.bind(this);
    this.maybeOpenOverlay = this.maybeOpenOverlay.bind(this);
  }

  bindListeners() {
    document.addEventListener("showModal", this.handleShow);
    document.addEventListener("hideModal", this.handleHide);
    document.addEventListener("click", this.dismissModal);
    document.addEventListener("turbo:load", this.maybeOpenOverlay);
  }

  maybeOpenOverlay() {
    const overlay = document.querySelector(".overlay");
    const openModal = document.querySelector(".modal.state-warn");

    if (!openModal) return null;

    this.dh.addClass(overlay, "state-warn");
    this.mh.setBodyScrollLock(openModal);
  }

  dismissModal(event) {
    if (!this.dh.hasDataAttribute(event.target.dataset, "modalDismiss"))
      return null;
    this.hideModal(event.target);
  }

  handleShow(event) {
    const config = this.validateConfig(event.detail);
    this.showModal();
    this.setModalContents(config);
    this.setModalElementVisibility(config);
    this.setupListeners(config);
  }

  handleHide(event, isKeyEvent) {
    const toggleEl = isKeyEvent
      ? document.querySelector(".modal [data-modal-dismiss]")
      : event.target;
    this.hideModal(toggleEl);
    this.destroyListeners();
  }

  setupListeners(config) {
    this.destroyListeners();
    const yayButton = document.querySelector(".modal [data-modal-yay]");
    const nayButton = document.querySelector(".modal [data-modal-nay]");
    const input = document.querySelector(".modal [data-modal-input]");
    this.yayEventHandler = (event) => {
      if (!config.doNotCloseOnYay) this.handleHide(event);
      const payload = Object.assign(
        {
          modalInputValue: input.value,
          closeMethod: () => {
            this.handleHide(event);
          },
        },
        config.yayData
      );
      config.onYay(payload);
    };
    this.nayEventHandler = (event) => {
      this.handleHide(event);
      config.onNay(config.yayData);
    };
    this.inputEventHandler = (event) => {
      if (event.keyCode == 13) {
        this.yayEventHandler(event);
      }
    };
    this.windowKeyDownHandler = (event) => {
      const { key, shiftKey } = event;
      if (key !== "Escape" && key !== "Tab") return;
      key === "Escape" && this.handleHide(event, true);
      key === "Tab" &&
        this.mh.handleTab(event, document.querySelector("[data-modal-warn]"));
    };
    yayButton.addEventListener("click", this.yayEventHandler);
    nayButton.addEventListener("click", this.nayEventHandler);
    input.addEventListener("keydown", this.inputEventHandler);
    window.addEventListener("keydown", this.windowKeyDownHandler);
  }

  destroyListeners() {
    const yayButton = document.querySelector(".modal [data-modal-yay]");
    const nayButton = document.querySelector(".modal [data-modal-nay]");
    const input = document.querySelector(".modal [data-modal-input]");
    input.removeEventListener("keydown", this.inputEventHandler);
    yayButton.removeEventListener("click", this.yayEventHandler);
    nayButton.removeEventListener("click", this.nayEventHandler);
    window.removeEventListener("keydown", this.windowKeyDownHandler);
  }

  setModalElementVisibility(config) {
    const nayButton = document.querySelector(".modal [data-modal-nay]");
    const yayButton = document.querySelector(".modal [data-modal-yay]");
    const closeButton = document.querySelector(".modal [data-modal-dismiss]");
    if (config.hideNay == true) {
      nayButton.style.display = "none";
    } else {
      nayButton.style.display = "";
    }
    if (config.hideYay == true) {
      yayButton.style.display = "none";
    } else {
      yayButton.style.display = "";
    }
    if (config.hideClose == true) {
      closeButton.style.display = "none";
    } else {
      closeButton.style.display = "";
    }
    const input = document.querySelector(".modal [data-modal-input]");
    input.setAttribute("placeholder", config.inputPlaceholder);
    if (config.showInput == true) {
      input.style.display = "";
    } else {
      input.style.display = "none";
    }
  }

  setModalContents(config) {
    const title = document.querySelector(".modal [data-modal-title]");
    const body = document.querySelector(".modal [data-modal-body]");
    const form = document.querySelector(".modal [data-modal-form]");
    const yayButton = document.querySelector(".modal [data-modal-yay]");
    const nayButton = document.querySelector(".modal [data-modal-nay]");
    title.innerHTML = config.title;
    body.innerHTML = config.body;
    form.innerHTML = config.form || "";
    yayButton.innerHTML = config.yayLabel;
    nayButton.innerHTML = config.nayLabel;
  }

  showModal() {
    const modal = document.querySelector("[data-modal-warn]");
    this.mh.showModal(modal);
    this.modalVisible = true;
    const yayButton = modal.querySelector("[data-modal-yay]");
    if (yayButton) yayButton.focus();
  }

  hideModal(el) {
    this.mh.hideModal(this.dh.closest(el, "[data-modal-warn]"));
    this.modalVisible = false;
  }

  validateConfig(inputConfig) {
    return Object.assign(
      {
        title: "Are you sure?",
        body: "Please confirm that you would like to perform this action",
        yayLabel: "Confirm",
        nayLabel: "Cancel",
        hideNay: false,
        yayData: {},
        nayData: {},
        onYay: (data) => {
          console.log(data);
        },
        onNay: (data) => {
          console.log(data);
        },
        showInput: false,
        inputPlaceholder: "Set inputPlaceholder config value",
        doNotCloseOnYay: false,
      },
      inputConfig
    );
  }
};
