import { forwardRef } from "react";

type option = { label: string; value: string };

interface Props extends Omit<React.HTMLProps<HTMLSelectElement>, "data"> {
  label?: string;
  options: option[];
}

const Select = (
  {
    disabled = false,
    placeholder,
    options,
    required,
    className,
    ...inputProps
  }: Props,
  ref: React.LegacyRef<HTMLSelectElement>
) => {
  return (
    <div className="select-wrapper" aria-disabled={disabled}>
      <select
        className=""
        ref={ref}
        aria-disabled={disabled}
        required={required}
        {...inputProps}
      >
        {placeholder && (
          <option value="" disabled selected>
            {placeholder}
          </option>
        )}
        {options &&
          options.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
      </select>
    </div>
  );
};

export default forwardRef(Select);
