import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static outlets = ["selector"];

  static targets = [
    "localeSelector",
    "hidden",
    "input",
    "reference",
    "referenceWrapper",
    "string",
    "textarea",
  ];

  static values = {
    attribute: String,
    kind: {
      type: String,
      default: "string",
    },
    locale: {
      type: String,
      default: "en",
    },
    referenceLocale: {
      type: String,
      default: "en",
    },
  };

  initialize() {
    this._ensureCache();
  }

  connect() {
    this.localeValue = this.calculateCurrentLocale();
  }

  calculateCurrentLocale() {
    return this.localeSelectorTarget.value;
  }

  swapCurrentLocale(event) {
    this.localeValue = event.originalTarget?.value || event.target.value;
  }

  /**
   * This action is triggered by _another_ locale selection.
   */
  maybeSwapLocale(event) {
    if (event.target !== this.localeSelectorTarget) {
      // We got the event from another field, so we should change our locale to match
      // in order to make it easier for someone to copy+paste

      this.localeValue = event.target.value;
    }
  }

  getCurrentLocalizedElement() {
    return this.getLocalizedElementFor(this.localeValue);
  }

  getLocalizedElementFor(locale) {
    return this.localizedInputs?.get(locale);
  }

  updateLocalized(event) {
    const element = this.getCurrentLocalizedElement();

    const newValue = event?.originalTarget?.value ?? event?.target?.value ?? "";

    element.value = newValue;

    if (this.localeValue === this.referenceLocaleValue) {
      this.referenceTarget.value = newValue;
    }
  }

  _ensureCache() {
    if (!this.localizedInputs) {
      this.localizedInputs = new Map();
    }
  }

  _reflectCurrentLocale() {
    this._setInputForLocale(this.localeValue);
  }

  _setInputForLocale(currentLocale) {
    const element = this.getLocalizedElementFor(currentLocale);

    // might be null during initialization
    if (!element) {
      return null;
    }

    this.inputTarget.value = element.value;
  }

  _updateReference() {
    this._toggleReferenceVisibility();
    this._updateReferenceText();
  }

  _toggleReferenceVisibility() {
    const shouldBeHidden = this.localeValue === this.referenceLocaleValue;

    this.referenceWrapperTarget.classList.toggle("hidden", shouldBeHidden);
  }

  _updateReferenceText() {
    const element = this.getLocalizedElementFor(this.referenceLocaleValue);

    // might be null during initialization
    if (!element) {
      return null;
    }

    this.referenceTarget.value = element.value;
  }

  hiddenTargetConnected(element) {
    const locale = element.dataset.locale;

    this.localizedInputs.set(locale, element);

    this._reflectCurrentLocale();
  }

  hiddenTargetDisconnected(element) {
    this.localizedInputs.delete(element.dataset.locale);
  }

  localeValueChanged(value, previousValue) {
    this._setInputForLocale(value);

    this._updateReference();
  }

  selectorOutletConnected(outlet, element) {
    console.info("got selector outlet");
    console.dir({ outlet, element });
  }
}
