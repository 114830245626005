import React from "react";
import Icons from "../icons";

export type EventDay = {
  participant_id: number;
  team_id: number;
  event_id: number;
  date: string;
  cweek: number;
  month: number;
  year: number;
  day: string;
  has_checkin: boolean | null;
  is_complete: boolean | null;
  in_progress: boolean | null;
};

type Props = {
  day: EventDay;
  currentDay: string;
  participantCurrentDay: string;
};

export default function CheckInDay({
  day,
  currentDay,
  participantCurrentDay,
}: Props) {
  return (
    <a
      className={`calendar-day ${
        day.is_complete
          ? "action-complete"
          : day.has_checkin
          ? "checked-in"
          : "not-checked-in"
      } ${participantCurrentDay === day.date ? "today" : ""}`}
      href={`${window.location.origin}/dashboards/${day.date}`}
      title={day.date}
      aria-current={currentDay === day.date ? "page" : undefined}
    >
      <span className="calendar-day__text">{day.day}</span>
      {day.has_checkin && <Icons.Checkmark className="calendar-day__check" />}
    </a>
  );
}
