import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";
import ScrollLock from "./scroll_lock.js";

export default class ModalHelpers {
  constructor() {
    this.dh = new DomHelpers();
    this.sl = new ScrollLock();
  }

  setBodyScrollLock(modal) {
    this.sl.only(modal);
    this.dh.addClass(document.body, "scroll-lock");
  }

  unsetBodyScrollLock(modal) {
    this.sl.any(modal);
    this.dh.removeClass(document.body, "scroll-lock");
  }

  showOverlay() {
    const overlay = document.querySelector(".overlay");
    if (!overlay) return null;

    this.dh.addClass(overlay, "state-warn");
  }

  hideOverlay() {
    const overlay = document.querySelector(".overlay");
    if (!overlay) return null;

    this.dh.removeClass(overlay, "state-warn");
  }

  showModal(modal) {
    if (!modal) return null;

    // this.dh.removeAttribute(modal, "hidden");
    this.showOverlay();
    this.setBodyScrollLock(modal);
    this.dh.addClass(modal, "state-warn");
  }

  hideModal(modal) {
    if (!modal) return null;

    this.hideOverlay();
    this.unsetBodyScrollLock(modal);
    this.dh.removeClass(modal, "state-warn");
    // this.dh.addAttribute(modal, "hidden");
  }

  handleTab(event, modal) {
    if (!modal) return null;

    const focusable = this.focusable(modal);

    if (!focusable || !focusable.length) return false;

    const focusedItem = document.activeElement;
    const focusableIndex = focusable.indexOf(focusedItem);

    const lastIndex = event.shiftKey
      ? focusableIndex === 0
      : focusableIndex === focusable.length - 1;

    // if tab or shift + tab will remain within `focusable`,
    // proceed with default key behavior
    if (!lastIndex) return;

    // if not, prevent default and jump to other end of `focusable`
    event.preventDefault();
    const nextFocus = event.shiftKey
      ? focusable[focusable.length - 1]
      : focusable[0];
    nextFocus.focus();
  }

  focusable(el) {
    return Array.from(
      el.querySelectorAll(
        'summary, button:not(:disabled), [href], input, select, textarea, [tabindex]:not([tabindex="-1"])' // eslint-disable-line max-len
      )
    );
  }
}
