export default class ApiInterface {

  baseRoute: string;

  constructor(baseRoute: string) {
    this.baseRoute = this.truncateSlashes(baseRoute);
  }

  headersFor(data) {
    const headers = {
      "X-CSRF-Token": Rails.csrfToken(),
      "Accept": "application/json"
    }

    // If data is JSON, we need to tell the server to parse it as JSON
    // Otherwise, if instance of FormData fetch will correctly assign Content-Type
    if(!(data instanceof FormData)) {
      headers['Content-Type'] = "application/json"
    }

    return headers;
  }

  async get(route = "", params = {}) {
    return await fetch(this.buildHref(route, params), {
      method: "GET",
      credentials: "include",
      headers: {
        "Accept": "application/json"
      }
    });
  }

  async post(route = "", data = {}) {
    return await fetch(this.buildHref(route), {
      method: "POST",
      credentials: "include",
      headers: this.headersFor(data),
      body: (data instanceof FormData) ? data : JSON.stringify(data)
    });
  }

  async put(route = "", data = {}) {
    return await fetch(this.buildHref(route), {
      method: "PUT",
      credentials: "include",
      headers: this.headersFor(data),
      body: (data instanceof FormData) ? data : JSON.stringify(data)
    });
  }

  async submitResource(resource) {
    const resourceId = resource instanceof FormData ? resource.get("id") : resource.id

    if (resourceId) {
      return await this.put(resourceId, resource);
    } else {
      return await this.post("", resource);
    }
  }

  buildUrl(route: string, params = {}): URL {
    const currentPage: URL = new URL(document.baseURI);
    const host: string = currentPage.host;
    const protocol: string = currentPage.protocol;

    const url: URL = new URL(this.truncateSlashes(`${protocol}//${host}/${this.baseRoute}/${route}`));
    Object.keys(params).forEach((key) => { url.searchParams.append(key, params[key]) });

    return url;
  }

  buildHref(route: string, params = {}): string {
    return this.buildUrl(route, params).href;
  }

  truncateSlashes(str: string): any {
    if(!str) return;

    return str.match(/^\/?(.*?)\/?$/)[1]
  }

}
