function ImpactIcon({ impact, sizeClass = "small", fill = true }) {

  function svg() {
    switch(impact.icon) {
      case "bike":
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" className={sizeClass}>
          <path d="M55.532 49.64A15.953 15.953 0 1 1 39.58 33.686 15.953 15.953 0 0 1 55.532 49.64zM39.58 37.356A12.283 12.283 0 1 0 51.862 49.64 12.283 12.283 0 0 0 39.58 37.356z" fill="#fff"/>
          <path d="M39.581 33.189a16.344 16.344 0 0 0-5.12.82l-6.36-17.6h8.3a5.245 5.245 0 1 1 0 10.49v1a6.245 6.245 0 1 0 0-12.49h-9.73l2.58 7.14H3.281c-.16.33-.3.66-.44 1h26.78l1.32 3.66-29.7 20.42c.08.35.18.69.28 1.03l29.77-20.47 2.23 6.17a16.422 16.422 0 1 0 6.06-1.17zm0 31.9a15.44 15.44 0 0 1-5.72-29.79l5.25 14.51.94-.34-5.25-14.52a15.45 15.45 0 1 1 4.78 30.14z" fill="#40434f"/>
        </svg>);
      case "bus":
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" className={sizeClass}>
          <path d="M1.66 26.87q.234-.769.501-1.523-.266.754-.502 1.523zM1.084 28.967zM0 37.996zM2.375 24.76q.257-.69.54-1.369-.283.678-.54 1.37zM.653 30.985q.133-.717.294-1.425-.16.708-.294 1.425zM3.082 22.983q.368-.854.777-1.685-.408.832-.777 1.685zM.278 33.399q.115-.956.278-1.897-.163.94-.278 1.897zM57.29 19.796c-1.47-3.81-2.48-4.68-6.97-4.68H7.66a37.903 37.903 0 0 0-3.724 6.03h6.503v15.42a2.507 2.507 0 0 1-2.5 2.5H.036a37.802 37.802 0 0 0 5.413 18.55h6.73a11.452 11.452 0 0 1-.23-2.28 11.205 11.205 0 1 1 22.41 0 11.447 11.447 0 0 1-.23 2.28h20.33c2.73 0 4.16-.97 5.03-4.16a43.366 43.366 0 0 0 1.17-9.52c.29-9.31-2.34-21.49-3.37-24.14zM34.267 36.564a2.507 2.507 0 0 1-2.5 2.5H17.694a2.507 2.507 0 0 1-2.5-2.5V21.151h19.074zm19.52 13.929a2.507 2.507 0 0 1-2.5 2.5h-9.672a2.507 2.507 0 0 1-2.5-2.5V21.151h14.672zM.093 35.422q.053-.789.137-1.569-.084.78-.137 1.57z" fill="#fff"/>
          <path d="M23.156 62.825a7.488 7.488 0 1 1 7.487-7.488 7.496 7.496 0 0 1-7.487 7.488zm0-13.976a6.488 6.488 0 1 0 6.487 6.488 6.495 6.495 0 0 0-6.487-6.488zM4.189 20.646c-.09.16-.18.33-.26.5s-.16.33-.24.5h42.26v31.35h1v-31.35h10.9v-1z" fill="#40434f"/>
        </svg>);
      case "choose":
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" className={sizeClass}>
          <path d="M59.969 24.9l-5.9 5.9-24.44 24.44-11.12 3.17 3.18-11.11 24.44-24.44 5.9-5.9a2.094 2.094 0 0 1 2.98 0l4.96 4.96a2.112 2.112 0 0 1 0 2.98z" fill="#f6f6f6"/>
          <path d="M30.037 56.354l-9.464-9.464.707-.707 9.464 9.464zM55.18 31.21l-9.463-9.464-.707.707 9.463 9.464zm2.275-2.275l-9.464-9.463-.707.707 9.464 9.463zm-7.643 1.979l-.707-.707L29.04 50.273l.707.707zm-3.036-3.036l-.707-.707-20.066 20.066.707.707z" fill="#40434f"/>
        </svg>);
      case "community":
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" className={sizeClass}>
          <path d="M45.7,21.6H23.6c-3.4,0-6.2,2.8-6.2,6.2v13.1c0,3.4,2.8,6.2,6.2,6.2H27v7.5l7.5-7.5h11.2 c3.4,0,6.2-2.8,6.2-6.2V27.8C51.9,24.4,49.1,21.6,45.7,21.6z" fill="none" stroke="#40434F"/>
          <path d="M43.3,37.1h14c2.2,0,3.9,1.8,3.9,3.9v8.3c0,2.2-1.8,3.9-3.9,3.9H55v4.7l-4.7-4.7h-7c-2.2,0-3.9-1.8-3.9-3.9V41 C39.4,38.8,41.1,37.1,43.3,37.1" fill="#fff"/>
        </svg>);
      case "energy":
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" className={sizeClass}>
          <path d="M33.051 59.327a1.351 1.351 0 0 0 1.351 1.35h7.178a1.351 1.351 0 0 0 1.351-1.35l-.19-12.69c.087-2.28 3.215-4.69 4.936-6.42A13.644 13.644 0 0 0 37.33 16.969a13.755 13.755 0 0 0-12.973 12.845 13.587 13.587 0 0 0 4.105 10.56c1.739 1.692 4.702 4.195 4.788 6.462z" fill="#fff"/>
          <path d="M31.83 53.69h12.34v1H31.83zm0 4.183h12.34v-1H31.83zm6.671-7.367v-16.42a.457.457 0 0 0-.02-.092h3.893v-1h-8.749v1h3.895a.473.473 0 0 0-.019.092v16.42h-5.67v1h12.34v-1z" fill="#40434f"/>
        </svg>);
      case "food":
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" className={sizeClass}>
          <path d="M38.02 27.782a3.834 3.834 0 0 1-2.706-1.098 8.78 8.78 0 0 0-6.541-2.788c-4.122.242-9.65 5.226-8.041 16.752 1.608 9.247 5.271 15.948 10.99 16.216 5.22.245 6.298-2.546 6.298-2.546z" fill="#fff"/>
          <path d="M43.68 57.38c-4.955 0-6.079-2.76-6.126-2.882l.933-.36c.04.095 1.06 2.46 5.809 2.227 4.98-.234 8.814-5.994 10.52-15.803.783-5.611-.129-10.35-2.57-13.328a7.26 7.26 0 0 0-5.007-2.84 8.372 8.372 0 0 0-6.16 2.645 4.284 4.284 0 0 1-2.559 1.216v.028h-1v-5.418a6.715 6.715 0 0 1 4.15-6.217l1.56-.649.384.924-1.561.649a5.717 5.717 0 0 0-3.533 5.293v4.381a3.282 3.282 0 0 0 1.855-.917 9.38 9.38 0 0 1 6.922-2.933A8.253 8.253 0 0 1 53.02 26.6c1.853 2.261 3.84 6.546 2.784 14.117-1.822 10.48-5.892 16.385-11.461 16.647-.228.01-.45.015-.663.015z" fill="#40434f"/>
        </svg>);
      case "foot":
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" className={sizeClass}>
          <path d="M51.839 41.22a8.624 8.624 0 0 1-7.51-4.609l-4.477-8.602a1.666 1.666 0 0 0-1.579-.92c-6.799.523-9.095-4.927-9.912-7.473a1.007 1.007 0 0 0-1.226-.64c-6.528 1.67-8.706 9.716-8.706 9.716l-2.714 3.076a1.275 1.275 0 0 0 .121 1.808l15.14 13.097a25.966 25.966 0 0 0 16.989 6.329h7.574a1.803 1.803 0 0 0 1.803-1.803v-2.388s.555-.495.522-2.377c-.033-1.89-.516-5.11-6.025-5.215z" fill="#fff"/>
          <path d="M37.757 29.85a1.132 1.132 0 1 1-1.586-.215 1.132 1.132 0 0 1 1.586.215zm.34 3.175a1.132 1.132 0 1 0 1.585.214 1.132 1.132 0 0 0-1.586-.214zm1.924 3.389a1.132 1.132 0 1 0 1.586.214 1.132 1.132 0 0 0-1.586-.214zm9.766 12.007c-.056-.001-.111-.01-.167-.01.1-2.405.594-5.517 2.286-7.767l.115-.15-.792-.61-.121.158c-1.84 2.446-2.378 5.757-2.486 8.306A25.45 25.45 0 0 1 33.24 41.92l-9.968-8.987a8.98 8.98 0 0 0-1.194-12.674l-.637.771a7.978 7.978 0 0 1 1.087 11.232l-4.862-4.383-.67.743L32.57 42.664a26.475 26.475 0 0 0 17.2 6.757l8.454.125.014-1zm-21.08 2.827H9.746v1h18.96zm-7.169-5.417H9.747v1h11.791zm-4.511-5.416h-7.28v1h7.28z" fill="#40434f"/>
        </svg>);
      case "health":
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" className={sizeClass}>
          <path d="M56.341 33.14a12.657 12.657 0 0 1-2.93 3.84q-1.845 1.786-3.72 3.55l-2.32.96a3.513 3.513 0 0 0 .12-.87 3.188 3.188 0 0 0-3.18-3.18h-3.33c-.59-.59-1.17-1.17-1.74-1.77a8.77 8.77 0 0 1-2.32-4.33 5.572 5.572 0 0 1 4.31-6.639 5.247 5.247 0 0 1 5.2 2.15c.09.11.16.22.24.33s.16.21.27.37a6.292 6.292 0 0 1 2.77-2.5 5.22 5.22 0 0 1 6.93 2.96 6.103 6.103 0 0 1-.3 5.13z" fill="#fff"/>
          <path d="M64.611 39.761a3.337 3.337 0 0 1-1.64 2.45l-15.1 8.7a6.897 6.897 0 0 1-3.4.91h-17.57L9.881 63.56c-.23-.25-.45-.5-.67-.76l17.38-11.98h17.88a5.81 5.81 0 0 0 2.9-.78l15.1-8.7a2.336 2.336 0 0 0-2.11-4.16l-12.72 5.28-.98.41-.18.07a3.15 3.15 0 0 1-2.17.86h-11.42v-1h11.32l1.65-.68.05-.02a2.18 2.18 0 0 0-1.6-3.66h-18.55a5.843 5.843 0 0 0-2.6.61l-.23-.44.22.45-21.38 10.43c-.1-.32-.2-.65-.29-.97l21.23-10.36a6.873 6.873 0 0 1 3.05-.72h18.55a3.188 3.188 0 0 1 3.18 3.18 3.513 3.513 0 0 1-.12.87l2.32-.96 10.28-4.26a3.33 3.33 0 0 1 4.64 3.49z" fill="#40434f"/>
        </svg>);
      case "materials":
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" className={sizeClass}>
          <path d="M42.89 14.444l6.92 11.932-1.712 5.445-5.583-1.199-9.422-16.178zm18.015 31.237l-18.722.033-3.838 4.228 3.851 4.213 13.793.001zm-40.946 8.45l9.317-16.238-1.747-5.437-5.573 1.235-6.887 11.95z" fill="#fff"/>
          <path d="M56.277 54.656l-12.98-.003 4.14 4.56-.74.672-9.029-9.943 9.064-9.914.738.675-8.449 9.24 3.368 3.71 13.312.003 4.626-7.976-6.553-11.385.866-.5 6.842 11.887zm-36.029-1.025l-4.602-7.99 6.649-11.532 4.895-1.067 3.788 11.934.954-.303-4.064-12.802-13.124 2.86.213.977 6.018-1.312-6.483 11.245 5.178 8.99 13.714.006v-1zm31.307-34.327l-1.867 5.87-6.51-11.23H32.805l-6.849 11.882.866.5 6.56-11.382h9.221l6.676 11.517-1.518 4.774-12.232-2.672-.214.977 13.122 2.867 4.072-12.8z" fill="#40434f"/>
        </svg>);
      case "money":
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" className={sizeClass}>
          <path d="M36.9 46.2v-6.7c0-1.1-.3-1.7-1.5-1.9-2.4-.5-4.7-1.5-6.7-2.7-4.9-2.8-6-10.2-2.1-14.7 1.7-2.1 4-3.5 6.6-4.1.6-.1 1.2-.3 1.9-.4 1.2-.2 2-.6 1.8-2-.2-1.3.6-1.6 1.8-1.6 1.1 0 1.6.5 1.5 1.5-.2 1.6.6 2.2 2.2 2.4 3.2.4 5.8 1.9 8.3 3.8.9.6 1 1.2.2 2.1-.7.7-1.1 1.5-2.2.5-2.1-1.7-4.4-2.9-7-3.5-1-.2-1.4 0-1.4 1v14c0 1.2.6 1.6 1.7 1.9 1.8.5 3.6 1.2 5.3 1.9 7 3.1 7 11.2 3.6 15.6-1.3 1.6-3 2.7-4.9 3.5-.7.3-1.4.4-2.1.7-1.2.4-2.8.1-3.5 1.2-.5.9-.2 2.2-.2 3.4-.1 1.7-.5 2-2.1 1.8-.6 0-1.1-.4-1.1-1v-.2-2.2c-.1-2.4-.1-2.4-2.4-2.9-4.1-.7-7.5-2.6-10.8-5.2-1-.9-.9-1.5-.1-2.4.7-.7 1.3-1 2.1-.2.8.7 1.6 1.3 2.5 1.9 1.3 1 2.9 1.8 4.5 2.3 4.4 1.1 4.1 1.8 4.1-3v-4.8zm3.1.8v6.7c0 1 .5 1.4 1.4 1.2 2.7-.4 5.2-1.4 6.8-3.6 2.4-3.2 2.1-8.4-2.7-10.7-1.3-.5-2.6-1-3.8-1.5-1-.3-1.6 0-1.6 1.2-.1 2.2-.1 4.4-.1 6.7h0zm-3.1-20.4V20c0-1.2-.6-1.5-1.6-1.3-2.2.4-4.3 1.2-5.8 2.9-2.7 2.7-2.7 7.2.1 9.8.3.3.7.6 1 .9 1.5.9 3.2 1.6 4.9 2.1 1 .3 1.4-.1 1.4-1.1v-6.7z" fill="#fff" stroke="#231f20"/>
        </svg>);
      case "nature":
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" className={sizeClass}>
          <path d="M38.501 56.037v19.95c-.17.01-.33.01-.5.01s-.33 0-.5-.01v-19.95z" fill="#40434f"/>
          <path d="M38.001 24.997l-11.34 13.68h22.68zm0-12.81l-9.46 11.41h18.91zm13.22 43.85h-26.45l13.23-15.96z" fill="#fff"/>
        </svg>);
      case "simplicity":
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" className={sizeClass}>
          <path d="M52.071 44.93h-28.04a14.029 14.029 0 0 1 28.04 0z" fill="#fff"/>
          <path d="M37.551 18.56v8.27h1v-8.27zm19.66 7.72l-5.85 5.85.71.71 5.85-5.85zm-38.31 0l-.71.71 5.85 5.85.71-.71zm30.69-9.9l-4.74 11.68.93.37 4.74-11.67zm17.67 16.88l-11.61 4.9.39.93 11.6-4.91zm-58.6.42l-.37.93 11.67 4.74.38-.93zm17.42-17.13l-.92.39 4.91 11.61.92-.39zm49.29 28.38c-.06.34-.13.67-.2 1H.831c-.07-.33-.14-.66-.2-1z" fill="#40434f"/>
        </svg>);
      case "smokestack":
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" className={sizeClass}>
          <path d="M72.719 22.527a10.116 10.116 0 0 1-12.04 3.09 10.126 10.126 0 0 1-14.63 4.73 8.828 8.828 0 0 0-4.6 7.72h-7.85a16.692 16.692 0 0 1 7.77-14.09 9.992 9.992 0 0 1 3.84-10.53 10.297 10.297 0 0 1-.91-4.19 10.18 10.18 0 0 1 3.76-7.91 38.096 38.096 0 0 1 24.66 21.18z" fill="#fff"/>
          <path d="M44.819 37.557h-14.98l-2.49 36.92c.32.1.65.19.98.27l1.69-25.1h14.61l1.72 25.43c.33-.07.66-.15.98-.24zm-14.73 11.09l.68-10.09h13.12l.68 10.09z" fill="#2f323c"/>
        </svg>);
      case "vehicle":
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" className={sizeClass}>
          <path className="cls-2" d="M55.185 33.639l-2.202-8.848c-.628-2.533-1.569-4.103-4.308-4.103h-20.53c-2.74 0-3.68 1.57-4.308 4.103l-2.202 8.848-.97-.242 2.201-8.848c.627-2.525 1.7-4.861 5.28-4.861h20.53c3.578 0 4.651 2.336 5.278 4.861l2.202 8.848zm1.554 16.083v4.337a3.517 3.517 0 0 1-1.793 3.053v-7.39h-1v7.786a3.518 3.518 0 0 1-.736.08h-.116a3.518 3.518 0 0 1-.735-.08v-7.786h-1v7.39a3.517 3.517 0 0 1-1.793-3.053v-4.337h-1v4.337a4.533 4.533 0 0 0 4.528 4.528h.116a4.533 4.533 0 0 0 4.529-4.528v-4.337zm-29.485 0v4.337a3.518 3.518 0 0 1-1.904 3.114v-7.451h-1v7.802a3.511 3.511 0 0 1-.624.063h-.116a3.49 3.49 0 0 1-.847-.114v-7.751h-1v7.33a3.52 3.52 0 0 1-1.682-2.993v-4.337h-1v4.337a4.533 4.533 0 0 0 4.529 4.528h.116a4.533 4.533 0 0 0 4.528-4.528v-4.337z"/>
          <path d="M54.647 33.018H22.174a5.912 5.912 0 0 0-5.905 5.905v11.299h44.283v-11.3a5.912 5.912 0 0 0-5.905-5.904z" fill="#fff"/>
          <path className="cls-2" d="M23.067 43.685a3.858 3.858 0 1 1 3.858-3.858 3.862 3.862 0 0 1-3.858 3.858zm0-6.716a2.858 2.858 0 1 0 2.858 2.858 2.862 2.862 0 0 0-2.858-2.858zm30.686 6.716a3.858 3.858 0 1 1 3.859-3.858 3.862 3.862 0 0 1-3.859 3.858zm0-6.716a2.858 2.858 0 1 0 2.859 2.858 2.861 2.861 0 0 0-2.859-2.858zm-8.18 1.098H31.247v1h14.326zm0 3.053H31.247v1h14.326zm0 3.052H31.247v1h14.326z"/>
        </svg>);
      case "water":
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" className={sizeClass}>
          <path d="M44.903 49.566a.978.978 0 1 1-1.383 0 .978.978 0 0 1 1.383 0zm3.32-4.704a.978.978 0 1 0 1.384 0 .978.978 0 0 0-1.384 0zm4.704-4.703a.978.978 0 1 0 1.383 0 .978.978 0 0 0-1.383 0zm4.703-4.703a.978.978 0 1 0 1.383 0 .978.978 0 0 0-1.383 0zm-17.707 1.106a.978.978 0 1 0 1.384 0 .978.978 0 0 0-1.384 0zm4.704-4.703a.978.978 0 1 0 1.383 0 .978.978 0 0 0-1.383 0zm-2.905 11.205a.978.978 0 1 0 1.383 0 .978.978 0 0 0-1.383 0zm4.703-4.703a.978.978 0 1 0 1.383 0 .978.978 0 0 0-1.383 0zm4.703-4.704a.978.978 0 1 0 1.384 0 .978.978 0 0 0-1.384 0zm-27.807-6.481a9.311 9.311 0 0 0-2.75 6.63v37.96c.33.18.66.35 1 .5v-38.46a8.37 8.37 0 0 1 12.09-7.5l.73-.76a9.375 9.375 0 0 0-11.07 1.63z" fill="#40434f"/>
          <path fill="#f6f6f6" d="M45.201 24.966l-12.15 12.14-3.53-6.5 4.14-4.3.73-.76 3.96-4.12 6.85 3.54z"/>
        </svg>);
      default:
        return (<svg xmlns="http://www.w3.org/2000/svg" className={sizeClass} viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
        </svg>);
    };
  }

  function wrap(toWrap) {
    if(fill) return toWrap;

    return (
      <>
        <svg className="border" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46">
          <circle r="22.5" cx="23" cy="23" fill="none"></circle>
        </svg>
        {toWrap}
      </>
    )
  }

  function icon() {
    if(impact.icon == "custom" && impact.custom_icon_url) {
      return wrap(customIconTag(impact))
    } else {
      return wrap(svg(impact.icon));
    }
  }

  function customIconTag(impact: Object) {
    return(
      <img src={impact.custom_icon_url} className={sizeClass} />
    )
  } 

  const color = fill ? impact.color : "";

  return(
    <div className={`impact-icon-container ${color} ${sizeClass}`}>
      <div className="icon">
        {icon(impact)}
      </div>
    </div>
  )

}

export default ImpactIcon;
