import { Combobox } from "@headlessui/react";
import CheckmarkSvg from "../../icons/CheckmarkSvg";
import { useCallback } from "react";

export type TypeaheadOption = {
  label: string;
  value: string | number | null;
};

type TypeaheadInputProps = Pick<
  React.ComponentProps<typeof Combobox.Input>,
  "displayValue"
>;

type Props<T extends TypeaheadOption> = {
  id: string;
  placeholder?: string;
  options: T[] | [];
  onInputChange: (q: string) => void;
  value: T | null;
  createPrefix?: string;
  allowClear?: boolean;
  onChange: (o: T | null) => void;
  required?: boolean;
} & TypeaheadInputProps;

export default function Typeahead<T extends TypeaheadOption>({
  id,
  placeholder,
  options = [],
  onInputChange,
  displayValue,
  value = null,
  createPrefix = "Create",
  allowClear = false,
  onChange,
  required = false
}: Props<T>) {
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      onInputChange(event.target.value),
    [onInputChange]
  );

  return (
    <Combobox
      as="div"
      className="typeahead"
      value={value}
      onChange={onChange}
      nullable
    >
      <div className="typeahead-select">
        <Combobox.Input
          id={id}
          className="typeahead-select-input"
          onChange={handleInputChange}
          displayValue={displayValue}
          placeholder={placeholder}
          required={required}
        />
        <Combobox.Button className="typeahead-select-button">
          <span className="screen-reader-text">Open</span>
        </Combobox.Button>
      </div>
      <div className="typeahead-dropdown">
        <Combobox.Options className="typeahead-dropdown-list">
          {allowClear &&
            <Combobox.Option
              key={"clear"}
              value=""
              className="typeahead-dropdown-item"
            >
              <span className="italic">None</span>
            </Combobox.Option>
          }
          {options.map((o) => (
            <Combobox.Option
              key={o.value}
              value={o}
              className="typeahead-dropdown-item"
            >
              {value?.value === o.value && <CheckmarkSvg />}
              {!o.value ? (
                <span className="italic">
                  {createPrefix} "{o.label}"
                </span>
              ) : (
                <span>{o.label}</span>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}
