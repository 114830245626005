import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";

export default class EcoChallengeSidebar {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;
    this.mobilizer = this.mobilizer.bind(this);
    this.windowWidth = window.innerWidth;
  }

  bindListeners() {
    document.addEventListener('turbo:load', this.mobilizer);
  }

  mobilizer() {
    if (!document.querySelector('#dashboard-sidebar')) {
      return;
    }

    if (window.innerWidth <= 768) {
      this.dh.addClass(document.querySelector('#dashboard-sidebar'), 'closed');
    }

    window.addEventListener('resize', this.dh.debounce(() => {
      const windowWidth = window.innerWidth;
      const sidebarEl = document.querySelector('#dashboard-sidebar');

      if (!sidebarEl) return;
      // Only check on resize if Window Width has Changed
      if (windowWidth != this.windowWidth) {
        if (windowWidth <= 768) {
          this.dh.addClass(sidebarEl, 'closed');
        } else {
          this.dh.removeClass(sidebarEl, 'closed');
        }
      }
    }, 200));
  }

};
