import React from "react";
import { createRoot } from "react-dom/client";

import ImpactIcon from "./ImpactTypes/ImpactIcon";
import SortableImpactList from "./ImpactTypes/SortableImpactTypeList";

import CheckInDays from "./CheckInDays";
import OrganizationTypeahead from "./OrganizationTypeahead";

import { Filters, Pagination } from "./feed";

const components = {
  ImpactIcon,
  SortableImpactList,
  CheckInDays,
  Filters,
  Pagination,
  OrganizationTypeahead,
};

export default class ReactRenderer {
  bindListeners() {
    document.addEventListener(
      "turbo:load",
      this.renderComponents.bind(this)
    );
  }

  renderComponents = () => {
    document.querySelectorAll("[data-react-component]").forEach((node) => {
      this.renderComponent(node);
    });
  };

  renderComponent(node) {
    const componentName = node.dataset["reactComponent"];
    const Component = components[componentName];
    if (!Component) return;

    const propsString = node.dataset["reactProps"];
    const props = propsString ? JSON.parse(propsString) : {};

    createRoot(node).render(<Component {...props}></Component>);
  }
}
