import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";

export default class Posts {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;
    this.postCreateUrl = "/dashboards/posts";
    this.postDestroyUrl = _.template("/dashboards/posts/<%= id %>.json");
    this.reflection = null;
    this.mediasCount = 0;

    this.handlePostSubmit = this.handlePostSubmit.bind(this);
    this.handleCommentSubmit = this.handleCommentSubmit.bind(this);
    this.handlePostDestroy = this.handlePostDestroy.bind(this);
    this.handleLoadMorePosts = this.handleLoadMorePosts.bind(this);
    this.handleFlagPost = this.handleFlagPost.bind(this);
    this.handleDeflagPost = this.handleDeflagPost.bind(this);
    this.handleCommentDestroy = this.handleCommentDestroy.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handlePostBodyClick = this.handlePostBodyClick.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleUploadSelect = this.handleUploadSelect.bind(this);
    this.handleUploadChange = this.handleUploadChange.bind(this);
    this.handleShowPostReply = this.handleShowPostReply.bind(this);
    this.handleHighFiveClick = this.handleHighFiveClick.bind(this);
    this.handleReadMoreClick = this.handleReadMoreClick.bind(this);

    this.displayNewPost = this.displayNewPost.bind(this);
    this.displayNewComment = this.displayNewComment.bind(this);
    this.displayPostError = this.displayPostError.bind(this);
    this.removeDestroyedPost = this.removeDestroyedPost.bind(this);
    this.displayFetchedPosts = this.displayFetchedPosts.bind(this);
    this.showPostAsDeflagged = this.showPostAsDeflagged.bind(this);
    this.showPostAsFlagged = this.showPostAsFlagged.bind(this);
    this.showPostUnHighFived = this.showPostUnHighFived.bind(this);
    this.showPostHighFived = this.showPostHighFived.bind(this);
    this.displayPostSuccessMessage = this.displayPostSuccessMessage.bind(this);
    this.displayDestroyPostError = this.displayDestroyPostError.bind(this);
    this.emitReflectionSuccessEvent = this.emitReflectionSuccessEvent.bind(this);
    this.removeDestroyedComment = this.removeDestroyedComment.bind(this);

    this.feedElementForNode = this.feedElementForNode.bind(this);

    this.doCreatePost = this.doCreatePost.bind(this);
    this.doLoadMorePosts = this.doLoadMorePosts.bind(this);
    this.doDestroyPost = this.doDestroyPost.bind(this);
    this.doAttachmentUpload = this.doAttachmentUpload.bind(this);

    this.collapsePosts = this.collapsePosts.bind(this);
  }

  bindListeners() {
    document.addEventListener('submit', this.handlePostDestroy);
    document.addEventListener('submit', this.handlePostSubmit);
    document.addEventListener('submit', this.handleCommentDestroy);
    document.addEventListener('submit', this.handleCommentSubmit);
    document.addEventListener('click', this.handleLoadMorePosts);
    document.addEventListener('click', this.handleFlagPost);
    document.addEventListener('click', this.handleDeflagPost);
    document.addEventListener('click', this.handleReadMoreClick);
    document.addEventListener('click', this.handlePostBodyClick);
    document.addEventListener('click', this.handleUploadSelect);
    document.addEventListener('click', this.handleShowPostReply);
    document.addEventListener('click', this.handleHighFiveClick);
    document.addEventListener('trix-attachment-add', this.handleUpload);
    document.addEventListener('trix-change', this.handleEditorChange);
    document.addEventListener('change', this.handleUploadChange);
    document.addEventListener('turbo:load', this.collapsePosts);
    window.addEventListener('load', this.collapsePosts);
  }

  collapsePosts() {
    const bodies = document.querySelectorAll("[data-post-body]");
    [...bodies].forEach(body => {
      const height = body.clientHeight;
      if (height <= 120) return null;

      this.truncatePost(body);
    })
  }

  truncatePost(el) {
    const post = this.dh.closest(el, "[data-post-copy]");
    const readMore = post.querySelector("[data-post-read-more]");

    el.setAttribute("class", "body truncated");
    readMore.setAttribute("style", "display: inline-block;");
  }

  handleShowPostReply(event) {
    if (!this.canHandleShowPostReply(event)) return;
    const parent = this.dh.closest(event.target, '[data-post-reply-container]');
    this.dh.addClass(parent, 'panel-visible');
  }

  /*
   * Event handlers
   */
  handleUpload(event) {
    const attachment = event.attachment;
    if (attachment.file) {
      return this.doAttachmentUpload(attachment);
    }
  }

  handleUploadChange(event) {
    if (!this.canHandlePostUpload(event)) return;
    const input = event.target;
    const file = input.files[0];
    const form = this.dh.closest(input, 'form');
    const editorElement = form.querySelector('trix-editor');
    input.value = "";
    editorElement.editor.insertFile(file);
  }

  handleUploadSelect(event) {
    if (!this.canHandleUploadSelect(event)) return;
    event.preventDefault();
    const uploadInput = event.target.previousElementSibling;
    uploadInput.click();
  }

  handlePostBodyClick(event) {
    if (this.canHandleReadMore(event)) return null;
    const link = this.dh.closest(event.target, 'a');
    if (link && this.dh.closest(link, '[data-post-copy]')) {
      event.preventDefault();
      event.stopPropagation();
      window.open(link.getAttribute('href'));
    }
  }

  handleReadMoreClick(event) {
    if (!this.canHandleReadMore(event)) return null;
    event.preventDefault();
    event.stopPropagation();
    const trigger = event.target;
    const post = this.dh.closest(event.target, "[data-post-copy]");
    const body = post.querySelector("[data-post-body]");
    body.setAttribute("class", "body");
    trigger.setAttribute("style", "display: none;");
  }

  handleEditorChange(event) {
    this.hideAssociatedMessages(event.target);
  }

  handleFlagPost(event) {
    if (!this.canHandleFlagPost(event)) return;
    const feedElement = this.feedElementForNode(event.target);
    const payload = {
      title:  event.target.dataset.title ? event.target.dataset.title : "Is this post abusive or inappropriate?",
      body: event.target.dataset.body ? event.target.dataset.body : "If this post is inappropriate, please let us know. Flagged posts are regularly reviewed by the Ecochallenge team.",
      yayLabel: event.target.dataset.yayLabel ? event.target.dataset.yayLabel : "Yes",
      nayLabel: event.target.dataset.nayLabel ? event.target.dataset.nayLabel : "No",
      onYay: () => { this.doFlagPost(event.target, feedElement) }
    }
    document.dispatchEvent(new CustomEvent('showModal', { detail: payload }));
    event.preventDefault();
  }

  handleHighFiveClick(event) {
    if (!this.canHandleHighFivePost(event)) return null;
    event.preventDefault();
    this.doHighFivePost(event.target);
  }

  handleDeflagPost(event) {
    if (!this.canHandleDeflagPost(event)) return;
    const feedElement = this.feedElementForNode(event.target);
    this.doDeflagPost(event.target, feedElement);
    event.preventDefault();
  }

  handleLoadMorePosts(event) {
    if (!this.canHandleLoadMorePosts(event)) return;
    const feedElement = this.feedElementForNode(event.target);
    this.doLoadMorePosts(feedElement);
    event.preventDefault();
  }

  handleCommentDestroy(event) {
    if (!this.canHandleCommentDestroy(event)) return
    const commentContainer = this.findCommentContainer(event.target);
    const payload = {
      title:  event.target.dataset.title ? event.target.dataset.title : "Are you sure?",
      body: event.target.dataset.body ? event.target.dataset.body : "This action cannot be undone. Please confirm that you would like to delete this comment.",
      yayLabel: event.target.dataset.yayLabel ? event.target.dataset.yayLabel : "CONFIRM",
      nayLabel: event.target.dataset.nayLabel ? event.target.dataset.nayLabel : "CANCEL",
      onYay: () => { this.doDestroyComment(event.target, commentContainer) }
    }
    document.dispatchEvent(new CustomEvent('showModal', { detail: payload }));
    event.preventDefault();
  }

  handlePostDestroy(event) {
    if (!this.canHandlePostDestroy(event)) return
    const feedElement = this.feedElementForNode(event.target);
    const payload = {
      title:  event.target.dataset.title ? event.target.dataset.title : "Are you sure?",
      body: event.target.dataset.body ? event.target.dataset.body : "This action cannot be undone. Please confirm that you would like to delete this post.",
      yayLabel: event.target.dataset.yayLabel ? event.target.dataset.yayLabel : "CONFIRM",
      nayLabel: event.target.dataset.nayLabel ? event.target.dataset.nayLabel : "CANCEL",
      onYay: () => { this.doDestroyPost(event.target, feedElement) }
    }
    document.dispatchEvent(new CustomEvent('showModal', { detail: payload }));
    event.preventDefault();
  }

  handlePostSubmit(event) {
    if (!this.canHandlePostSubmit(event)) return;
    this.reflection = _.has(event.target.dataset, "reflection");
    const feedElement = this.feedElementForNode(event.target);
    this.doCreatePost(new FormData(event.target), feedElement);
    event.preventDefault();
  }

  handleCommentSubmit(event) {
    if (!this.canHandleCommentSubmit(event)) return
    const feedElement = this.feedElementForNode(event.target);
    this.doCreateComment(event.target, feedElement)
    event.preventDefault();
  }

  /*
   * UI updates
   */

  displayNewComment(json, formElement, feedElement) {
    const postContainer = this.dh.closest(formElement, "[data-post-parent]");
    const commentContainer = postContainer.querySelector("[data-comment-container]");
    const actions = postContainer.querySelector("[data-post-reply-container]");
    const replyButton = actions.querySelector("[data-show-reply]");
    const { html, replyCount } = json;
    this.makeButtonBadge(replyButton, replyCount);

    const newComment = this.postStringToNode(html);
    this.dh.addClass(replyButton, 'active');
    this.dh.addClass(newComment, 'faded');
    this.dh.removeClass(actions, 'panel-visible');
    commentContainer.insertBefore(newComment, commentContainer.children[0]);
    setTimeout(() => {
      this.dh.removeClass(newComment, 'faded');
    }, 0)
  }

  displayNewPost(json, feedElement) {
    const postContainer = this.findPostContainer(feedElement);
    if (!postContainer) return;
    const newPost = this.postStringToNode(json.html);
    this.dh.addClass(newPost, 'faded');
    postContainer.insertBefore(newPost, postContainer.firstChild);
    setTimeout(() => {
      this.dh.removeClass(newPost, 'faded');
    }, 0)
  }

  redirectToFeed() {
    window.location = "/dashboards/posts"
  }

  displayFetchedPosts(json, feedElement) {
    const postContainer = this.findPostContainer(feedElement);
    const newPosts = this.postStringToNodes(json.html);
    [...newPosts].forEach((post) => {
      this.appendPost(postContainer, post);
    });
    if (!json.hasNextPage) {
      this.hideLoadMoreLink(feedElement);
    }
  }

  appendPost(postContainer, post) {
    if (!(post instanceof Element)) return null;
    const postId = post.dataset.post;
    if (postContainer.querySelector(`[data-post='${postId}']`)) return null;

    postContainer.appendChild(post);
  }

  hideLoadMoreLink(feedElement) {
    const el = this.findLoadMoreLink(feedElement);
    if (el) {
      el.style.display = 'none';
    }
  }

  hideAssociatedMessages(editor) {
    [...this.dh.closest(editor, 'form').querySelectorAll('[data-editor-message]')].forEach((messageContainer) => {
      if (!messageContainer.classList.contains('faded') && editor.value != "" ) {
        this.dh.addClass(messageContainer, 'faded');
        this.dh.removeClass(messageContainer, "error");
      }
    });
  }

  removeDestroyedPost(response, postElement, feedElement) {
    if (postElement.parentNode) {
      postElement.parentNode.removeChild(postElement);
    }
  }

  removeDestroyedComment(json, commentElement, commentContainer) {
    const postContainer = commentContainer.parentNode;
    const actions = postContainer.querySelector("[data-post-reply-container]");
    const replyButton = actions.querySelector("[data-show-reply]");
    const { replyCount, participantReplyCount } = json;
    if (participantReplyCount === 0) this.dh.removeClass(replyButton, "active");
    this.makeButtonBadge(replyButton, replyCount);

    if (commentElement.parentNode) {
      commentElement.parentNode.removeChild(commentElement);
    }
  }

  resetPostInterface(promiseChainResults, feedElement) {
    feedElement.querySelector('trix-editor').value = "";
  }

  resetCommentInterface(promiseChainResults, formElement, feedElement) {
    formElement.querySelector('trix-editor').value = "";
  }

  showPostAsFlagged(promiseChainResults, flagLinkElement, feedElement) {
    this.dh.addClass(flagLinkElement, "flagged");
    flagLinkElement.setAttribute("href", flagLinkElement.getAttribute('href').replace("/flag", "/deflag"));
    flagLinkElement.removeAttribute("data-flag-post");
    flagLinkElement.setAttribute("data-deflag-post", true);
  }

  showPostAsDeflagged(promiseChainResults, flagLinkElement, feedElement) {
    this.dh.removeClass(flagLinkElement, "flagged");
    flagLinkElement.setAttribute("href", flagLinkElement.getAttribute('href').replace("/deflag", "/flag"));
    flagLinkElement.removeAttribute("data-deflag-post");
    flagLinkElement.setAttribute("data-flag-post", true);
  }

  showPostUnHighFived(promiseChainResults, highFiveLinkElement, feedElementIgnored) {
    this.dh.removeClass(highFiveLinkElement, "active");
    const { count } = promiseChainResults;
    highFiveLinkElement.setAttribute("data-high-five-url", highFiveLinkElement.dataset.highFiveUrl.replace(/high_fives\/.+$/, "high_fives"));
    highFiveLinkElement.removeAttribute("data-un-high-five-post");
    highFiveLinkElement.setAttribute("data-high-five-post", true);
    this.makeButtonBadge(highFiveLinkElement, count);
  }

  showPostHighFived(promiseChainResults, highFiveLinkElement, feedElementIgnored) {
    this.dh.addClass(highFiveLinkElement, "active");
    const { id, count } = promiseChainResults;
    highFiveLinkElement.setAttribute("data-high-five-url", highFiveLinkElement.dataset.highFiveUrl + `/${id}`);
    highFiveLinkElement.removeAttribute("data-high-five-post");
    highFiveLinkElement.setAttribute("data-un-high-five-post", true);
    this.makeButtonBadge(highFiveLinkElement, count);
  }

  makeButtonBadge(button, count) {
    const el = button;
    let badge = el.querySelector(".badge");
    if (count === 0 && badge) {
      el.removeChild(badge);
      return el;
    }
    if (count > 0) {
      badge = badge || document.createElement("span");
      badge.setAttribute("class", "badge");
      badge.innerHTML = count;
    }

    el.appendChild(badge);

    return el;
  }

  displayDestroyPostError(error, postContainer) {
    const messageContainer = postContainer.querySelector('[data-message]')
    messageContainer.innerHTML = "Error: Unable to delete this post."
    messageContainer.style.display = '';
 }

  displayDestroyCommentError(error, commentContainer) {
    const messageContainer = commentContainer.querySelector('[data-message]')
    messageContainer.innerHTML = "Error: Unable to delete this comment."
    messageContainer.style.display = '';
  }

  displayPostError(response, feedElement) {
    const postCreateFormMessage = this.findPostCreateFormMessage(feedElement);
    if (!postCreateFormMessage) return null;
    response.json().then((error) => {
      postCreateFormMessage.innerHTML = error.join(". ");
      this.dh.addClass(postCreateFormMessage, "error");
      this.dh.removeClass(postCreateFormMessage, "faded");
    })
  }

  displayFetchedPostsError(error, feedElement) {
    // TODO: Error handling on displayFetchedPosts
    console.log(error, 'error caught by displayFetchedPostsError');
  }

  showFlagPostError(error, postContainer) {
    const messageContainer = postContainer.querySelector('[data-message]');
    messageContainer.innerHTML = "Error: Unable to flag this post.";
    messageContainer.style.display = '';
  }

  showDeflagPostError(error, postContainer) {
    const messageContainer = postContainer.querySelector('[data-message]');
    messageContainer.innerHTML = "Error: Unable to deflag this post.";
    messageContainer.style.display = '';
  }

  showHighFiveError(error, postContainer) {
    const messageContainer = postContainer.querySelector('[data-message]');
    messageContainer.innerHTML = "Error: Unable to high-five this post.";
    messageContainer.style.display = '';
  }

  displayPostSuccessMessage(promisePayload, feedElement) {
    const postCreateFormMessage = this.findPostCreateFormMessage(feedElement);
    postCreateFormMessage.innerHTML = "The post has been added to your feed.";
    this.dh.removeClass(postCreateFormMessage, "faded");
  }

  hidePointsMessage(promisePayload, feedElement) {
    const pointsMessageContainer = feedElement.querySelector('.points-label');
    if(pointsMessageContainer) pointsMessageContainer.innerHTML = "";
  }

  emitReflectionSuccessEvent() {
    if (!this.reflection) return;
    document.dispatchEvent(new CustomEvent('reflectionSuccess'));
  }

  /*
   * Do the things
   */

  // We need to use XHR instead of fetch because fetch doesn't give us the progress callbacks we need for file upload.
  doAttachmentUpload(attachment) {
    const file = attachment.file
    const key = this.createStorageKey(file);
    const url = "/me/media"
    const form = new FormData;
    const formElement = document.getElementById("post-form");
    form.append("key", key);
    form.append("Content-Type", file.type);
    form.append("file", file);
    const xhr = new XMLHttpRequest;
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-CSRF-Token", document.querySelector('meta[name="csrf-token"]').getAttribute('content'));
    xhr.upload.onprogress = function(event) {
      var progress;
      progress = event.loaded / event.total * 100;
      return attachment.setUploadProgress(progress);
    };
    xhr.onload = () => {
      if (xhr.status === 201) {
        const media = JSON.parse(xhr.response);
        const attributes = {
          url: location.protocol + "//" + location.hostname + media.url,
          href: media.url
        }
        const mediaInput = document.createElement("input");
        mediaInput.type = "hidden";
        mediaInput.name = `post[medias_attributes][${media.id}][id]`
        mediaInput.value = media.id
        formElement.appendChild(mediaInput);
        return attachment.setAttributes(attributes);
      }
    };
    return xhr.send(form);
  }

  doCreatePost(data, feedElement) {
    fetch(this.postCreateUrl, this.fetchInit('POST', data))
        .then(this.checkForSuccessfulResponse)
        .then(this.parseJSON)
        .then(this.curryCallback(this.displayNewPost, feedElement))
        .then(this.curryCallback(this.emitReflectionSuccessEvent))
        .then(this.curryCallback(this.resetPostInterface, feedElement))
        .then(this.curryCallback(this.displayPostSuccessMessage, feedElement))
        .then(this.curryCallback(this.hidePointsMessage, feedElement))
        .catch(this.curryCallback(this.displayPostError, feedElement));
  }

  doCreateComment(formElement, feedElement) {
    const url = formElement.getAttribute("action") + ".json";
    fetch(url, this.fetchInit('POST', new FormData(formElement)))
        .then(this.checkForSuccessfulResponse)
        .then(this.parseJSON)
        .then(this.curryCallback(this.displayNewComment, formElement, feedElement))
        .then(this.curryCallback(this.resetCommentInterface,formElement, feedElement))
        .catch(this.curryCallback(this.displayPostError, feedElement));
  }

  doDestroyPost(deleteFormEl, feedElement) {
    const url = deleteFormEl.getAttribute("action");
    const isDeleteRedirect = _.has(deleteFormEl.dataset, 'deleteRedirect');
    const adjustedUrl = url + ".json";
    const postEl = this.dh.closest(deleteFormEl, "[data-post]");
    fetch(adjustedUrl, this.fetchInit('DELETE'))
        .then(this.checkForSuccessfulResponse)
        .then(this.curryCallback(this.removeDestroyedPost, postEl, feedElement))
        .then(() => {
          if (isDeleteRedirect) {
            window.location = deleteFormEl.dataset.deleteRedirect
          }
        })
        .catch(this.curryCallback(this.displayDestroyPostError, postEl, feedElement));
  }

  doDestroyComment(deleteFormEl, commentContainer) {
    const url = deleteFormEl.getAttribute("action");
    const adjustedUrl = url + ".json";
    const commentEl = this.dh.closest(deleteFormEl, "[data-post]");
    fetch(adjustedUrl, this.fetchInit('DELETE'))
        .then(this.checkForSuccessfulResponse)
        .then(this.parseJSON)
        .then(this.curryCallback(this.removeDestroyedComment, commentEl , commentContainer))
        .catch(this.curryCallback(this.displayDestroyCommentError, commentEl , commentContainer));
  }

  checkForSuccessfulResponse(response) {
    if ( response.status >= 200 && response.status < 300 || response.status === 304 ) {
      return response;
    } else {
      throw response
    }
  }

  doLoadMorePosts(feedElement, limit = null) {
    const filter = {};
    filter.filter_type = _.has(feedElement.dataset, "filterType") ? feedElement.dataset.filterType : null;
    filter.filter_id = _.has(feedElement.dataset, "filterId") ? feedElement.dataset.filterId : null;
    filter.last_post_date = this.lastPostDate(feedElement);
    if (limit != null) filter.limit = limit;
    const url = this.postCreateUrl + "?" + this.queryString(filter);
    fetch(url, this.fetchInit('GET'))
        .then(this.parseJSON)
        .then(this.curryCallback(this.displayFetchedPosts, feedElement))
        .catch(this.curryCallback(this.displayFetchedPostsError, feedElement));
  }

  doFlagPost(flagLinkElement, feedElement) {
    const url = flagLinkElement.getAttribute("href") + ".json";
    fetch(url, this.fetchInit('GET'))
        .then(this.curryCallback(this.showPostAsFlagged, flagLinkElement, feedElement))
        .catch(this.curryCallback(this.showFlagPostError, feedElement));
  }

  doDeflagPost(flagLinkElement, feedElement) {
    const url = flagLinkElement.getAttribute("href") + ".json";
    fetch(url, this.fetchInit('GET'))
        .then(this.curryCallback(this.showPostAsDeflagged, flagLinkElement, feedElement))
        .catch(this.curryCallback(this.showDeflagPostError, feedElement));
  }

  doHighFivePost(highFiveLinkElement) {
    const url = highFiveLinkElement.dataset.highFiveUrl + ".json";
    const method = _.has(highFiveLinkElement.dataset, 'unHighFivePost') ? "delete" : "post";
    const feedElement = this.feedElementForNode(highFiveLinkElement);
    const postAction = method === "delete" ? this.showPostUnHighFived : this.showPostHighFived;

    fetch(url, this.fetchInit(method))
        .then(this.parseJSON)
        .then(this.curryCallback(postAction, highFiveLinkElement, feedElement))
        .catch(this.curryCallback(this.showHighFiveError, feedElement));
  }

  /*
   * Event checks
   */
  canHandleReadMore(event) {
    return _.has(event.target.dataset, 'postReadMore');
  }

  canHandleHighFivePost(event) {
    const dataset = event.target.dataset;
    return _.has(dataset, 'highFivePost') || _.has(dataset, 'unHighFivePost');
  }

  canHandleDeflagPost(event) {
    return _.has(event.target.dataset, 'deflagPost');
  }

  canHandleFlagPost(event) {
    return _.has(event.target.dataset, 'flagPost');
  }

  canHandlePostSubmit(event) {
    return _.has(event.target.dataset, 'postCreate');
  }

  canHandleCommentSubmit(event) {
    return _.has(event.target.dataset, 'commentCreate');
  }

  canHandlePostDestroy(event) {
    return _.has(event.target.dataset, 'postDestroy');
  }

  canHandleCommentDestroy(event) {
    return _.has(event.target.dataset, 'commentDestroy');
  }

  canHandleLoadMorePosts(event) {
    return _.has(event.target.dataset, 'loadMorePosts');
  }

  canHandleUploadSelect(event) {
    return _.has(event.target.dataset, 'uploadSelect');
  }

  canHandlePostUpload(event) {
    return _.has(event.target.dataset, 'postUpload');
  }

  canHandleShowPostReply(event) {
    return _.has(event.target.dataset, 'showReply');
  }

  /*
   * Utility Methods
   */

  lastPostDate(feedElement) {
    const post = feedElement.querySelector("[data-post-parent]:last-child");
    if (!post) return null;
    return post.dataset["timestamp"];
  }

  feedElementForNode(element) {
    const feedElement = this.dh.closest(element, "[data-feed]");
    return feedElement;
  }

  findPostCreateFormMessage(feedElement) {
    if (!feedElement) return null;
    return feedElement.querySelector("[data-editor-message]");
  }

  findPostContainer(feedElement) {
    if (!feedElement) return null;
    return feedElement.querySelector("[data-post-container]");
  }

  findCommentContainer(formElement) {
    const parent = this.dh.closest(formElement, '[data-post-parent]');
    if (!parent) return null;

    return parent.querySelector("[data-comment-container]");
  }

  findLoadMoreLink(feedElement) {
    if (!feedElement) return null;
    return feedElement.querySelector("[data-load-more-posts]");
  }

  formData(form, objName = "post") {
    const fd = new FormData(form);
    const out = {};
    out[objName] = {
      body: fd.get(`${objName}[body]`),
      participant_action_id: fd.get(`${objName}[participant_action_id]`),
      hide_on_event_feed: fd.getAll(`${objName}[hide_on_event_feed]`).pop()
    };
    out["redirect"] = fd.get("redirect");
    return out;
  }

  parseJSON(response) {
    return response.json()
  }

  curryCallback(callback) {
    const args = [...arguments];
    args.shift();
    return function (response) {
      args.unshift(response);
      callback.apply(this, args);
    }
  }

  postStringToNodes(string) {
    const list = document.createElement("ul");
    if(string) list.innerHTML = string.trim();
    return list.childNodes;
  }

  postStringToNode(string) {
    const list = document.createElement("ul");
    if(string) list.innerHTML = string.trim();
    return list.firstChild;
  }

  queryString(shallowHash) {
    return Object.keys(shallowHash).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(shallowHash[k])}`).join('&');
  }

  createStorageKey(file) {
    const date = new Date();
    const day = date.toISOString().slice(0, 10);
    const time = date.getTime();
    return "tmp/" + day + "/" + time + "-" + file.name;
  }

  fetchInit(method, data) {
    const init = {
      method: method,
      credentials: 'include',
      headers: {
        "Accept": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    };
    if (data) {
      init.body = data;
    }
    return init;
  }
};
