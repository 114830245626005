import DomHelpers from "../utility/dom_helpers.js"

export default class ShowHideFields {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;
  }

  bindListeners() {
    document.addEventListener('click', this.handleShowHide.bind(this));
  }

  handleShowHide(event) {
    const showTarget = this.getTarget(event, "showField");
    const hideTarget =  this.getTarget(event, "hideField");
    const toggleTarget = this.getTarget(event, "toggleField");

    this.show(showTarget);
    this.hide(hideTarget);
    this.toggle(toggleTarget);

    const showTargets = this.getTargets(event, "showClass");
    const hideTargets =  this.getTargets(event, "hideClass");
    const toggleTargets = this.getTargets(event, "toggleClass");

    for(target of showTargets) { this.show(target) };
    for(target of hideTargets) { this.hide(target) };
    for(target of toggleTargets) { this.toggle(target) };
  }

  getTarget(event, dataValue) {
    const targetId = event.target.dataset[dataValue];
    if(!targetId) return;
    return document.getElementById(targetId);
  }

  getTargets(event, dataValue) {
    const targetClass = event.target.dataset[dataValue];
    if(!targetClass) return [];
    return document.getElementsByClass(targetClass);
  }

  show(target) {
    if(!target) return;
    target.style.display = "block";
  }

  hide(target) {
    if(!target) return;
    target.style.display = "none";
  }

  toggle(target) {
    if(!target) return;
    target.style.display = target.style.display == "none" ? "block" : "none";
  }

};
