import classNames from "classnames";
import { useState } from "react";
import {
  getDefaultValues,
  setSingleSearchParam,
  getHrfFromParams,
} from "../links";

type Props = { path: string; totalPages: number };

export default function Pagination({ path, totalPages }: Props) {
  const { page } = getDefaultValues();

  if (!(totalPages > 0)) return null;

  const [invalidPage, setInvalidPage] = useState<boolean>(false);

  const handleResetInvalid = (value: string) => {
    const valueAsNumber = parseInt(value);
    const isValidValue =
      valueAsNumber && valueAsNumber >= 1 && valueAsNumber <= totalPages;

    if (invalidPage && isValidValue) return setInvalidPage(false);
    if (!invalidPage && !isValidValue) return setInvalidPage(true);
  };

  const onPageInputChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") return;

    const target = e.target as HTMLInputElement;
    const value = target.value;
    const valueAsNumber = parseInt(value);
    const isValidValue =
      valueAsNumber && valueAsNumber >= 1 && valueAsNumber <= totalPages;

    if (!isValidValue) return setInvalidPage(true);

    if (value && isValidValue) {
      return setSingleSearchParam({ path, param: "page", value });
    }
  };

  const pageAsNumber = parseInt(page ?? "1");
  const nextPage = pageAsNumber + 1;
  const prevPage = pageAsNumber - 1;

  const hasNextPage = pageAsNumber && nextPage > 0 && nextPage <= totalPages;
  const hasPrevPage =
    pageAsNumber && prevPage > 0 && prevPage - 1 <= totalPages;

  const nextHref = getHrfFromParams({
    path,
    param: "page",
    value: nextPage.toString(),
  });
  const prevHref = getHrfFromParams({
    path,
    param: "page",
    value: prevPage.toString(),
  });

  const inputSize = totalPages.toString().length + 2;

  return (
    <div className="feed-pagination dashboard-table-pagination">
      <nav>
        {hasPrevPage ? (
          <a className="page-left" href={prevHref}>
            <span className="screen-reader-text">Previous</span>
          </a>
        ) : (
          <span className="page-left disabled">
            <span className="screen-reader-text">Previous</span>
          </span>
        )}
        <p>
          <span>Page</span>
          <input
            type="text"
            defaultValue={page}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
              onPageInputChange(e)
            }
            onChange={(e) => handleResetInvalid(e.target.value)}
            className={classNames("page-input", { invalid: invalidPage })}
            size={inputSize}
          />
          <span>of {totalPages}</span>
        </p>
        {hasNextPage ? (
          <a className="page-right" href={nextHref}>
            <span className="screen-reader-text">Next</span>
          </a>
        ) : (
          <span className="page-right disabled">
            <span className="screen-reader-text">Next</span>
          </span>
        )}
      </nav>
    </div>
  );
}
