import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";

export default class Confirm {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  bindListeners() {
    document.addEventListener('click', this.handleButtonClick);
  }

  handleButtonClick(event) {
    if (!this.isConfirmButtonClick(event)) return;
    const url = event.target.getAttribute('href');
    const action = event.target.dataset.confirmAction;

    event.stopPropagation();
    event.preventDefault();
    const body = event.target.dataset.confirmMessage;

    const payload = {
      title: event.target.dataset.title ? event.target.dataset.title : "Are you sure?",
      body: body,
      yayLabel: event.target.dataset.yayLabel ? event.target.dataset.yayLabel : "CONFIRM",
      nayLabel: event.target.dataset.nayLabel ? event.target.dataset.nayLabel : "CANCEL",
      onNay: () => { },
      onYay: () => { this.doConfirm(url, action) }
    }
    document.dispatchEvent(new CustomEvent('showModal', { detail: payload }));
  }

  doConfirm(url, action) {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = url;
    form.style.display = 'none';
    const input = document.createElement('input');
    input.setAttribute('name', '_method');
    input.setAttribute('value', action);

    const metaToken = document.querySelector('[name=csrf-token]');
    const token = metaToken.getAttribute('content');
    const tokenInput = document.createElement('input');
    tokenInput.setAttribute('name', 'authenticity_token');
    tokenInput.setAttribute('value', token);

    form.appendChild(input);
    form.appendChild(tokenInput);
    document.body.appendChild(form);
    form.submit();
  }

  isConfirmButtonClick(event) {
      return _.has(event.target.dataset, 'confirmMessage')
  }

};
