import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";

export default class HtmlPreview {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;

    this.init = this.init.bind(this);
    this.handleHtmlChange = this.handleHtmlChange.bind(this);
    this.handleTab = this.handleTab.bind(this)
  }

  bindListeners() {
    document.addEventListener('turbo:load', this.init);
    document.addEventListener('input', this.handleHtmlChange);
    document.addEventListener('keydown', this.handleTab);
  }

  init() {
    if (!document.querySelector('[data-html-preview]')) return;

    document.querySelectorAll('textarea[data-html-preview]').forEach((node) => {
      const previewName = node.dataset['htmlPreview'];
      const frame = document.querySelector(`iframe[data-html-preview="${previewName}"]`);
      if(!frame) return;

      if(frame.dataset["stylesheet"]) {
        const stylesheetUrl = frame.dataset["stylesheet"];
        const styleLink = document.createElement("link");
        styleLink.rel = "stylesheet";
        styleLink.href = stylesheetUrl;
        frame.contentDocument.head.appendChild(styleLink);
      }

      if(frame.dataset["template"]) {
        const template = frame.dataset["template"];
        const body = this.setTemplate(template);
        frame.contentDocument.body.innerHTML = body;

        if(frame.dataset["header"]) {
          const target = frame.contentDocument.body.querySelector("[data-header-content]");
          if(target) {
            target.innerHTML = frame.dataset["header"];
          }
        }

        if(frame.dataset["footer"]) {
          const target = frame.contentDocument.body.querySelector("[data-footer-content]");
          if(target) {
            target.innerHTML = frame.dataset["footer"];
          }
        }
      }

      this.updatePreview(node);

      frame.style.height = `${frame.contentDocument.body.scrollHeight || "300"}px`;
    });
  }

  handleHtmlChange = event => {
    this.updatePreview(event.target);
  }

  updatePreview(textarea) {
    const previewName = textarea.dataset['htmlPreview'];
    const frame = document.querySelector(`iframe[data-html-preview="${previewName}"]`);
    if(!frame) return;

    frame.contentDocument.querySelector("[data-preview-content]").innerHTML = textarea.value;
  }

  setTemplate(template) {
    switch(template) {
      case 'email':
        return `<body>
                  <table class="body-wrap" cellpadding="0" cellspacing="0">
                    <tr>
                      <td class="container">
                        <table class="section" cellpadding="0" cellspacing="0">
                          <tr>
                            <td data-header-content class="content">
                            </td>
                          </tr>
                        </table>

                        <table class="section" cellpadding="0" cellspacing="0">
                          <tr>
                            <td data-preview-content class="content">
                            </td>
                          </tr>
                        </table>

                        <table class="section" cellpadding="0" cellspacing="0">
                          <tr>
                            <td data-footer-content class="content">
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </body>
        `
        return `<table class="body-wrap" cellpadding="0" cellspacing="0">
                  <tr>
                    <td data-preview-content class="container">
                    </td>
                  </tr>
                </table>`;
      default:
        return template;
    }
  }

  handleTab = event => {
    const target = event.target;
    if(!target.dataset["htmlPreview"]) return;
    if(event.keyCode != 9) return;

    target.value = `${target.value.substring(0, target.selectionStart)}\t${target.value.substring(target.selectionEnd)}`;
    target.selectionEnd = target.selectionStart + 1;
    target.selectionStart = target.selectionEnd;
    event.preventDefault();
  }

}
