import DomHelpers from "../utility/dom_helpers.js"

export default class SelectAlls {

  constructor(dh = new DomHelpers()) {
    this.dh = dh;

    this.doDeselectAll = this.doDeselectAll.bind(this);
    this.doSelectAll = this.doSelectAll.bind(this);
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
  }

  bindListeners() {
    document.addEventListener('click', this.handleSelectAllClick);
  }

  handleSelectAllClick(event) {
    if (this.isSelectAll(event.target)) {
      event.preventDefault;
      this.doSelectAll(event.target);
    }
    if (this.isDeselectAll(event.target)) {
      event.preventDefault;
      this.doDeselectAll(event.target);
    }
  }

  doSelectAll(el) {
    const selector = `[data-select-all-target="${el.dataset.selectAll}"]`;
    [...document.querySelectorAll(selector)].forEach((checkbox) => {
      checkbox.checked = true;
      checkbox.nextElementSibling.classList.add("checked");
    });
  }

  doDeselectAll(el) {
    [...document.querySelectorAll(`[data-select-all-target="${el.dataset.deselectAll}"]`)].forEach((checkbox) => {
      checkbox.checked = false
      checkbox.nextElementSibling.classList.remove("checked");
    });
  }

  isSelectAll(el) {
    if (el.dataset.hasOwnProperty('selectAll')) return true;
    return false;
  }

  isDeselectAll(el) {
    if (el.dataset.hasOwnProperty('deselectAll')) return true;
    return false;
  }



};
