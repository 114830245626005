import { Controller } from "@hotwired/stimulus";

const DEFAULT_LOCALE = {
  locale: "en",
  name: "English (US)",
  suffix: "en",
};

export default class extends Controller {
  static outlets = ["input"];

  static targets = ["locale"];

  static values = {
    current: {
      type: Object,
      default: DEFAULT_LOCALE,
    },
  };

  initialize() {
    this._ensureCache();
  }

  connect() {
    this.localeValue = this.calculateCurrentLocale();
  }

  calculateCurrentLocale() {
    const element = this.localeTargets.find((target) => target.selected);

    return element ? this.localeDefinitions.get(element.value) : null;
  }

  getOtherInputs() {
    return this.inputOutlets.filter((outlet) => outlet.element !== this.element);
  }

  switchLocale(event) {
    this._recalculateCurrentValue();

    this.dispatch("locale", {
      detail: this.currentValue,
      prefix: "mobility"
    });
  }

  maybeSwitchLocale(event) {
    if (event.target !== this.element) {
      // We got the event from another field, so we should change our locale to match
      // in order to make it easier for someone to copy+paste

      this.element.value = event.detail.locale;

      this._recalculateCurrentValue();
    }
  }

  _ensureCache() {
    if (!this.localeDefinitions) {
      this.localeDefinitions = new Map();
    }
  }

  currentValueChanged(curr, prev) {
    // intentionally left blank for debugging
  }

  localeTargetConnected(element) {
    const definition = buildLocaleFrom(element);

    this.localeDefinitions.set(definition.locale, definition);
  }

  localeTargetDisconnected(element) {
    this.localeDefinitions.delete(element.dataset.locale);
  }

  _recalculateCurrentValue() {
    this.currentValue = this.calculateCurrentLocale() ?? DEFAULT_LOCALE;
  }
}

function buildLocaleFrom(element) {
  if (!element) {
    return null;
  }

  const { innerText: name, value: locale, dataset: { suffix } } = element;

  return { locale, name, suffix };
}
