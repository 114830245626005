import React from "react";
import baseSvgProps from "./baseSvgProps";

const ChevronLeftSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" {...baseSvgProps} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m10.28.543 1.378 1.414L6.01 7.75l5.648 5.793-1.379 1.414L3.252 7.75 10.28.543Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronLeftSvg;
