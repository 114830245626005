import _ from "lodash";
import DomHelpers from "../utility/dom_helpers.js";
import ModalHelpers from "../utility/modal_helpers.js";

export default class EcoChallengeVideo {
  constructor(dh = new DomHelpers(), mh = new ModalHelpers()) {
    this.dh = dh;
    this.mh = mh;
    this.windowKeyDownHandler = null;
    this.handleVideoStop = this.handleVideoStop.bind(this);
    this.handleViewVideoModalClick = this.handleViewVideoModalClick.bind(this);
    this.maybeInitializePlayer = this.maybeInitializePlayer.bind(this);
  }

  bindListeners() {
    document.addEventListener("click", this.handleViewVideoModalClick);
    document.addEventListener("click", this.handleVideoStop);
    document.addEventListener("turbo:load", this.maybeInitializePlayer);
  }

  maybeInitializePlayer() {
    const videoModal = document.querySelector("[data-modal-video]");
    if (!videoModal) return null;
    if (!this.dh.hasClass(videoModal, "state-warn")) return null;

    this.initializePlayer(videoModal);
    this.playVideo();
  }

  initializePlayer(container) {
    const embed = container.querySelector("[data-video-embed]");
    if (!embed) return null;

    this.Player = new Vimeo.Player(embed);
  }

  handleViewVideoModalClick(event) {
    if (!this.dh.hasDataAttribute(event.target.dataset, "showVideo"))
      return null;
    event.preventDefault();
    const modal = document.querySelector("[data-modal-video]");

    this.mh.showModal(modal);
    this.windowKeyDownHandler = (event) => {
      const { key } = event;
      if (key !== "Escape") return;
      this.handleEscKey();
    };
    window.addEventListener("keydown", this.windowKeyDownHandler);
    this.initializePlayer(modal);
    this.playVideo();
  }

  handleEscKey() {
    this.mh.hideModal(document.querySelector("[data-modal-video]"));
    window.removeEventListener("keydown", this.windowKeyDownHandler);
    this.resetVideo();
  }

  handleVideoStop(event) {
    if (!this.dh.hasDataAttribute(event.target.dataset, "videoDismiss"))
      return null;
    const modal = this.dh.closest(event.target, "[data-modal-video]");

    this.mh.hideModal(modal);
    window.removeEventListener("keydown", this.windowKeyDownHandler);
    this.resetVideo();
  }

  playVideo() {
    if (!this.Player) return null;
    this.Player.play();
  }

  resetVideo() {
    if (!this.Player) return null;

    this.Player.pause();
    this.Player.setCurrentTime(0);
  }
};
